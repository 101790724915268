import { useEffect, useMemo, useState } from "react"
import axios from "../../../axios"
import { ReactComponent as NoBillings } from "../../../assets/images/settings/no-billings.svg"
import Loading from "./../../common/Loading"
import SubscriptionBillingTable from "./SubscriptionBillingTable"
import toastService from "../../../services/toastService"

const SubscriptionBilling = () => {
  const [invoicingList, setInvoicingList] = useState([])
  const [isListLoading, setIsListLoading] = useState(false)
  const [sortItem, setSortItem] = useState({
    name: "created",
    direction: "desc"
  })

  useEffect(() => {
    fetchInvoicingList()
  }, [])

  const fetchInvoicingList = () => {
    setIsListLoading(true)

    axios
      .get("/subscription/invoices")
      .then((response) => {
        if (response.success) {
          setInvoicingList(response.data.invoices)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setIsListLoading(false))
  }

  const currentEmployees = useMemo(() => {
    if (!sortItem) {
      return invoicingList
    }

    if (sortItem.direction === "asc") {
      return [...invoicingList].sort((a, b) => (a[sortItem.name] < b[sortItem.name] ? -1 : 1))
    }

    return [...invoicingList].sort((a, b) => (a[sortItem.name] > b[sortItem.name] ? -1 : 1))
  }, [invoicingList, sortItem])

  const filteredInvoices = currentEmployees.filter(
    (el) => el.status.toLowerCase() !== "draft" && el.amount_paid > 0
  )

  return (
    <div className="subscription-billing">
      <div className="subscription-billing-header">
        <div>Invoice</div>
        <div className="subscription-billing-subtitle">
          Here you can view and download your past payments
        </div>
      </div>

      <div>
        {isListLoading ? (
          <Loading />
        ) : filteredInvoices.length > 0 ? (
          <SubscriptionBillingTable
            invoicingList={filteredInvoices}
            setSortItem={setSortItem}
            sortItem={sortItem}
          />
        ) : (
          <div className="subscription-billing-empty">
            <NoBillings />
            <div>No Invoice yet</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SubscriptionBilling

import "../assets/css/run-payroll.css"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import TitleInfo from "./../components/common/TitleInfo"
import axios from "../axios"
import EmptyState from "../components/run-payroll/EmptyState"
import { getPayrollList } from "../store/payroll/payroll.selectors"
import { actions } from "../store/payroll/payroll.reducers"
import Loading from "../components/common/Loading"
import { actions as breadCrumbsActions } from "../store/bread-crumbs/bread-crumbs.reducers"
import ExpensesContent from "../components/expenses/ExpensesContent"
import { useTableSortItem } from "../hooks/useTableSortItem"
import toastService from "../services/toastService"

const Expenses = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [displayBy, setDisplayBy] = useState(4)
  const { sortItem, setSortItem } = useTableSortItem("expenses")

  const payrolls = useSelector(getPayrollList)

  useEffect(() => {
    dispatch(
      breadCrumbsActions.setBreadCrumbs([
        {
          name: "Expenses",
          url: `/expenses`
        }
      ])
    )
  }, [])

  useEffect(() => {
    const handler = setTimeout(() => fetchPayrollList(), 500)

    return () => {
      clearTimeout(handler)
    }
  }, [displayBy, sortItem])

  const fetchPayrollList = () => {
    setLoading(true)

    axios
      .get(
        `/payroll?filter=${displayBy}&field=${sortItem?.name || ""}&direction=${
          sortItem?.direction || ""
        }`
      )
      .then((response) => dispatch(actions.setPayrollList(response.data.payrolls)))
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setLoading(false))
  }

  const handleRangeChange = (e) => {
    setDisplayBy(e.target.value)
    localStorage.setItem("expensesFilterBy", e.target.value)
    setSortItem()
  }

  const handleSortChange = (sortItemName, type) =>
    setSortItem({
      name: sortItemName,
      direction: type
    })

  useEffect(() => {
    const expensesFilterBy = localStorage.getItem("expensesFilterBy")

    if (expensesFilterBy) {
      setDisplayBy(+expensesFilterBy)
    }
  }, [])

  return (
    <>
      <TitleInfo title="Expenses" subTitle="Select a date to see expenses for it" />

      {loading ? (
        <div className="run-payroll-height">
          <Loading />
        </div>
      ) : payrolls.length ? (
        <ExpensesContent
          payrolls={payrolls}
          handleRangeChange={handleRangeChange}
          displayBy={displayBy}
          handleSortChange={handleSortChange}
          activeSortedItem={sortItem}
        />
      ) : (
        <EmptyState />
      )}
    </>
  )
}

export default Expenses

import "../assets/css/settings.css"

import { useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import TitleInfo from "../components/common/TitleInfo"
import SettingsLeftMenu from "../components/settings/SettingsLeftMenu"
import SettingsPlug from "../components/settings/SettingsPlug"
import SettingsCompanies from "../components/settings/SettingsCompanies"
import SettingsMyProfile from "../components/settings/SettingsMyProfile"
import SettingsMembers from "../components/settings/SettingsMembers"
import SettingsPreferences from "../components/settings/SettingsPreferences"
import SettingsSubscription from "../components/settings/SettingsSubscription"
import SettingsFixedExpenses from "../components/settings/SettingsFixedExpenses"
import {
  defaultSettingPage,
  isUserSourceRestricted,
  redirectUserSourceRestricted
} from "../helpers/helpers"

const Settings = () => {
  const { search } = useLocation()
  const nav = useNavigate()

  const [page, setPage] = useState(defaultSettingPage())

  const isUserRestricted = isUserSourceRestricted()

  const useQuery = () => {
    return useMemo(() => new URLSearchParams(search), [search])
  }

  const query = useQuery()

  // const testFromQuery = query.get("test");

  useEffect(() => {
    const pageFromQuery = query.get("page")

    redirectUserSourceRestricted(pageFromQuery || page, setPage, nav)

    if (pageFromQuery) {
      setPage(pageFromQuery)
    }
  }, [query])

  const handlePageChange = (newPage) => {
    setPage(newPage)

    nav({
      pathname: "/settings",
      search: `?page=${newPage}`
    })
  }

  const settingPage = useMemo(() => {
    switch (page) {
      case "profile":
        return <SettingsMyProfile />
      case "subscription":
        return <SettingsSubscription />
      case "companies":
        return <SettingsCompanies />
      case "preferences":
        return <SettingsPreferences />
      // case "settings":
      //   return <SettingsPlug text="Profile Settings Data Coming Soon..." />;
      case "users":
        return <SettingsMembers />
      case "fixed-expenses":
        return <SettingsFixedExpenses />
      default:
        return <SettingsMyProfile />
    }
  }, [page])

  return (
    <>
      <TitleInfo title={isUserRestricted ? "Custom Settings" : "Settings"} />
      <div className="profile-details">
        <SettingsLeftMenu handlePageChange={handlePageChange} page={page} />

        <div className="profile-dis expenses-settings">{settingPage}</div>
      </div>
    </>
  )
}

export default Settings

import { Menu, ArrowBack } from "@mui/icons-material"
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  useMediaQuery
} from "@mui/material"
import { alpha } from "@mui/material/styles"
import { usePopover } from "../hooks/use-popover"
import AccountPopover from "./AccountPopover"
import { useSelector } from "react-redux"
import { getUser } from "../../store/auth/auth.selectors"
import { useNavigate } from "react-router"

const SIDE_NAV_WIDTH = 280
const TOP_NAV_HEIGHT = 64

const TopNav = (props) => {
  const { onNavOpen } = props
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"))
  const accountPopover = usePopover()
  const nav = useNavigate()

  const userData = useSelector(getUser)

  const handleReturn = () => nav("/")

  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: "blur(6px)",
          backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
          position: "sticky",
          left: {
            lg: `${SIDE_NAV_WIDTH}px`
          },
          top: 0,
          width: {
            lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`
          },
          zIndex: (theme) => theme.zIndex.appBar
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2
          }}
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            {!lgUp && (
              <IconButton onClick={onNavOpen}>
                <SvgIcon fontSize="small">
                  <Menu />
                </SvgIcon>
              </IconButton>
            )}
            {/* <Tooltip title="Search">
              <IconButton>
                <SvgIcon fontSize="small">
                  <MagnifyingGlassIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip> */}
          </Stack>
          <Stack alignItems="center" direction="row" spacing={2}>
            {/* <Tooltip title="Contacts">
              <IconButton>
                <SvgIcon fontSize="small">
                  <UsersIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip> */}
            <Tooltip title="Back to App">
              <IconButton onClick={handleReturn}>
                <SvgIcon fontSize="small">
                  <ArrowBack />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Avatar
              onClick={accountPopover.handleOpen}
              ref={accountPopover.anchorRef}
              sx={{
                cursor: "pointer",
                height: 30,
                width: 30
              }}
              src={userData.avatar}
            />
          </Stack>
        </Stack>
      </Box>
      <AccountPopover
        anchorEl={accountPopover.anchorRef.current}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
      />
    </>
  )
}

export default TopNav

import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { IconButton, InputAdornment, TextField } from "@mui/material"
import { useState } from "react"
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material"
import { ReactComponent as ArrowDown } from "../../../assets/images/settings/arrow-down.svg"
import Loading from "./../../common/Loading"
import axios from "../../../axios"
import toastService from "../../../services/toastService"

const styles = {
  error: {
    "& div input": {
      borderColor: "red"
    }
  },
  password: {
    "& .MuiInputBase-root": {
      border: "1px solid #EEF0F2",
      "& input": {
        border: "none",
        boxShadow: "none"
      },
      "&.Mui-focused": {
        border: "1px solid #7B87F5",
        outline: "none",
        boxShadow: "0px 0px 0px 4px rgba(64, 139, 250, 0.15)"
      },
      "&.Mui-error": {
        borderColor: "red"
      }
    }
  }
}

const schema = yup.object().shape({
  current_password: yup.string().required("Current password is required!"),
  new_password: yup
    .string()
    .required("New password is required!")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  new_password_confirmation: yup
    .string()
    .required("Confirm password is required!")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
})

const SettingsMyProfilePassword = () => {
  const [saveLoading, setSaveLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const [showNewPassword, setShowNewPassword] = useState(false)
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword)
  const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword)

  const [showNewConfPassword, setShowNewConfPassword] = useState(false)
  const handleClickShowNewConfPassword = () => setShowNewConfPassword(!showNewConfPassword)
  const handleMouseDownNewConfPassword = () => setShowNewConfPassword(!showNewConfPassword)

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    reset
  } = useForm({
    mode: "all",
    defaultValues: {
      current_password: "",
      new_password: "",
      new_password_confirmation: ""
    },
    resolver: yupResolver(schema)
  })

  const onSubmit = (values) => {
    setSaveLoading(true)

    axios
      .put("/auth/password/change", values)
      .then((response) => {
        if (response.success) {
          setIsOpen(false)
          reset()
          toastService.success(response.message)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setSaveLoading(false))
  }

  const handleCancel = (e) => {
    e.preventDefault()
    reset()
  }

  const handleOpenChange = (e) => {
    e.preventDefault()
    setIsOpen(!isOpen)
  }

  return (
    <form className="profile-form-sec password">
      <div
        className="profile-form profile-info"
        style={{ paddingBottom: isOpen ? "24px" : "16px" }}
      >
        <div
          className="profile-info-form-title"
          style={{
            borderBottom: isOpen ? "1px solid #EAEAEA" : "none",
            paddingBottom: isOpen ? "16px" : "0"
          }}
        >
          <div className="profile-info-form-title-text">Password</div>
          <button onClick={handleOpenChange} className={`companies-btn edit ${isOpen ? "up" : ""}`}>
            <ArrowDown />
          </button>
        </div>

        {isOpen && (
          <>
            <div className="profile-info-form-wrapper password">
              <div className="form-item">
                <label className={errors.current_password?.message && "error"}>
                  Current password *
                </label>
                <div className="input-box">
                  <TextField
                    sx={errors.current_password?.message ? styles.error : {}}
                    fullWidth
                    type="password"
                    placeholder="Current password"
                    name="current_password"
                    required
                    {...register("current_password")}
                    error={touchedFields.current_password && !!errors.current_password?.message}
                    helperText={errors.current_password?.message}
                  />
                </div>
              </div>
              <div className="form-item">
                <label className={errors.new_password?.message && "error"}>Password *</label>
                <div className="input-box">
                  <TextField
                    sx={[styles.password, errors.new_password?.message ? styles.error : {}]}
                    fullWidth
                    type={showNewPassword ? "text" : "password"}
                    placeholder="Password"
                    name="new_password"
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPassword}
                            onMouseDown={handleMouseDownNewPassword}
                          >
                            {showNewPassword ? (
                              <VisibilityOutlined fontSize="small" sx={{ fill: "#8D98A9" }} />
                            ) : (
                              <VisibilityOffOutlined fontSize="small" sx={{ fill: "#8D98A9" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    {...register("new_password")}
                    error={touchedFields.new_password && !!errors.new_password?.message}
                    helperText={errors.new_password?.message}
                  />
                </div>
              </div>
              {!errors.new_password?.message && (
                <div className="my-profile-password-helper-text">
                  Your new password must contain at least 8 characters, one uppercase, one number
                  and one special case character
                </div>
              )}
              <div className="form-item">
                <label className={errors.new_password_confirmation?.message && "error"}>
                  Confirm password *
                </label>
                <div className="input-box">
                  <TextField
                    sx={[
                      styles.password,
                      errors.new_password_confirmation?.message ? styles.error : {}
                    ]}
                    fullWidth
                    type={showNewConfPassword ? "text" : "password"}
                    placeholder="Confirm password"
                    name="new_password_confirmation"
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewConfPassword}
                            onMouseDown={handleMouseDownNewConfPassword}
                          >
                            {showNewConfPassword ? (
                              <VisibilityOutlined fontSize="small" sx={{ fill: "#8D98A9" }} />
                            ) : (
                              <VisibilityOffOutlined fontSize="small" sx={{ fill: "#8D98A9" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    {...register("new_password_confirmation")}
                    error={
                      touchedFields.new_password_confirmation &&
                      !!errors.new_password_confirmation?.message
                    }
                    helperText={errors.new_password_confirmation?.message}
                  />
                </div>
              </div>
            </div>
            <div className="profile-info-modal-btn-wrapper">
              <button className="btn-style profile-info-cancel" onClick={handleCancel}>
                Cancel
              </button>
              <button
                className="btn-style"
                type="submit"
                onClick={handleSubmit(onSubmit)}
                disabled={saveLoading}
              >
                {saveLoading ? <Loading /> : "Update password"}
              </button>
            </div>
          </>
        )}
      </div>
    </form>
  )
}

export default SettingsMyProfilePassword

import PendingRequestsTableRaw from "./PendingRequestsTableRaw"

const PendingRequestsTable = ({ requestsList, handleApprove, handleDecline }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Organization</th>
          <th>Email</th>
          <th>Organization ID</th>
          <th>Registration Date</th>
          <th>Options</th>
        </tr>
      </thead>

      <tbody>
        {requestsList.map((item) => (
          <PendingRequestsTableRaw
            key={item.id}
            requestsData={item}
            handleApprove={handleApprove}
            handleDecline={handleDecline}
          />
        ))}
      </tbody>
    </table>
  )
}

export default PendingRequestsTable

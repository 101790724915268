import EmployeesTableRaw from "./EmployeesTableRaw"
import TableSortArrowsBlock from "./../common/TableSortArrowsBlock"

const EmployeesTable = ({
  userList,
  handleEdit,
  handleDeleteEmployee,
  handleSortChange,
  activeSortedItem
}) => {
  return (
    <table>
      <thead>
        <tr>
          <th>
            <div className="leaderboard-top-table-header-item">
              Name{" "}
              <TableSortArrowsBlock
                handleSortChange={handleSortChange}
                itemName="last_name"
                activeSortedItem={activeSortedItem}
              />
            </div>
          </th>
          <th>
            <div className="leaderboard-top-table-header-item">
              Primary Company{" "}
              <TableSortArrowsBlock
                handleSortChange={handleSortChange}
                itemName="primary_company_name"
                activeSortedItem={activeSortedItem}
              />
            </div>
          </th>
          <th>
            <div className="leaderboard-top-table-header-item">
              Primary Station{" "}
              <TableSortArrowsBlock
                handleSortChange={handleSortChange}
                itemName="primary_station_name"
                activeSortedItem={activeSortedItem}
              />
            </div>
          </th>
          <th>
            <div className="leaderboard-top-table-header-item">
              FedEx ID{" "}
              <TableSortArrowsBlock
                handleSortChange={handleSortChange}
                itemName="fedex_id"
                activeSortedItem={activeSortedItem}
              />
            </div>
          </th>
          <th>
            <div className="leaderboard-top-table-header-item">
              Employee ID{" "}
              <TableSortArrowsBlock
                handleSortChange={handleSortChange}
                itemName="employee_id"
                activeSortedItem={activeSortedItem}
              />
            </div>
          </th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        {userList.map((item) => (
          <EmployeesTableRaw
            key={item.id}
            userData={item}
            handleEdit={handleEdit}
            handleDeleteEmployee={handleDeleteEmployee}
          />
        ))}
      </tbody>
    </table>
  )
}

export default EmployeesTable

import { useEffect, useState } from "react"
import axios from "../axios"
import toastService from "../services/toastService"
import { useParams } from "react-router-dom"
import Loading from "../components/common/Loading"
import DefaultPageHeader from "../components/shared/DefaultPageHeader"
import {
  Area,
  AreaChart,
  Bar,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts"
import { KeyboardArrowDown } from "@mui/icons-material"
import { MenuItem, Select } from "@mui/material"

const formatFloatValue = (v) => parseFloat(v).toFixed(2)

const EmployeeStats = () => {
  const { employeeId } = useParams()
  const [employee, setEmployee] = useState(null)
  const [employeeStats, setEmployeeStats] = useState(null)
  const [loading, setLoading] = useState(false)
  const [totalILS, setTotalILS] = useState(0)
  const [totalEarnings, setTotalEarnings] = useState(0)
  const [displayBy, setDisplayBy] = useState(24)

  useEffect(() => {
    getUserStats()
  }, [displayBy])

  function formatLabel(str) {
    return str
      .replace(/^chart_earning_/, "")
      .split("_") // Split by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" ") // Join with spaces
  }

  const getUserStats = () => {
    setLoading(true)
    axios({
      method: "get",
      url: `/employee/${employeeId}/detailed?filter=${displayBy}`
    })
      .then((response) => {
        const data = response.data
        let dataArr = []
        let totalIlsPercentage = 0
        let totalIlsCount = 0
        let totalEarnings = 0

        for (const [key, value] of Object.entries(data?.charts)) {
          if (value.total_ils > 0) {
            totalIlsPercentage += value.total_ils
            totalIlsCount += 1
          }

          totalEarnings += value.total_earnings

          const totalPackages =
            value.total_delivery_packages +
            value.total_ecommerce_packages +
            value.total_pickup_packages
          const totalStops =
            value.total_delivery_stops + value.total_ecommerce_stops + value.total_pickup_stops

          const stopsPerHours = value.total_hours ? totalStops / value.total_hours : 0

          dataArr.push({
            name: key,
            date: key,

            packages: totalPackages,
            stops: totalStops,

            working_days: value.working_days,
            total_hours: value.total_hours,
            total_fuel: value.total_fuel,
            stops_per_hour: stopsPerHours,

            total_code_85: value.total_code_85,
            total_all_status_code_package: value.total_all_status_code_package,
            total_dna: value.total_dna,
            total_miss_pickup_window: value.total_miss_pickup_window,
            total_early_late_pickup: value.total_early_late_pickup,

            total_earnings: value.total_earnings,
            total_addition: value.total_addition,
            total_deduction: -value.total_deduction,

            // earnings chart
            chart_earning_pay: value.chart_earning_pay,
            chart_earning_addition: value.chart_earning_addition,
            chart_earning_deduction: -value.chart_earning_deduction,
            chart_earning_total_pto: value.chart_earning_total_pto,
            chart_earning_total_hourly: value.chart_earning_total_hourly,
            chart_earning_training: value.chart_earning_training
          })
        }

        setTotalILS(totalIlsCount ? formatFloatValue(totalIlsPercentage / totalIlsCount) : 0)
        setTotalEarnings(totalEarnings)

        setEmployeeStats(dataArr)

        setEmployee(data.employee)
      })
      .catch((err) => toastService.error(err.response?.data?.message || "Something went wrong"))
      .finally(() => {
        setLoading(false)
      })
  }

  const EmployeeILSCard = ({ employeeName, percentage }) => {
    const progressColor = percentage === 0 ? "#ccc" : percentage >= 98 ? "#4caf50" : "#f44336"

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          marginRight: "15px",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          width: "200px",
          boxShadow: "0 2px 5px rgba(0,0,0,0.1)"
        }}
      >
        <h3 style={{ marginBottom: "10px" }}>{employeeName}</h3>
        <div
          style={{
            position: "relative",
            width: "100px",
            height: "100px",
            borderRadius: "50%",
            border: `10px solid ${progressColor}`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <span style={{ fontSize: "20px", fontWeight: "bold" }}>
            {percentage ? `${percentage}%` : "N/A"}
          </span>
        </div>
      </div>
    )
  }

  const handleRangeChange = (e) => {
    setDisplayBy(e.target.value)
  }

  if (!employee) {
    return <Loading />
  }

  const CustomTooltip = ({ active, payload, label, moneyFormat = false }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "10px"
          }}
        >
          <p>
            <strong>{label}</strong>
          </p>
          {payload.map((entry, index) => (
            <p key={`item-${index}`} style={{ color: entry.color }}>
              {formatLabel(entry.dataKey)}:{moneyFormat ? "$" : null}{" "}
              {formatFloatValue(entry.value)}
            </p>
          ))}
        </div>
      )
    }

    return null
  }

  return (
    <>
      {!loading && (
        <DefaultPageHeader title={"Employee: " + employee.first_name + " " + employee.last_name} />
      )}
      <div className="overview-revenue">
        <div className="payroll-top">
          <Select
            className="payroll-table-select"
            value={displayBy}
            onChange={handleRangeChange}
            IconComponent={KeyboardArrowDown}
          >
            <MenuItem value={4}>Month</MenuItem>
            <MenuItem value={24}>6 Month</MenuItem>
            <MenuItem value={48}>Year</MenuItem>
            <MenuItem value={999}>All Data</MenuItem>
          </Select>
        </div>

        {employeeStats ? (
          <>
            <div style={{ display: "flex" }}>
              <EmployeeILSCard employeeName="ILS%" percentage={totalILS} />
              {/*<EmployeeILSCard employeeName='Employee Score'*/}
              {/*                 percentage={100} />*/}
              Total Period Earnings: ${formatFloatValue(totalEarnings)}
            </div>

            <br />
            <br />
            <div className="chart-header">
              <h4>Total Stops & Packages</h4>
            </div>

            <ResponsiveContainer width="100%" height={200}>
              <AreaChart
                width={500}
                height={400}
                data={employeeStats}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="packages"
                  stroke="#8884d8"
                  fill="rgba(66, 83, 240, 0.25)"
                />
                <Area
                  type="monotone"
                  dataKey="stops"
                  stroke="#4253F0"
                  fill="rgba(66, 83, 240, 0.6)"
                />
              </AreaChart>
            </ResponsiveContainer>

            <div className="chart-header">
              <h4>Time & Productivity</h4>
            </div>

            <ResponsiveContainer width="100%" height={200}>
              <AreaChart
                width={500}
                height={200}
                data={employeeStats}
                syncId="anyId"
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis type="number" allowDecimals={true} domain={["dataMin", "auto"]} />
                <Tooltip content={<CustomTooltip />} />
                <Area
                  type="monotone"
                  dataKey="total_hours"
                  stroke="#82ca9d"
                  fill="rgba(130, 202, 157, 0.25)"
                />
                <Area
                  type="monotone"
                  dataKey="stops_per_hour"
                  stroke="#8884d8"
                  fill="rgba(66, 83, 240, 0.25)"
                />
              </AreaChart>
            </ResponsiveContainer>

            <div className="chart-header">
              <h4>Fuel Usage</h4>
            </div>

            <ResponsiveContainer width="100%" height={200}>
              <AreaChart
                data={employeeStats}
                syncId="anyId"
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis type="number" allowDecimals={true} domain={["dataMin", "auto"]} />
                <Tooltip content={<CustomTooltip moneyFormat />} />
                <Area
                  type="monotone"
                  dataKey="total_fuel"
                  stroke="#82ca9d"
                  fill="rgba(130, 202, 157, 0.25)"
                />
              </AreaChart>
            </ResponsiveContainer>

            <div className="chart-header">
              <h4>Earnings</h4>
            </div>
            <ResponsiveContainer width={"100%"} height={300}>
              <ComposedChart
                width={500}
                height={400}
                data={employeeStats}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={<CustomTooltip moneyFormat />} />
                <Bar dataKey="chart_earning_pay" fill="rgba(66, 83, 240, 0.65)" stackId="a" />
                <Bar dataKey="chart_earning_addition" fill="rgb(0 184 108)" stackId="a" />
                <Bar stackId="a" dataKey="chart_earning_deduction" fill="rgb(255 90 89)" />
                <Bar stackId="a" dataKey="chart_earning_total_pto" fill="#7f7f7f" />
                <Bar stackId="a" dataKey="chart_earning_total_hourly" fill="rgb(255 82 245)" />
                <Bar stackId="a" dataKey="chart_earning_training" fill="#44454e" />

                <Area
                  type="monotone"
                  dataKey="total_earnings"
                  fill="rgba(66, 83, 240, 0.25)"
                  stroke="#8884d8"
                />
              </ComposedChart>
            </ResponsiveContainer>

            <div style={{ display: "flex", placeContent: "space-evenly" }}>
              <p style={{ color: "rgba(66, 83, 240, 0.65)" }}>Pay</p>
              <p style={{ color: "#7f7f7f" }}>Total Pto</p>
              <p style={{ color: "rgb(255 82 245)" }}>Total Hourly</p>
              <p style={{ color: "rgb(0 184 108)" }}>Addition</p>
              <p style={{ color: "rgb(255 90 89)" }}>Deduction</p>
              <p style={{ color: "#44454e" }}>Training</p>
            </div>

            <div className="chart-header">
              <h4>Total Codes</h4>
            </div>

            <div className="payroll-table payroll-data-table statement statement-height">
              <table>
                <thead>
                  <tr>
                    <th>
                      <div className="leaderboard-top-table-header-item">
                        Date {/*<TableSortArrowsBlock*/}
                        {/*  handleSortChange={handleSortChange}*/}
                        {/*  itemName="employee_name"*/}
                        {/*  activeSortedItem={sortItem}*/}
                        {/*/>*/}
                      </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">
                        total_code_85 {/*<TableSortArrowsBlock*/}
                        {/*  handleSortChange={handleSortChange}*/}
                        {/*  itemName="employee_name"*/}
                        {/*  activeSortedItem={sortItem}*/}
                        {/*/>*/}
                      </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">
                        total_all_status_code_package {/*<TableSortArrowsBlock*/}
                        {/*  handleSortChange={handleSortChange}*/}
                        {/*  itemName="working_days"*/}
                        {/*  activeSortedItem={sortItem}*/}
                        {/*/>*/}
                      </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">
                        total_dna {/*<TableSortArrowsBlock*/}
                        {/*  handleSortChange={handleSortChange}*/}
                        {/*  itemName="perStop"*/}
                        {/*  activeSortedItem={sortItem}*/}
                        {/*/>*/}
                      </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">
                        total_miss_pickup_window {/*<TableSortArrowsBlock*/}
                        {/*  handleSortChange={handleSortChange}*/}
                        {/*  itemName="total_fuel"*/}
                        {/*  activeSortedItem={sortItem}*/}
                        {/*/>*/}
                      </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">
                        total_early_late_pickup {/*<TableSortArrowsBlock*/}
                        {/*  handleSortChange={handleSortChange}*/}
                        {/*  itemName="total_stops"*/}
                        {/*  activeSortedItem={sortItem}*/}
                        {/*/>*/}
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {employeeStats.map((el) => (
                    <tr>
                      <td>{el.date}</td>
                      <td>{el.total_code_85}</td>
                      <td>{el.total_all_status_code_package}</td>
                      <td>{el.total_dna}</td>
                      <td>{el.total_miss_pickup_window}</td>
                      <td>{el.total_early_late_pickup}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : null}
      </div>
    </>
  )
}

export default EmployeeStats

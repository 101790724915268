import React, { useEffect, useState } from "react"
import "./payroll.scss"
import {
  formatDate,
  initiateFileDownloadFromBlob,
  isUserSourceRestricted
} from "../../helpers/helpers"
import CircularProgressBar from "../shared/CircularProgressBar"
import { MoreVert } from "@mui/icons-material"
import { Menu, MenuItem } from "@mui/material"
import ExportIcon from "../../assets/images/run-payroll/export.svg"
import StartOverIcon from "../../assets/images/run-payroll/start-over.svg"
import { ReactComponent as LockIcon } from "../../assets/images/run-payroll/lock.svg"
import axios from "../../axios"
import { actions } from "../../store/payroll/payroll.reducers"
import * as dayjs from "dayjs"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getUser } from "../../store/auth/auth.selectors"
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal"
import toastService from "../../services/toastService"
import DarkTooltip from "../common/DarkToolTip"
import Button from "../UI/Button/Button"
import ActivateSubscriptionModal from "../modals/ActivateSubscriptionModal"

const PayrollItem = ({ itemData, index, handleUpdateList, canUploadNewPayroll, productPrice }) => {
  const dispatch = useDispatch()
  const nav = useNavigate()
  const [isOpenStartOverModal, setIsOpenStartOverModal] = useState(false)
  const [isOpenDeleteFlexChecksModal, setIsOpenDeleteFlexChecksModal] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [isDeleteFlexChecksLoading, setIsDeleteFlexChecksLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [isActivateOpen, setIsActivateOpen] = useState(false)
  const [isActivated, setIsActivated] = useState(false)

  const open = Boolean(anchorEl)

  const { date, statements_count, created_at, updated_at, processed_at, current_csa_number } =
    itemData

  const userData = useSelector(getUser)

  const [payrollStarted, setPayrollStarted] = useState(statements_count > 0)
  const [payrollCompleted, setPayrollCompleted] = useState(processed_at)

  const isUserRestricted = isUserSourceRestricted()

  const handlePreviewClick = () => {
    if (payrollStarted) {
      nav(`/run-payroll-preview/${itemData.id}`)
    } else {
      if (canUploadNewPayroll) {
        nav(`/run-payroll/${itemData.id}/process`)
      } else {
        if (!isUserRestricted) {
          setIsActivateOpen(true)
        }
      }
    }
  }

  useEffect(() => {
    if (isActivated) {
      handlePreviewClick()
    }
  }, [isActivated])

  const handleOpen = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  const handleDownloadZip = () => {
    handleClose()
    setIsDownloading(true)
    axios({
      method: "get",
      url: `/payroll/${itemData.id}/download-zip`,
      responseType: "blob"
    })
      .then((response) => {
        setIsDownloading(false)
        initiateFileDownloadFromBlob(
          response,
          `payroll-${dayjs(date).format("MM-DD-YYYY")} - ${dayjs(date)
            .add(6, "d")
            .format("MM-DD-YYYY")}`
        )
      })
      .catch(() => toastService.error("Something went wrong"))
  }

  const handleStartOverModal = () => {
    handleClose()
    setIsOpenStartOverModal(!isOpenStartOverModal)
  }

  const handleOpenDeleteFlexChecksModal = () => {
    handleClose()
    setIsOpenDeleteFlexChecksModal(!isOpenDeleteFlexChecksModal)
  }

  const handleCloseStartOverModal = () => {
    handleClose()
    setIsOpenStartOverModal(!isOpenStartOverModal)
  }

  const handleCloseDeleteFlexChecksModal = () => {
    handleClose()
    setIsOpenDeleteFlexChecksModal(!isOpenDeleteFlexChecksModal)
  }

  const handleConfirmStartOver = () => {
    setIsDeleteLoading(true)
    axios
      .delete(`/payroll/${itemData.id}`)
      .then((res) => {
        if (res.success) {
          setPayrollStarted(false)
          setPayrollCompleted(false)
          dispatch(actions.startOverPayroll(itemData.id))
          toastService.success("Payroll Data Successfully Deleted!")
          handleCloseStartOverModal()
        } else {
          toastService.error(res.data.message)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setIsDeleteLoading(false))
  }

  const handleConfirmDeleteFlexChecks = () => {
    setIsDeleteFlexChecksLoading(true)
    axios
      .post(`/integrations/paychex/delete-checks/${itemData.id}`)
      .then((res) => {
        if (res.success) {
          toastService.success("Delete Flex Checks started")
          handleCloseDeleteFlexChecksModal()
        } else {
          toastService.error(res.data.message)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setIsDeleteFlexChecksLoading(false))
  }

  const buttonType = payrollCompleted ? "grey-bg" : payrollStarted ? "blue-border" : "blue-bg"

  let actionButton = (
    <DarkTooltip
      placement="top"
      title={!canUploadNewPayroll && !isUserRestricted ? "Trial period ended" : ""}
      arrow
    >
      <Button
        onClick={handlePreviewClick}
        text={payrollCompleted ? "View" : payrollStarted ? "Resume" : "Run"}
        type={buttonType}
        className="mr1"
        {...(!canUploadNewPayroll && !payrollStarted ? { icon: <LockIcon /> } : {})}
      />
    </DarkTooltip>
  )

  return (
    <div className="payroll-item">
      <div className="run-payroll-item-content" onClick={handlePreviewClick}>
        <p className="title">
          Pay Period {formatDate(date, false)} - {formatDate(date, true, 6)}
        </p>
        <div className="payroll-details">
          <CircularProgressBar total={current_csa_number} uploaded={statements_count} />
          <p className="subtitle">
            Uploaded Files ({statements_count}/{current_csa_number})
            {payrollCompleted && (
              <span className="paragraph-item-text" style={{ marginLeft: "0.5rem" }}>
                Processed on: {dayjs(payrollCompleted).format("HH:mm MMMM DD, YYYY")}
              </span>
            )}
          </p>
        </div>
      </div>
      <div className="run-payroll-actions">
        {actionButton}
        <button className="btn-payroll-more" onClick={handleOpen}>
          <MoreVert className="document-more-btn-icon" />
        </button>

        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} className="more-menu">
          <MenuItem onClick={handleDownloadZip} disabled={isDownloading || !payrollStarted}>
            <button className="document-more-btn menu-item">
              <img src={ExportIcon} alt="" />
            </button>
            Download
          </MenuItem>
          <MenuItem onClick={handleStartOverModal} disabled={isDeleteLoading || !payrollStarted}>
            <button className="document-more-btn menu-item">
              <img src={StartOverIcon} alt="" />
            </button>
            Start Over
          </MenuItem>
        </Menu>
      </div>
      <ConfirmDeleteModal
        open={isOpenStartOverModal}
        handleClose={handleCloseStartOverModal}
        handleConfirm={handleConfirmStartOver}
        isLoading={isDeleteLoading}
        text="Confirm Payroll Deletion"
        subtitle={`This action will permanently delete payroll data for ${formatDate(
          date,
          false
        )} - ${formatDate(date, true, 6)}. Are you sure you want to proceed?`}
        confirmButtonText="Yes, I’m sure"
        noIcon
        noCloseIcon
      />
      <ActivateSubscriptionModal
        open={isActivateOpen}
        handleClose={() => setIsActivateOpen(false)}
        handleConfirm={() => setIsActivated(true)}
        productPrice={productPrice}
      />
    </div>
  )
}

export default PayrollItem

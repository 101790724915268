import { useEffect, useState } from "react"
import axios from "../../axios"
import Loading from "./../common/Loading"
import TitleInfo from "../common/TitleInfo"
import SettingsCompaniesList from "./companies/SettingsCompaniesList"
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal"
import CompanyModal from "../modals/CompanyModal"
import { isUserSourceRestricted } from "../../helpers/helpers"
import toastService from "../../services/toastService"
import { useDispatch, useSelector } from "react-redux"
import { getUser } from "../../store/auth/auth.selectors"
import {actions as authActions, actions} from "../../store/auth/auth.reducers"

const SettingsCompanies = () => {
  const dispatch = useDispatch()
  const [isNeedUpdate, setIsNeedUpdate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [companiesList, setCompaniesList] = useState([])

  const [addOpen, setAddOpen] = useState(false)
  const [editedCompany, setEditedCompany] = useState()
  const [isAddLoading, setIsAddLoading] = useState(false)

  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [deletedCompany, setDeletedCompany] = useState()

  const [stationCount, setStationCount] = useState(0)

  const userData = useSelector(getUser)

  const isUserRestricted = isUserSourceRestricted()

  useEffect(() => {
    if (!isNeedUpdate) {
      fetchCompanies()
      fetchStationsCount()
    }

    if (isNeedUpdate) {
      setIsNeedUpdate(false)
    }
  }, [isNeedUpdate])

  const fetchCompanies = () => {
    setLoading(true)

    axios
      .get("/companies")
      .then((response) => {
        if (response.success) {
          setCompaniesList(response.data.data)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setLoading(false))
  }

  const fetchStationsCount = () => {
    axios
      .get("/station-count")
      .then((response) => {
        if (response.success) {
          setStationCount(response.data)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
  }

  const handleAddOpen = () => setAddOpen(true)

  const handleAddClose = () => {
    setAddOpen(false)
    setEditedCompany()
  }

  const handleCloseDeleteModal = () => setDeletedCompany()

  const fetchUserData = () =>
    axios
        .get("/auth/user")
        .then((response) => {
          dispatch(authActions.loginUser(response))
          localStorage.setItem("user", JSON.stringify(response || {}))
        })
        .catch((err) => toastService.error(err.response?.data?.message || err.message))

  const handleDelete = (isChecked, setIsChecked) => {
    setIsDeleteLoading(true)

    const body = {
      ...(isChecked && !!deletedCompany?.employees_count ? { delete_employees: 1 } : {})
    }

    axios
      .delete(`/companies/${deletedCompany.id}`, { data: body })
      .then((response) => {
        if (response.success) {
          handleCloseDeleteModal()
          setIsNeedUpdate(true)
          toastService.success("Company Deleted Successfully")
          dispatch(actions.setUserCsaCount(userData.csa_count - deletedCompany.stations_count))
          fetchUserData()
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => {
        setIsDeleteLoading(false)
        isChecked && setIsChecked(false)
      })
  }

  const handleOpenEditModal = (item) => setEditedCompany(item)

  const handleEdit = (values, handleCurClose) => {
    setIsAddLoading(true)

    const formData = new FormData()

    for (const property in values) {
      formData.append(property, values[property])
    }

    if (editedCompany) {
      formData.append("_method", "PUT")

      const url = isUserRestricted
        ? `/package-route/companies/${editedCompany.id}`
        : `/companies/${editedCompany.id}`

      axios
        .post(url, formData)
        .then((response) => {
          if (response.success) {
            handleAddClose()
            handleCurClose && handleCurClose()
            setIsNeedUpdate(true)
            toastService.success("Company Successfully Updated")
          }
        })
        .catch((err) => {
          toastService.error(err.response?.data?.message || err.message)
        })
        .finally(() => setIsAddLoading(false))
    } else {
      axios
        .post("/companies", formData)
        .then((response) => {
          if (response.success) {
            handleAddClose()
            handleCurClose && handleCurClose()
            toastService.success("Company Added Successfully")
            setIsNeedUpdate(true)
          }
        })
        .catch((err) => {
          toastService.errorHandler(err)
        })
        .finally(() => setIsAddLoading(false))
    }
  }

  const handleOpenDeleteModal = (item, e) => {
    e.preventDefault()

    setDeletedCompany(item)
  }

  let buttons = []

  if (!isUserRestricted) {
    buttons = [
      {
        name: "+ Add Company",
        class: "btn-style",
        disabled: companiesList.length >= 10,
        onClick: handleAddOpen
      }
    ]
  }

  return (
    <div className="profile-companies settings-tabs">
      <TitleInfo
        title="Company"
        subTitle="Manage Company, Station, Contract Service Area and Work Area"
        buttons={buttons}
      />

      {loading ? (
        <Loading />
      ) : (
        <div className="table-scroll-mobile payroll-table companies">
          <SettingsCompaniesList
            companiesList={companiesList}
            handleDelete={handleOpenDeleteModal}
            handleEdit={handleOpenEditModal}
            stationCount={stationCount}
          />
        </div>
      )}

      <CompanyModal
        open={!!editedCompany || addOpen}
        handleClose={handleAddClose}
        editedCompany={editedCompany}
        handleConfirm={handleEdit}
        isLoading={isAddLoading}
        isUserRestricted={isUserRestricted}
      />

      <ConfirmDeleteModal
        open={!!deletedCompany}
        handleClose={handleCloseDeleteModal}
        handleConfirm={handleDelete}
        text="Are you sure delete this Company?"
        subtitle={
          deletedCompany?.employees_count
            ? `There are ${deletedCompany?.employees_count} employees on this Company, do you want to proceed?`
            : null
        }
        checkbox={!!deletedCompany?.employees_count}
        isLoading={isDeleteLoading}
      />
    </div>
  )
}

export default SettingsCompanies

import DeleteIcon from "../../assets/images/run-payroll/delete-filled.svg"
import Avatar from "../common/Avatar"
import BlurText from "./../common/BlurText"
import { getPayAdjustment } from "../../helpers/helpers"

const EmployeesTableRaw = ({ userData, handleEdit, handleDeleteEmployee }) => {
  return (
    <tr className={!userData.active ? "inactive" : !userData.station?.id || !userData.primary_company ? "no-station" : ""}>
      <td onClick={handleEdit.bind(null, userData)}>
        <div className="statement-first-block">
          <Avatar
            variant="circular"
            className="statement-avatar"
            alt={userData.first_name}
            src={userData.profile_image}
            withBadge
            badgeType={getPayAdjustment(userData)}
          />
          <div className="statement-name-block">
            <div>
              {userData.first_name} <BlurText>{userData.last_name}</BlurText>
            </div>

            <div className="statement-company">{userData.company_position_name}</div>
          </div>
        </div>
      </td>
      <td onClick={handleEdit.bind(null, userData)}>{userData.primary_company_name}</td>
      <td onClick={handleEdit.bind(null, userData)}>{userData.primary_station_name}</td>

      <td onClick={handleEdit.bind(null, userData)}>
        <BlurText>{userData.fedex_id}</BlurText>
      </td>
      <td onClick={handleEdit.bind(null, userData)}>
        <BlurText>{userData.employee_id}</BlurText>
      </td>
      <td>
        <button
          className="document-more-btn menu-item"
          onClick={handleDeleteEmployee.bind(null, userData)}
        >
          <img src={DeleteIcon} alt="" />
        </button>
      </td>
    </tr>
  )
}

export default EmployeesTableRaw

import SettingsMembersTableRaw from "./SettingsMembersTableRaw"

const SettingsMembersTable = ({ memberList, handleEdit, handleDeleteMember }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Options</th>
        </tr>
      </thead>

      <tbody>
        {memberList.map((item) => (
          <SettingsMembersTableRaw
            key={item.id}
            userData={item}
            handleEdit={handleEdit}
            handleDeleteMember={handleDeleteMember}
          />
        ))}
      </tbody>
    </table>
  )
}

export default SettingsMembersTable

import { useEffect, useState } from "react"
import { companyIcons } from "../../constants"
import PaychexIntegrationModal from "../modals/PaychexIntegrationModal"
import axios from "../../axios"
import Loading from "../common/Loading"
import CloseCircle from "../../assets/images/close-circle.png"
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal"
import toastService from "../../services/toastService"

const PaychexCard = ({
  paychexIntegrations,
  synchronizationProgress,
  directIntegrations,
  templateIntegrations
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [syncStatuses, setSyncStatuses] = useState({})

  const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState(false)

  const updateSyncStatuses = (integrations) => {
    return integrations.reduce((acc, integration) => {
      acc[integration.data.display_id] = integration.data.sync
      return acc
    }, {})
  }

  useEffect(() => {
    const updatedStatuses = updateSyncStatuses(paychexIntegrations)
    setSyncStatuses(updatedStatuses)
  }, [paychexIntegrations])

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const listItemStyle = {
    border: "1px solid rgb(123, 135, 245)",
    padding: "24px",
    minHeight: "100px",
    width: "100%",
    display: "block",
    "--darkreader-inline-border-top": "#0b178d",
    "--darkreader-inline-border-right": "#0b178d",
    "--darkreader-inline-border-bottom": "#0b178d",
    "--darkreader-inline-border-left": "#0b178d"
  }

  const pathStyle = {
    stroke: "#8D98A9",
    strokeWidth: "1.5",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    "--darkreader-inline-stroke": "#b1a99e"
  }

  const isCompanyApproved = paychexIntegrations.some((integration) => integration.data.approved)

  const handleSync = async (displayId) => {
    const currentSyncStatus = syncStatuses[displayId]
    const newSyncStatus = !currentSyncStatus

    setSyncStatuses({
      ...syncStatuses,
      [displayId]: newSyncStatus
    })

    await axios
      .post("/integrations/paychex/synchronization", {
        display_id: displayId,
        sync: newSyncStatus
      })
      .then((response) => {
        toastService.success(response.data)
      })
      .catch((error) => {
        setSyncStatuses({
          ...syncStatuses,
          [displayId]: currentSyncStatus
        })
        toastService.error(error?.response?.data?.message || "Something went wrong")
      })
  }

  const handleSyncCancel = (integration) => {
    toastService.success("Cancel Synchronization...")

    axios
      .post(`/integrations/paychex/cancel-synchronization/${integration?.id}`)
      .then((response) => {
        window.location.reload()
      })
      .catch((error) => {
        toastService.error(error?.response?.data?.message || "Something went wrong")
      })
  }

  const handleOpenDisconnectModal = () => setIsDisconnectModalOpen(true)
  const handleCloseDisconnectModal = () => setIsDisconnectModalOpen(false)

  const handleDisconnect = () => {
    axios
      .post(`/integrations/paychex/disconnect`)
      .then(() => {
        toastService.success("Paychex disconnected successfully")

        handleCloseDisconnectModal()
        window.location.reload()
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
  }

  return (
    <li style={listItemStyle}>
      <div className="applications-item">
        <div className="applications-item-content">
          <img src={companyIcons["paychex"]} alt="" />
          <div className="applications-item-text">
            <div>Paychex</div>
            <div>Connect Paychex to sync payroll and employees automatically</div>
          </div>
        </div>
        <button onClick={() => handleOpenDisconnectModal()} className="companies-btn connect-now">
          Disconnect
        </button>
      </div>
      {paychexIntegrations.map((integration, index) => (
        <div className="settings-applications-company-row">
          <div key={index} className="applications-company-row-item">
            <div className="applications-company-row-item-container">
              {integration.data.approved && integration.data.sync_progress ? (
                <div
                  className="applications-company-row-item-text co-code"
                  style={{ width: "20%" }}
                >
                  <span>
                    <b>Synchronization...</b>
                  </span>
                </div>
              ) : null}
              {integration.data.approved ? (
                <div
                  className="applications-company-row-item-name"
                  style={{
                    width: integration.data.sync_progress ? "60%" : "100%"
                  }}
                >
                  {integration.data.sync_progress ? (
                    <Loading />
                  ) : (
                    <span>{integration?.company.name}</span>
                  )}
                </div>
              ) : (
                <div className="applications-company-row-item-name" style={{ width: "100%" }}>
                  <a href={integration.data.approvalLink} target="_blank">
                    Approval Link
                  </a>
                </div>
              )}
              <div className="applications-company-row-item-text co-code" style={{ width: "100%" }}>
                <span>
                  Company ID: <b>{integration.data.display_id}</b>
                </span>
              </div>
            </div>
            <div className="applications">
              {!integration.data.approved && (
                <button
                  style={{
                    padding: "0 10px",
                    width: "100%",
                    boxSizing: "border-box",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                  disabled
                  className="companies-btn coming-soon"
                >
                  Waiting for Approval
                </button>
              )}
              {!integration.data.sync_progress && integration.data.approved && (
                <div className="auto-clock-in-container">
                  <div
                    style={{ flexWrap: "unset" }}
                    className="option-toggle add-bonus-toggle"
                    onClick={() => handleSync(integration.data.display_id)}
                  >
                    <span
                      className={`toggle-btn ${
                        syncStatuses[integration.data.display_id] ? "active" : ""
                      }`}
                    ></span>
                    <span>Synchronization</span>
                  </div>
                </div>
              )}
              {integration.data.approved &&
                integration.data.sync_progress &&
                integration.company && (
                  <div className="auto-clock-in-container">
                    <button
                      className="popup-close add-popup-cancel"
                      onClick={() => handleSyncCancel(integration)}
                    >
                      <img src={CloseCircle} alt="" />
                    </button>
                  </div>
                )}
            </div>
          </div>
        </div>
      ))}
      <div className="onboard-add-more add-company-paychex" style={{ marginTop: "3rem" }}>
        <button onClick={handleOpenModal}>
          <span className="plus">+</span> Add Company
        </button>
      </div>

      <ConfirmDeleteModal
        open={isDisconnectModalOpen}
        handleClose={handleCloseDisconnectModal}
        handleConfirm={handleDisconnect}
        text="Are you sure you want to Disconnect Paychex integration?"
        confirmButtonText="Disconnect"
      />

      {modalOpen && <PaychexIntegrationModal open={modalOpen} handleClose={handleCloseModal} />}
    </li>
  )
}

export default PaychexCard

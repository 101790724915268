import "../assets/css/run-payroll.css"
import { useEffect, useMemo, useState } from "react"
import { batch, useDispatch, useSelector } from "react-redux"
import { useLocation, useParams } from "react-router-dom"
import axios from "../axios"
import { actions } from "../store/payroll-preview/payroll-preview.reducers"
import { actions as dataActions } from "../store/data/data.reducers"
import { actions as breadCrumbsActions } from "../store/bread-crumbs/bread-crumbs.reducers"
import Loading from "../components/common/Loading"
import RunPayrollStatementTable from "../components/run-payroll-preview/RunPayrollStatementTable"
import { formatMoney, formatNumber, calculateTotalPayAndPerStop, calculateProfit } from "../utils"
import { getBreadCrumbs } from "../store/bread-crumbs/bread-crumbs.selectors"
import ConfirmDeleteModal from "./../components/modals/ConfirmDeleteModal"
import { calculatePercentageOfTotal, getPayrollPeriodString } from "../helpers/helpers"
import toastService from "../services/toastService"
import { getStatementEmployees } from "../store/payroll-preview/payroll-preview.selectors"

const RunPayrollStatement = () => {
  const dispatch = useDispatch()
  const { search } = useLocation()

  const useQuery = () => {
    return useMemo(() => new URLSearchParams(search), [search])
  }

  const query = useQuery()

  const { payrollId, statementId } = useParams()

  const breadCrumbs = useSelector(getBreadCrumbs)
  const employees = useSelector(getStatementEmployees)

  const [loading, setLoading] = useState(false)
  const [isEditComplete, setIsEditComplete] = useState(false)
  const [employeeIdFocus, setEmployeeIdFocus] = useState(query.get("employee"))
  const [deleteUser, setDeleteUser] = useState()
  const [sortItem, setSortItem] = useState()

  useEffect(() => {
    const employeesSortItem = localStorage.getItem("runPayrollPreviewStatement")
    if (employeesSortItem) {
      setSortItem(JSON.parse(employeesSortItem || "{}"))
    }

    fetchStatementList()

    fetchCompanies()
    fetchStations()
    fetchPositions()
  }, [statementId])

  useEffect(() => {
    if (isEditComplete) {
      fetchStatementList()
      setIsEditComplete(false)
    }
  }, [isEditComplete])

  useEffect(() => {
    const employeesSortItem = localStorage.getItem("runPayrollPreviewStatement")

    if (sortItem?.name === "employee_name") {
      localStorage.setItem("runPayrollPreviewStatement", JSON.stringify(sortItem || {}))
    } else if (sortItem?.name && employeesSortItem) {
      localStorage.removeItem("runPayrollPreviewStatement")
    }
  }, [sortItem])

  const fetchCompanies = () => {
    axios
      .get("/company-list")
      .then((response) => {
        dispatch(dataActions.setCompanies(response.data.companies))
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
  }

  const fetchStations = () => {
    axios
      .get("/station-list")
      .then((response) => {
        dispatch(dataActions.setStations(response.data.stations))
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
  }

  const fetchPositions = () => {
    axios
      .get("/settings/positions")
      .then((response) => {
        dispatch(dataActions.setPositions(response.data.positions))
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
  }

  const fetchStatementList = (withFocusEmployeeId = null) => {
    setLoading(true)

    axios
      .get(`statement/${statementId}`)
      .then((response) => {
        const statementData = response.data
        const totalW2 = statementData.weekly_summary.reduce((acc, el) => {
          const { elTotalPay } = calculateTotalPayAndPerStop(el, statementData.station_id)

          if (typeof acc === "number") {
            return acc + elTotalPay
          }

          return elTotalPay
        }, 0)

        const profit = calculateProfit(totalW2, statementData)

        const statementTotalStats = [
          {
            name: "Employees",
            count: statementData.total_employees
          },
          {
            name: "Stops",
            count: formatNumber(statementData.total_stops),
            number: statementData.total_stops
          },
          {
            name: "Fuel",
            count: formatMoney(+statementData.total_fuel === 0 ? 0 : statementData.total_fuel),
            percentage: calculatePercentageOfTotal(
              +statementData.total_fuel,
              statementData.total_charges
            )
          },
          {
            name: "Expenses",
            count: formatMoney(
              +statementData.total_expenses === 0 ? 0 : statementData.total_expenses
            ),
            number: statementData.total_expenses,
            percentage: calculatePercentageOfTotal(
              +statementData.total_expenses,
              statementData.total_charges
            )
          },
          {
            name: "W-2",
            count: formatMoney(totalW2),
            percentage: calculatePercentageOfTotal(totalW2, statementData.total_charges)
          },
          // TODO total_charges - revenue need to check
          {
            name: "Revenue",
            count: formatMoney(statementData.total_charges),
            number: statementData.total_charges
          },
          {
            name: "Per Stop",
            count: formatMoney(statementData.per_stop, true)
          },
          {
            name: "Profit",
            count: formatMoney(profit),
            percentage: calculatePercentageOfTotal(profit, statementData.total_charges)
          }
        ]

        if (breadCrumbs.length === 0 || breadCrumbs.length > 2) {
          dispatch(
            breadCrumbsActions.setBreadCrumbs([
              {
                name: "Payroll",
                url: `/run-payroll`
              },
              {
                name: getPayrollPeriodString(statementData.date),
                url: `/run-payroll-preview/${payrollId}`
              },
              {
                name: `${statementData.company_name}, ${statementData.station_name} ${statementData.station_id}`
              }
            ])
          )
        } else {
          dispatch(
            breadCrumbsActions.setBreadCrumbs([
              ...breadCrumbs,
              {
                name: `${statementData.company_name}, ${statementData.station_name} ${statementData.station_id}`
              }
            ])
          )
        }

        const statementEmployeesWithTotalPay = statementData.weekly_summary.map((el) => {
          const { elTotalPay, perStop } = calculateTotalPayAndPerStop(el, statementData.station_id)

          return {
            ...el,
            totalPay: elTotalPay,
            perStop: perStop
          }
        })

        batch(() => {
          dispatch(actions.setStatementTotalStats(statementTotalStats))
          dispatch(actions.setStatementAllData(statementData))
          dispatch(actions.setStatementEmployees(statementEmployeesWithTotalPay))
        })
        if (withFocusEmployeeId) {
          setEmployeeIdFocus(withFocusEmployeeId)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setLoading(false))
  }

  const handleDeleteEmployee = () => {
    axios
      .delete(`/statement/${statementId}/remove-employee/${deleteUser.employee_id}`)
      .then(() => {
        toastService.success("Employee deleted successfully from this statement")
        // fetchStatementList();
        const filteredEmployee = employees.filter((el) => el.employee_id !== deleteUser.employee_id)
        dispatch(actions.setStatementEmployees(filteredEmployee))
        dispatch(actions.deleteOneFromEmployeeTotalNumber())
        handleCloseDeleteModal()
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
  }

  const handleOpenDeleteModal = (user) => setDeleteUser(user)
  const handleCloseDeleteModal = () => setDeleteUser()

  return (
    <>
      {loading ? (
        <div className="loader-wrapper">
          <Loading />
        </div>
      ) : (
        <RunPayrollStatementTable
          loading={loading}
          setIsEditComplete={setIsEditComplete}
          handleDeleteEmployee={handleOpenDeleteModal}
          setSortItem={setSortItem}
          sortItem={sortItem}
          statementId={statementId}
          fetchStatementList={fetchStatementList}
          employeeIdFocus={employeeIdFocus}
        />
      )}

      <ConfirmDeleteModal
        open={!!deleteUser}
        handleClose={handleCloseDeleteModal}
        handleConfirm={handleDeleteEmployee}
        text="Are you sure delete this Employee from statement?"
      />
    </>
  )
}

export default RunPayrollStatement

import { Pagination, Stack, useMediaQuery } from "@mui/material"

const styles = {
  pagination: {
    color: "#717E94",
    "& .MuiPaginationItem-page": {
      border: "1px solid #EEF0F2",
      borderRadius: "8px"
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#F6F6F6",
      borderColor: "#EEF0F2",
      color: "#344054 !important",
      "&:hover": {
        backgroundColor: "#F6F6F6"
      }
    },
    "& .MuiPagination-ul": {
      "& .MuiPaginationItem-icon": {
        width: 24,
        height: 24
      },
      "& .MuiPaginationItem-root": {
        mx: 0.5,
        border: "1px solid #EEF0F2",
        borderRadius: "8px",
        height: "32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#717E94",
        "& svg": {
          color: "#8D98A9"
        },
        "&.Mui-disabled": {
          opacity: 1,
          border: "1px solid #D4D9DE",
          "&:not(.Mui-selected)": {
            color: "#717E94"
          }
        }
      },
      "& li:first-of-type": {
        borderRadius: "8px",
        "& .MuiPaginationItem-root": {
          mr: "14px"
        }
      },
      "& li:last-child": {
        borderRadius: "8px",
        "& .MuiPaginationItem-root": {
          ml: "14px"
        }
      }
    }
  },
  stackContainer: (isMobile) => ({
    mt: "24px",
    alignItems: isMobile ? "center" : "end"
  })
}

const PaginationComponent = ({ page, total_pages, onChange }) => {
  const isMobile = useMediaQuery("(max-width:990px)")

  if (!page) {
    return null
  }

  return (
    <Stack sx={styles.stackContainer(isMobile)}>
      <Pagination
        count={total_pages}
        variant="outlined"
        shape="rounded"
        page={page}
        onChange={onChange}
        sx={styles.pagination}
        boundaryCount={isMobile ? 0 : 1}
      />
    </Stack>
  )
}

export default PaginationComponent

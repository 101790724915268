import { useState } from "react"
import { useSelector } from "react-redux"
import { Menu, MenuItem } from "@mui/material"
import { Edit, MoreVert } from "@mui/icons-material"
import DeleteIcon from "../../../assets/images/run-payroll/delete-filled.svg"
import Avatar from "../../common/Avatar"

import { getUser } from "../../../store/auth/auth.selectors"
import BlurText from "./../../common/BlurText"

const SettingsMembersTableRaw = ({ userData, handleEdit, handleDeleteMember }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const loggedUser = useSelector(getUser)

  const open = Boolean(anchorEl)

  const handleOpen = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const handleEditOpen = () => {
    handleEdit(userData)
    handleClose()
  }

  const handleDeleteOpen = () => {
    handleDeleteMember(userData)
    handleClose()
  }

  const isDeleteDisabled = userData.id === loggedUser.id

  return (
    <tr>
      <td>
        <div className="statement-first-block">
          <Avatar
            variant="circular"
            className="statement-avatar settings-users-avatar"
            alt={userData.first_name}
            src={userData.avatar}
          />
          <div className="statement-name-block">
            {userData.first_name} <BlurText>{userData.last_name}</BlurText>
          </div>
        </div>
      </td>

      <td>
        <BlurText>{userData.email}</BlurText>
      </td>
      <td>
        <BlurText>{userData.phone}</BlurText>
      </td>
      <td>
        <button className="document-more-btn" onClick={handleOpen}>
          <MoreVert className="document-more-btn-icon" />
        </button>

        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} className="more-menu">
          <MenuItem onClick={handleEditOpen}>
            <button className="document-more-btn menu-item">
              <Edit />
            </button>
            Edit User
          </MenuItem>
          <MenuItem onClick={handleDeleteOpen} disabled={isDeleteDisabled}>
            <button className="document-more-btn menu-item">
              <img src={DeleteIcon} alt="" />
            </button>
            Delete User
          </MenuItem>
        </Menu>
      </td>
    </tr>
  )
}

export default SettingsMembersTableRaw

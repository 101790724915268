import React, { useEffect, useState } from "react"
import "./payroll-process.scss"
import RunPayrollStepFile from "./RunPayrollStepFile"
import { ReactComponent as DocumentUploadBig } from "../../assets/images/run-payroll/document-upload.svg"

const TimeCardUpload = ({ statements = [], handleUploadFile, files, handleDeleteFile }) => {
  const [filteredStatements, setFilteredStatements] = useState([])

  useEffect(() => {
    if (statements.length > 0) {
      const filteredStatementList =
        statements?.reduce((acc, el) => {
          const isExist = acc.find((elem) => elem.company_id === el.company_id)

          if (isExist) {
            return acc
          }

          return [...acc, el]
        }, []) || []
      setFilteredStatements(filteredStatementList)
    }
  }, [statements])

  return (
    <div className="time-card-upload-container">
      {filteredStatements.map((el) => (
        <div key={el.id}>
          <div className={`upload-file ${files[el.company_id] ? "with-file" : ""}`}>
            <div className="upload-file-card-title">{el.company_name}</div>
            <div className="dropdown-text">
              <input
                type="file"
                id="file-uploader"
                accept=".csv"
                onChange={handleUploadFile.bind(null, el.company_id)}
              />
              <div className="icon">
                <DocumentUploadBig />
              </div>
              <h6 className="title">Select a File</h6>
            </div>
          </div>

          {files[el.company_id] && (
            <div className="step-content-files-container">
              <RunPayrollStepFile
                className={`step-content-file`}
                key={files[el.company_id].file?.name}
                fileData={files[el.company_id].file}
                handleDeleteFile={() => handleDeleteFile(el.company_id)}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default TimeCardUpload

import "../../assets/css/modal.css"
import "./scss/activate-subscription-modal.scss"

import { Modal } from "@mui/material"
import { useEffect, useState } from "react"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { loadStripe } from "@stripe/stripe-js"
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements
} from "@stripe/react-stripe-js"
import CloseCircle from "../../assets/images/close-circle.png"
import CardsTypesIcon from "../../assets/images/settings/cards-types.png"
import { ReactComponent as Simplification } from "../../assets/images/settings/simplification.svg"
import { ReactComponent as PlusIcon } from "../../assets/images/settings/plus.svg"
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg"
import Loading from "../common/Loading"
import Button from "../UI/Button/Button"
import CustomCheckbox from "../common/CustomCheckbox"
import InputField from "../common/InputField"
import axios from "../../axios"
import toastService from "../../services/toastService"
import { useDispatch } from "react-redux"
import { actions } from "../../store/auth/auth.reducers"
import { formatMoney } from "./../../utils"
import ActivateSubscriptionModalButtons from "../settings/subscription/ActivateSubscriptionModalButtons"

const schema = yup.object().shape({
  billing_name: yup.string().required("Name is required!")
})

const ActivateSubscriptionModal = ({ open, handleClose, handleConfirm, productPrice }) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(true)
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false)
  const [isSetLikeDefault, setIsSetLikeDefault] = useState(true)
  const [isShowDiscount, setIsShowDiscount] = useState(false)
  const [couponCode, setCouponCode] = useState()
  const [isCouponAdded, setIsCouponAdded] = useState(false)
  const [isApplyCouponLoading, setIsApplyCouponLoading] = useState(false)
  const [price, setPrice] = useState()
  const [discountPrice, setDiscountPrice] = useState()
  const [stationCount, setStationCount] = useState(0)
  const [isCardNumberComplete, setIsCardNumberComplete] = useState(false)
  const [isCardExpiryComplete, setIsCardExpiryComplete] = useState(false)
  const [isCardCvcComplete, setIsCardCvcComplete] = useState(false)
  const [isCardError, setIsCardError] = useState(false)

  const handleChange = (setComplete, event) => setComplete(event.complete)

  const isFormComplete = isCardNumberComplete && isCardExpiryComplete && isCardCvcComplete

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setError,
    formState: { errors, touchedFields }
  } = useForm({
    mode: "all",
    defaultValues: {
      billing_name: ""
    },
    resolver: yupResolver(schema)
  })

  const { billing_name } = watch()

  const handleCheckIfCardError = () => {
    if (!isFormComplete) {
      !billing_name &&
        setError("billing_name", {
          type: "manual",
          message: "Name is required!"
        })
      setIsCardError(true)
      return false
    }
    return true
  }

  const handleIsSetLikeDefaultChange = () => setIsSetLikeDefault(!isSetLikeDefault)

  const handleCouponChange = (e) => setCouponCode(e.target.value)

  const handleCurClose = () => {
    reset()
    handleClose()
    setCouponCode()
    setIsSetLikeDefault(true)
    setIsShowDiscount(false)
    setIsShowDiscount(false)
    setIsCouponAdded(false)
    setPrice()
    setDiscountPrice()
    setStationCount(0)
  }

  const fetchStationsCount = () => {
    axios
      .get("/station-count")
      .then((response) => {
        if (response.success) {
          setStationCount(response.data)
          setPrice(response.data * productPrice)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    if (open && productPrice) {
      setIsLoading(true)
      axios
        .get("/subscription/subscription-price-with-discount")
        .then((response) => {
          const newPrice = response?.data?.price

          if (typeof newPrice === "number" && !isNaN(newPrice)) {
            setDiscountPrice(newPrice)
            setIsLoading(false)
          } else {
            fetchStationsCount()
          }
        })
        .catch((err) => {
          setIsLoading(false)
          toastService.error(err.response?.data?.message || err.message)
        })
    }
  }, [open, productPrice])

  const handleAddCoupon = () => {
    if (couponCode) {
      setIsApplyCouponLoading(true)

      axios
        .get(`coupons/apply/${couponCode}`)
        .then((response) => {
          if (response.success) {
            setIsCouponAdded(true)
            setDiscountPrice(response.data.new_price)
          }
        })
        .catch((err) => toastService.error(err.response?.data?.message || err.message))
        .finally(() => setIsApplyCouponLoading(false))
    }
  }

  const handleSubscriptionSubscribe = (result) =>
    axios
      .post("/subscription/subscribe", {
        paymentMethod: result.setupIntent.payment_method,
        ...(couponCode && isCouponAdded && { coupon_code: couponCode })
      })
      .then((res) => {
        if (res.success) {
          toastService.success(res.message)
          dispatch(actions.setUserIsSubscribed(true))
          handleCurClose()
          setIsSubscriptionLoading(false)
          handleConfirm()
        } else {
          toastService.error(res.data.message)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))

  const onSubmit = (stripe, elements, values) => {
    if (isFormComplete) {
      setIsSubscriptionLoading(true)

      axios
        .get("/subscription/get-setup-intent")
        .then((response) => {
          if (response.success) {
            stripe
              .confirmCardSetup(response.data.intent.client_secret, {
                payment_method: {
                  card: elements.getElement(CardNumberElement),
                  billing_details: {
                    name: values.billing_name
                  }
                }
              })
              .then((result) => {
                if (result.error) {
                  setIsSubscriptionLoading(false)
                  toastService.error("Please check your credit card information and try again")
                } else {
                  if (result.setupIntent.status === "succeeded") {
                    axios
                      .post("/subscription/cards/add", {
                        payment_method_id: result.setupIntent.payment_method
                      })
                      .then((response) => {
                        if (response.success) {
                          if (isSetLikeDefault) {
                            axios
                              .post("/subscription/cards/set-default", {
                                payment_method_id: result.setupIntent.payment_method
                              })
                              .then((response) => {
                                if (response.success) {
                                  handleSubscriptionSubscribe(result)
                                }
                              })
                              .catch((err) => {
                                toastService.error(err.response?.data?.message || err.message)
                                setIsSubscriptionLoading(false)
                              })
                          } else {
                            handleSubscriptionSubscribe(result)
                          }
                        }
                      })
                      .catch((err) => {
                        toastService.error(err.response?.data?.message || err.message)
                        setIsSubscriptionLoading(false)
                      })
                  } else {
                    setIsSubscriptionLoading(false)
                    toastService.error("Please check your credit card information and try again")
                  }
                }
              })
          } else {
            setIsSubscriptionLoading(false)
          }
        })
        .catch((err) => {
          toastService.error(err.response?.data?.message || err.message)
          setIsSubscriptionLoading(false)
        })
    } else {
      setIsCardError(true)
    }
  }

  return (
    <Modal open={open} onClose={handleCurClose}>
      <div className="confirm-modal activate-subscription">
        <div className="confirm-modal-wrapper">
          <button className="popup-close edit-popup-cancel" onClick={handleCurClose}>
            <img src={CloseCircle} alt="" />
          </button>
          Your Free Trial Has Ended
          <div className="confirm-modal-subtitle">
            To continue generating payroll and access all our tools, please renew your subscription
          </div>
          <Elements stripe={stripePromise}>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <div className="activate-subscription-card">
                  <div className="card-title">Card information</div>
                  <div
                    className={`stripe-card-container card-number ${
                      isCardError && !isCardNumberComplete ? "error" : ""
                    }`}
                  >
                    <CardNumberElement
                      onChange={handleChange.bind(null, setIsCardNumberComplete)}
                    />
                    <img src={CardsTypesIcon} alt="" />
                  </div>
                  <div className="card-more-data">
                    <div
                      className={`stripe-card-container ${
                        isCardError && !isCardExpiryComplete ? "error" : ""
                      }`}
                    >
                      <CardExpiryElement
                        onChange={handleChange.bind(null, setIsCardExpiryComplete)}
                      />
                    </div>
                    <div
                      className={`stripe-card-container ${
                        isCardError && !isCardCvcComplete ? "error" : ""
                      }`}
                    >
                      <CardCvcElement onChange={handleChange.bind(null, setIsCardCvcComplete)} />
                      <Simplification />
                    </div>
                  </div>
                </div>
                <form>
                  <InputField
                    title="Cardholder name"
                    placeholder="Full name on card"
                    name="billing_name"
                    errorMessage={errors.billing_name?.message}
                    isError={!!errors.billing_name?.message}
                    isTouched={touchedFields.billing_name}
                    otherProps={register("billing_name")}
                    fullWidth
                    required
                  />
                </form>
                <CustomCheckbox
                  checked={isSetLikeDefault}
                  disabled={isSubscriptionLoading}
                  onChange={handleIsSetLikeDefaultChange}
                  small
                  label={
                    <span onClick={handleIsSetLikeDefaultChange} className="dark-tooltip-text">
                      Set as default payment method
                    </span>
                  }
                />

                <div className="discount-container">
                  <div>Apply a discount</div>
                  {isShowDiscount ? (
                    <CloseIcon onClick={() => setIsShowDiscount(!isShowDiscount)} />
                  ) : (
                    <PlusIcon
                      onClick={() => setIsShowDiscount(!isShowDiscount)}
                      className="plus-icon"
                    />
                  )}
                </div>
                {isShowDiscount && (
                  <div className="discount-form">
                    <InputField
                      placeholder="Enter the discount code"
                      name="coupon"
                      value={couponCode}
                      onChange={handleCouponChange}
                      fullWidth
                      disabled={isCouponAdded}
                    />
                    <Button
                      onClick={handleAddCoupon}
                      type="blue-bg"
                      className="discount-button"
                      disabled={isCouponAdded}
                      text={isApplyCouponLoading ? <Loading /> : "Check"}
                    />
                  </div>
                )}
                {isShowDiscount && isCouponAdded && (
                  <div className="discount-message">Promo code applied successfully!</div>
                )}
                <div className="total-container">
                  <div className="total-container-title">Weekly Plan</div>
                  <div className="total-container-item mb-2">
                    <div className="total-container-item-regular">
                      {stationCount} CSA × {formatMoney(productPrice)} per station
                    </div>
                    <div className="total-weekly-price">
                      {isCouponAdded && (
                        <div className="total-discount">{formatMoney(price || 0, true)}</div>
                      )}
                      {formatMoney(discountPrice || price || 0, true)}
                    </div>
                  </div>
                  {isCouponAdded && (
                    <div className="total-container-item mb-2">
                      <div>Discount</div>
                      <div>-{formatMoney(price - discountPrice || 0, true)}</div>
                    </div>
                  )}
                  <div className="total-container-item">
                    <div>Total</div>
                    <div className="total-container-item-bold">
                      {formatMoney(discountPrice || price || 0, true)}
                    </div>
                  </div>
                </div>
              </>
            )}
            <ActivateSubscriptionModalButtons
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              handleCurClose={handleCurClose}
              isSubscriptionLoading={isSubscriptionLoading}
              handleCheckIfCardError={handleCheckIfCardError}
            />
          </Elements>
        </div>
      </div>
    </Modal>
  )
}

export default ActivateSubscriptionModal

import { useEffect, useState } from "react"
import axios from "../axios"
import dayjs from "dayjs"
import toastService from "../services/toastService"

export const useProductPrice = () => {
  const [productPrice, setProductPrice] = useState(0)

  useEffect(() => {
    const storageProductPrice = localStorage.getItem("productPrice")
    const productPriceDate = localStorage.getItem("productPriceDate")
    const curProductPriceDate = productPriceDate && dayjs(productPriceDate)
    const today = dayjs()
    const isProductPriceDateExpired = curProductPriceDate
      ? curProductPriceDate.isBefore(today, "day")
      : false

    axios
      .get("/subscription/subscription-price")
      .then((response) => {
        const curProductPrice = response.unit_amount / 100
        setProductPrice(curProductPrice)
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
  }, [])

  return { productPrice }
}

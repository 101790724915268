import { useEffect, useState } from "react"
import axios from "../../axios"
import Loading from "./../common/Loading"
import TitleInfo from "../common/TitleInfo"
import SettingsFixedExpensesContent from "./expenses/SettingsFixedExpensesContent"
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal"
import AddCustomExpensesModal from "../modals/AddCustomExpensesModal"
import { isUserSourceRestricted } from "../../helpers/helpers"
import toastService from "../../services/toastService"

const SettingsFixedExpenses = () => {
  const [loading, setLoading] = useState(false)
  const [settingsData, setSettingsData] = useState([])
  const [isUpdateNeed, setIsUpdateNeed] = useState(false)

  const [addOpen, setAddOpen] = useState(false)
  const [isAddLoading, setIsAddLoading] = useState(false)
  const [editedExpenses, setEditedExpenses] = useState()

  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [deletedExpenses, setDeletedExpenses] = useState()

  const isUserRestricted = isUserSourceRestricted()

  useEffect(() => {
    if (!isUpdateNeed) {
      fetchCompanies()
    }

    if (isUpdateNeed) {
      setIsUpdateNeed(false)
    }
  }, [isUpdateNeed])

  const fetchCompanies = () => {
    setLoading(true)

    axios
      .get("/fixed-expenses-settings")
      .then((response) => {
        if (response.success) {
          setSettingsData(response.data.custom_fields)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setLoading(false))
  }

  const handleChangeItem = (itemKey, value) => {
    const items = settingsData.map((el) => (el.key === itemKey ? { ...el, value: value } : el))
    setSettingsData(items)
  }

  const handleAddOpen = () => setAddOpen(true)

  const handleEditCustomOpen = (item) => setEditedExpenses(item)

  const handleAddClose = () => {
    setAddOpen(false)
    editedExpenses && setEditedExpenses()
  }

  const handleAddConfirm = (values, handleCurClose) => {
    setIsAddLoading(true)

    if (editedExpenses) {
      axios
        .put(`/fixed-expenses-settings-custom-fields/${editedExpenses.id}`, values)
        .then((response) => {
          if (response.success) {
            const expensesListWithChange = settingsData.map((el) =>
              el.id === editedExpenses.id
                ? { ...el, cost: values.cost, title: values.title, type: values.type }
                : el
            )
            setSettingsData(expensesListWithChange)
            handleCurClose()
            toastService.success("Expense Updated Successfully")
          }
        })
        .catch((err) => toastService.error(err.response?.data?.message || err.message))
        .finally(() => setIsAddLoading(false))
    } else {
      axios
        .post("/fixed-expenses-settings-custom-fields", values)
        .then((response) => {
          if (response.success) {
            setIsUpdateNeed(true)
            handleCurClose()
            toastService.success("Expense Created Successfully")
          }
        })
        .catch((err) => toastService.error(err.response?.data?.message || err.message))
        .finally(() => setIsAddLoading(false))
    }
  }

  const handleDeleteConfirm = () => {
    setIsDeleteLoading(true)

    axios
      .delete(`/fixed-expenses-settings-custom-fields/${deletedExpenses.id}`)
      .then((response) => {
        if (response.success) {
          setSettingsData(settingsData.filter((el) => !el.id || el.id !== deletedExpenses.id))
          handleCloseDeleteModal()
          toastService.success("Expense Deleted Successfully")
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setIsDeleteLoading(false))
  }

  const handleOpenDeleteModal = (item, e) => {
    e.preventDefault()

    setDeletedExpenses(item)
  }

  const handleCloseDeleteModal = () => setDeletedExpenses()

  return (
    <div className="profile-companies expenses-settings">
      <div className="expenses-settings-header">
        <TitleInfo
          title="Fixed Expenses"
          subTitle="Effortlessly compute your weekly fixed expenses by entering the required information."
          buttons={[
            {
              name: "+ Add Fixed Expense",
              class: "btn-style add-employee-btn",
              onClick: handleAddOpen
            }
          ]}
        />
      </div>

      {loading ? (
        <Loading />
      ) : (
        <SettingsFixedExpensesContent
          settingsData={settingsData}
          handleChangeItem={handleChangeItem}
          handleDelete={handleOpenDeleteModal}
          handleEditCustomOpen={handleEditCustomOpen}
        />
      )}

      <ConfirmDeleteModal
        open={!!deletedExpenses}
        handleClose={handleCloseDeleteModal}
        handleConfirm={handleDeleteConfirm}
        text="Are you sure delete this Fixed Expenses?"
        isLoading={isDeleteLoading}
      />

      <AddCustomExpensesModal
        open={addOpen || !!editedExpenses}
        editedExpenses={editedExpenses}
        handleClose={handleAddClose}
        handleConfirm={handleAddConfirm}
        isLoading={isAddLoading}
      />
    </div>
  )
}

export default SettingsFixedExpenses

import "../../assets/css/modal.css"
import { Modal, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import axios from "../../axios"
import toastService from "../../services/toastService"
import InputMask from "react-input-mask"
import Loading from "../common/Loading"

const styles = {
  error: {
    "& div input": {
      borderColor: "red"
    }
  }
}

const schema = yup.object().shape({
  phone: yup.string().required("Phone is required!")
})

const EmployeeTextReportModal = ({ open, handleClose, employee, payrollId }) => {
  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    setError,
    formState: { errors, touchedFields }
  } = useForm({
    mode: "all",
    defaultValues: {
      phone: ""
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (open) {
      setValue("phone", employee?.phone_no || "", {
        shouldValidate: true,
        shouldDirty: true
      })
    }
  }, [open])

  const handleMainClose = () => {
    reset()
    handleClose()
  }

  const onSubmit = (value) => {
    setIsLoading(true)

    axios
      .post(`/payroll/${payrollId}/send-week-report-for-employee/${employee.id}`, {
        method: "sms",
        value: value.phone
      })
      .then((response) => {
        if (response.success) {
          toastService.success("Report was sent successfully.")

          handleMainClose()
        }
      })
      .catch((err) => {
        const message = err.response?.data?.errors
          ? Object.values(err.response?.data?.errors).join("\n")
          : err.response?.data?.message || err?.message
        toastService.error(message)
        setError("phone", { type: "custom", message: "Check if number correct" })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Modal open={open} onClose={handleMainClose}>
      <div className="confirm-modal">
        <div className="confirm-modal-wrapper add-member-modal">
          <form className="profile-form-sec add-member-modal-form">
            <h3 style={{ fontSize: "18px", marginBottom: "5px", fontWeight: "800" }}>
              Text Report
            </h3>
            <span style={{ fontSize: "12px", fontWeight: "400" }}>
              Enter phone number to send report
            </span>

            <div className="form-item" style={{ marginBottom: "0", marginTop: "1.5rem" }}>
              <div className="input-box">
                <TextField
                  sx={errors.phone?.message ? styles.error : {}}
                  fullWidth
                  type="text"
                  placeholder="(999) 999-9999"
                  name="phone"
                  error={touchedFields.phone && !!errors.phone?.message}
                  helperText={errors.phone?.message}
                  InputProps={{
                    inputComponent: InputMask,
                    inputProps: {
                      mask: "(999) 999-9999",
                      maskChar: " ",
                      required: true,
                      ...register("phone")
                    }
                  }}
                />
              </div>
            </div>
          </form>

          <div className="add-member-form-btn-wrapper">
            <button className="btn-style blank-btn add-popup-cancel" onClick={handleMainClose}>
              Cancel
            </button>
            <button
              className="btn-style add-employee-btn loading-style"
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              {isLoading ? <Loading /> : "Send"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default EmployeeTextReportModal

import { createSelector } from "@reduxjs/toolkit"

export const getPayrollPreview = (state) => state.payrollPreview || {}

export const getPayrollTotalStats = createSelector(
  getPayrollPreview,
  ({ payrollTotalStats }) => payrollTotalStats || []
)
export const getPayrollStatements = createSelector(
  getPayrollPreview,
  ({ payrollStatements }) => payrollStatements || []
)
export const getPayrollData = createSelector(
  getPayrollPreview,
  ({ payrollData }) => payrollData || {}
)
export const getStatementTotalStats = createSelector(
  getPayrollPreview,
  ({ statementTotalStats }) => statementTotalStats || []
)
export const getStatementEmployees = createSelector(
  getPayrollPreview,
  ({ statementEmployees }) => statementEmployees || []
)
export const getStatementAllData = createSelector(
  getPayrollPreview,
  ({ statementAllData }) => statementAllData || {}
)

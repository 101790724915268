import Loading from "../common/Loading"
import ImprovementSincePreviousTimePeriod from "./ImprovementSincePreviousTimePeriod"

const TotalStatisticBlock = ({ value, percentageSubValue, isLoading, text, icon, displayBy }) => {
  const showPercentageImprovements = percentageSubValue && percentageSubValue !== 100
  const periodText = displayBy === 1 || displayBy === 2 ? "last week" : "previous period"

  return (
    <li>
      <div className="total-statistic-block-icon">{icon}</div>
      <div className="total-statistic-block-text">
        <h5>{text}</h5>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <h2 className="total-values">{value}</h2>
            {showPercentageImprovements ? (
              <ImprovementSincePreviousTimePeriod
                value={percentageSubValue}
                previousPeriodText={periodText}
              />
            ) : null}
          </>
        )}
      </div>
    </li>
  )
}

export default TotalStatisticBlock

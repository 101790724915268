import { createSelector } from "@reduxjs/toolkit"

export const getExpensesPreview = (state) => state.expenses || {}

export const getExpensesTotalStats = createSelector(
  getExpensesPreview,
  ({ expensesTotalStats }) => expensesTotalStats || []
)
export const getExpensesArr = createSelector(
  getExpensesPreview,
  ({ expensesArr }) => expensesArr || []
)
export const getExpensesAllData = createSelector(
  getExpensesPreview,
  ({ expensesAllData }) => expensesAllData || {}
)
export const getTotalExpenses = createSelector(
  getExpensesPreview,
  ({ totalExpenses }) => totalExpenses || 0
)

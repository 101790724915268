import { TextField } from "@mui/material"
import DarkTooltip from "./DarkToolTip"

const styles = {
  error: {
    "& div input": {
      borderColor: "red"
    }
  }
}

const InputField = ({
  title,
  placeholder,
  name,
  type,
  isError,
  errorMessage,
  isTouched,
  className,
  otherProps,
  required,
  disabled,
  fullWidth,
  onChange,
  spanText,
  onFocus,
  percentage,
  sx,
  autoWidth,
  tooltipText,
  value
}) => (
  <div className={`form-item ${percentage ? "percentage" : ""} ${autoWidth ? "auto-width" : ""}`}>
    <DarkTooltip title={tooltipText} enterNextDelay={500} enterDelay={500} placement="top" arrow>
      <label className={isError && "error"}>{title}</label>
    </DarkTooltip>

    <div className="input-box">
      {spanText && <span>{spanText}</span>}
      <TextField
        sx={[isError ? styles.error : {}, sx]}
        fullWidth={fullWidth}
        type={type || "text"}
        placeholder={placeholder}
        name={name}
        required={required}
        disabled={disabled}
        className={className}
        {...otherProps}
        {...(onChange ? { onChange } : {})}
        {...(onFocus ? { onFocus } : {})}
        value={value}
        error={isTouched && !!isError}
        helperText={errorMessage}
      />
    </div>
  </div>
)

export default InputField

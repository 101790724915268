import SettingsStationRow from "./SettingsStationRow"
import { ReactComponent as Plus } from "../../../assets/images/settings/plus.svg"
import Loading from "../../common/Loading"
import EmptyState from "../../common/EmptyState"

const SettingsStationList = ({
  stationList,
  handleDelete,
  handleEdit,
  handleAddNewOpen,
  isAddDisabled,
  loading,
  setWorkAreaCount,
  workAreaCount,
  isUserRestricted
}) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Station Name</th>
          <th>Contracted Service Area</th>
          <th>No. of Work Area</th>
          <th>
            {!isUserRestricted && (
              <button
                className="companies-btn add-station"
                onClick={handleAddNewOpen}
                disabled={isAddDisabled}
              >
                <Plus className="companies-add" />
              </button>
            )}
          </th>
        </tr>
      </thead>

      <tbody>
        {loading ? (
          <tr className="statement-more-data-container">
            <td colSpan="16">
              <Loading />
            </td>
          </tr>
        ) : stationList.length < 1 ? (
          <tr className="statement-more-data-container">
            <td colSpan="16">
              <EmptyState title="No added Station" paddingBottom />
            </td>
          </tr>
        ) : (
          stationList.map((el) => (
            <SettingsStationRow
              key={el.id}
              itemData={el}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              setCompanyWorkAreaCount={setWorkAreaCount}
              companyWorkAreaCount={workAreaCount}
              isUserRestricted={isUserRestricted}
            />
          ))
        )}
      </tbody>
    </table>
  )
}

export default SettingsStationList

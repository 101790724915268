import { Tab, Tabs, TextField } from "@mui/material"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import * as yup from "yup"

import { ReactComponent as Danger } from "../../assets/images/run-payroll-preview/danger.svg"
import EmployeeFormPayRates from "./EmployeeFormPayRates"
import axios from "../../axios"
import Loading from "../common/Loading"
import EmployeesModalPayHistory from "../modals/EmployeesModalPayHistory"
import toastService from "../../services/toastService"
import EmployeeFormFooter from "./EmployeeFormFooter"
import EmployeeFormHeader from "./EmployeeFormHeader"
import EmployeeFormDetails from "./EmployeeFormDetails"

const schema = yup.object().shape({
  first_name: yup.string().required("First Name is required!"),
  last_name: yup.string().required("Last Name is required!"),
  fedex_id: yup.string().required("Fedex Id is required!"),
  // employee_id: yup.string().required("Employee Id is required!"),
  contract_service_area: yup.string().required("Contract Service Area is required!"),
  primary_company: yup.string().required("Primary Company is required!"),
  primary_station: yup.string().required("Primary Station is required!"),
  email: yup.string().email("Invalid format")
})

const styles = {
  error: {
    "& div input": {
      borderColor: "red"
    }
  }
}

const EmployeeForm = ({
  handleClose,
  editedUserId,
  setIsEditComplete,
  isPayHistory,
  setIsPayHistory,
  editedUser,
  setEditedUser,
  isCloseRun,
  setIsCloseRun,
  style,
  isAddMissingEmployeeModal,
  setIsAddComplete,
  isLastAddMissingEmployee,
  addingEmployeeData,
  handleSaveMatchEmployeeId
}) => {
  const [tab, setTab] = useState("details")

  const handleTabChange = (_, newValue) => setTab(newValue)

  const [saveLoading, setSaveLoading] = useState(false)
  const [avatar, setAvatar] = useState()
  const [isActive, setIsActive] = useState(!editedUserId)
  const [phone, setPhone] = useState("")
  const [isGusto, setIsGusto] = useState(false)
  const [isPaychex, setIsPaychex] = useState(false)
  const [customErrors, setCustomErrors] = useState({})
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    trigger,
    formState: { errors, touchedFields }
  } = useForm({
    mode: "all",
    defaultValues: {
      first_name: "",
      last_name: "",
      company_position_id: "",
      notes: "",
      fedex_id: null,
      stop_pickup_rate: null,
      stop_delivery_rate: null,
      stop_ecommerce_rate: null,
      package_pickup_rate: null,
      package_delivery_rate: null,
      package_ecommerce_rate: null,
      adding_daily_pay: 1,
      subtracting_daily_pay: 0,
      deleted_stop_bonuses: [],
      deleted_package_bonuses: [],
      deleted_code_85_deduction_bonus: [],
      add_stop_bonus: 0,
      add_package_bonus: 0,
      add_per_package: 0,
      add_ils_addition_bonus: 0,
      add_ils_deduction_bonus: 0,
      add_all_status_code_package_deduction_bonus: 0,
      add_dna_deduction_bonus: 0,
      add_miss_pickup_window_deduction_bonus: 0,
      add_early_late_pickup_deduction_bonus: 0,
      add_code_85_deduction_bonus: 0,
      add_per_stop: 0,
      is_overtime_enabled: 0,
      is_work_area_enabled: 0,
      is_cap_reduction_bonus_enabled: 1
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (editedUserId) {
        reset();
        trigger(["primary_company", "primary_station"]);
    }
  }, [editedUserId, reset, trigger]);

  const {
    primary_station,
    primary_company,
    company_position_id,
    adding_daily_pay,
    stop_bonuses,
    code_85_deduction_bonus,
    package_bonuses,
    deleted_package_bonuses,
    deleted_code_85_deduction_bonus,
    deleted_stop_bonuses,
    add_stop_bonus,
    add_package_bonus,
    add_per_package,
    add_ils_addition_bonus,
    add_ils_deduction_bonus,
    add_all_status_code_package_deduction_bonus,
    add_dna_deduction_bonus,
    all_status_code_package_deduction_bonus,
    add_miss_pickup_window_deduction_bonus,
    add_early_late_pickup_deduction_bonus,
    add_code_85_deduction_bonus,
    add_per_stop,
    is_overtime_enabled,
    weekly_rate_w2,
    is_work_area_enabled,
    is_cap_reduction_bonus_enabled,
    ils_addition_bonus,
    ils_deduction_bonus,
    dna_deduction_bonus,
    miss_pickup_window_deduction_bonus,
    early_late_pickup_deduction_bonus,
    first_name,
    last_name,
    daily_pay,
    paid_time_off,
    training_rate,
    six_day_bonus,
    sunday_bonus
  } = watch()

  useEffect(() => {
    if (addingEmployeeData) {
      addingEmployeeData.first_name &&
        setValue("first_name", addingEmployeeData.first_name, {
          shouldValidate: true,
          shouldDirty: true
        })
      addingEmployeeData.last_name &&
        setValue("last_name", addingEmployeeData.last_name, {
          shouldValidate: true,
          shouldDirty: true
        })
      addingEmployeeData.fedex_id &&
        setValue("fedex_id", addingEmployeeData.fedex_id, {
          shouldValidate: true,
          shouldDirty: true
        })
    }
  }, [addingEmployeeData])

  useEffect(() => {
    if (editedUserId) {
      setLoading(true)
      axios
        .get(`/employee/${editedUserId}`)
        .then((res) => {
          const employeeData = res.data.employee
          const employeeDataRates = employeeData.rates
          setIsGusto(employeeData.is_gusto)

          // Paychex
          setIsPaychex(employeeData.paychex_company_integrations?.length > 0)

          setEditedUser && setEditedUser(employeeData)
          setIsActive(employeeData.active)

          setIsPaychex(employeeData.paychex_company_integrations?.length > 0)

          employeeData.first_name &&
            setValue("first_name", employeeData.first_name, {
              shouldValidate: true,
              shouldDirty: true
            })
          employeeData.last_name &&
            setValue("last_name", employeeData.last_name, {
              shouldValidate: true,
              shouldDirty: true
            })
          employeeData.email &&
            setValue("email", employeeData.email, { shouldValidate: true, shouldDirty: true })
          setPhone(employeeData.phone_no)
          setValue("fedex_id", employeeData.fedex_id, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("employee_id", employeeData.employee_id, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("contract_service_area", employeeData.station?.csa, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("station_id", employeeData.station?.id, {
            shouldValidate: true,
            shouldDirty: true
          })
          employeeData.company_position_id &&
            setValue("company_position_id", employeeData.company_position_id, {
              shouldValidate: true,
              shouldDirty: true
            })
          employeeData.notes &&
            setValue("notes", employeeData.notes, {
              shouldValidate: true,
              shouldDirty: true
            })
          employeeData.primary_company &&
            setValue("primary_company", employeeData.primary_company, {
              shouldValidate: true,
              shouldDirty: true
            })
          employeeData.primary_station &&
            setValue("primary_station", employeeData.station?.csa, {
              shouldValidate: true,
              shouldDirty: true
            })
          if (employeeData.bonuses) {
            const stop_bonuses = employeeData.bonuses.filter((el) => el.per_item === "stop")
            const package_bonuses = employeeData.bonuses.filter((el) => el.per_item === "package")
            setValue("stop_bonuses", stop_bonuses, {
              shouldValidate: true,
              shouldDirty: true
            })
            setValue("package_bonuses", package_bonuses, {
              shouldValidate: true,
              shouldDirty: true
            })
          }
          if (employeeData.bonus_adjustments) {
            const ils_addition_bonuses = employeeData.bonus_adjustments.find(
              (el) => el.action_type === "addition" && el.field === "ils"
            )
            const ils_deduction_bonuses = employeeData.bonus_adjustments.find(
              (el) => el.action_type === "deduction" && el.field === "ils"
            )
            const code_85_deduction_bonuses = employeeData.bonus_adjustments.filter(
              (el) => el.action_type === "deduction" && el.field === "code_85"
            )
            const all_status_code_package_deduction_bonuses = employeeData.bonus_adjustments.find(
              (el) => el.action_type === "deduction" && el.field === "all_status_code_package"
            )
            const dna_deduction_bonuses = employeeData.bonus_adjustments.find(
              (el) => el.action_type === "deduction" && el.field === "dna"
            )
            const miss_pickup_window_deduction_bonuses = employeeData.bonus_adjustments.find(
              (el) => el.action_type === "deduction" && el.field === "miss_pickup_window"
            )
            const early_late_pickup_deduction_bonuses = employeeData.bonus_adjustments.find(
              (el) => el.action_type === "deduction" && el.field === "early_late_pickup"
            )

            setValue("ils_addition_bonus", ils_addition_bonuses, {
              shouldValidate: true,
              shouldDirty: true
            })
            setValue("ils_deduction_bonus", ils_deduction_bonuses, {
              shouldValidate: true,
              shouldDirty: true
            })
            setValue(
              "all_status_code_package_deduction_bonus",
              all_status_code_package_deduction_bonuses,
              {
                shouldValidate: true,
                shouldDirty: true
              }
            )
            setValue("dna_deduction_bonus", dna_deduction_bonuses, {
              shouldValidate: true,
              shouldDirty: true
            })
            setValue("miss_pickup_window_deduction_bonus", miss_pickup_window_deduction_bonuses, {
              shouldValidate: true,
              shouldDirty: true
            })
            setValue("early_late_pickup_deduction_bonus", early_late_pickup_deduction_bonuses, {
              shouldValidate: true,
              shouldDirty: true
            })
            setValue("code_85_deduction_bonus", code_85_deduction_bonuses, {
              shouldValidate: true,
              shouldDirty: true
            })
          }
          setValue("is_overtime_enabled", employeeData.rates.is_overtime_enabled, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("is_work_area_enabled", employeeData.rates.is_work_area_enabled || 0, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue(
            "is_cap_reduction_bonus_enabled",
            employeeData.rates.is_cap_reduction_bonus_enabled || 0,
            {
              shouldValidate: true,
              shouldDirty: true
            }
          )
          setValue("training_rate", employeeDataRates.training_rate, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("adding_daily_pay", employeeDataRates.adding_daily_pay, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("subtracting_daily_pay", employeeDataRates.subtracting_daily_pay, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("weekly_rate_w2", employeeDataRates.weekly_rate_w2, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("daily_pay", employeeDataRates.daily_pay, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("hourly_rate", employeeDataRates.hourly_rate, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("six_day_bonus", employeeDataRates.six_day_bonus, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("sunday_bonus", employeeDataRates.sunday_bonus, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("paid_time_off", employeeDataRates.paid_time_off, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("stop_pickup_rate", employeeDataRates.stop_pickup_rate, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("stop_delivery_rate", employeeDataRates.stop_delivery_rate, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("stop_ecommerce_rate", employeeDataRates.stop_ecommerce_rate, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("package_pickup_rate", employeeDataRates.package_pickup_rate, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("package_delivery_rate", employeeDataRates.package_delivery_rate, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("package_ecommerce_rate", employeeDataRates.package_ecommerce_rate, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("add_per_stop", employeeDataRates.add_per_stop, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("add_per_package", employeeDataRates.add_per_package, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("add_ils_addition_bonus", employeeDataRates.add_ils_addition_bonus, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("add_ils_deduction_bonus", employeeDataRates.add_ils_deduction_bonus, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue(
            "add_all_status_code_package_deduction_bonus",
            employeeDataRates.add_all_status_code_package_deduction_bonus,
            {
              shouldValidate: true,
              shouldDirty: true
            }
          )
          setValue("add_dna_deduction_bonus", employeeDataRates.add_dna_deduction_bonus, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue(
            "add_miss_pickup_window_deduction_bonus",
            employeeDataRates.add_miss_pickup_window_deduction_bonus,
            {
              shouldValidate: true,
              shouldDirty: true
            }
          )
          setValue(
            "add_early_late_pickup_deduction_bonus",
            employeeDataRates.add_early_late_pickup_deduction_bonus,
            {
              shouldValidate: true,
              shouldDirty: true
            }
          )
          setValue("add_code_85_deduction_bonus", employeeDataRates.add_code_85_deduction_bonus, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("add_stop_bonus", employeeDataRates.add_stop_bonus, {
            shouldValidate: true,
            shouldDirty: true
          })
          setValue("add_package_bonus", employeeDataRates.add_package_bonus, {
            shouldValidate: true,
            shouldDirty: true
          })
        })
        .catch(() => {
          handleClose()
          toastService.error("This Employee cannot be edited")
        })
        .finally(() => setLoading(false))
    }
  }, [editedUserId])

  const handleCurrentClose = () => {
    handleClose()
    reset()
    setValue("primary_company", null, { shouldDirty: true })
    setValue("primary_station", null, { shouldDirty: true })
    setValue("station_id", null, { shouldDirty: true })
    setAvatar()
    setIsPayHistory && setIsPayHistory(false)
  }

  const handlePayHistoryClose = () => setIsPayHistory && setIsPayHistory(false)

  const isNoRates =
    !+weekly_rate_w2 &&
    (!paid_time_off || paid_time_off === "0.00") &&
    (!training_rate || training_rate === "0.00") &&
    (!daily_pay || daily_pay === "0.00") &&
    !+add_per_stop &&
    !+add_per_package &&
    !+six_day_bonus &&
    !+sunday_bonus

  document?.body.addEventListener("keydown", function (e) {
    if (e.key == "Escape" && open) {
      handleCurrentClose()
    }
  })

  useEffect(() => {
    if (isCloseRun) {
      handleCurrentClose()
      setIsCloseRun && setIsCloseRun(false)
    }
  }, [isCloseRun])

  useEffect(() => {
    if (Object.keys(errors).length && tab !== "details") {
      setTab("details")
    }
  }, [errors])

  if (loading) {
    return <Loading />
  }

  return (
    <div
      className="popup-dis-sec employees-modal"
      style={{ ...style, justifyContent: isPayHistory ? "flex-start" : "space-between" }}
    >
      {isPayHistory ? (
        <EmployeesModalPayHistory
          editedUserId={editedUserId}
          handlePayHistoryClose={handlePayHistoryClose}
        />
      ) : (
        <>
          <div className="popup-content-sec-main basic-info-sec">
            <EmployeeFormHeader
              {...{
                setAvatar,
                setIsPayHistory,
                editedUserId,
                setIsEditComplete,
                setIsActive,
                isActive,
                editedUser,
                isGusto,
                isPaychex,
                avatar,
                saveLoading,
                first_name,
                last_name
              }}
            />

            <Tabs
              value={tab}
              onChange={handleTabChange}
              variant="fullWidth"
              className="employee-modal-tabs"
            >
              <Tab value="details" label="Details" />
              <Tab
                value="pay-rates"
                label={
                  <div className="employee-tab-danger">Pay Rates {isNoRates && <Danger />}</div>
                }
              />
              <Tab value="notes" label="Notes" />
            </Tabs>
            <form
              className={`new-employees-basic-info ${tab === "details" ? "details" : ""}`}
              style={{
                height: isAddMissingEmployeeModal
                  ? `${((window?.innerHeight || 500) / 100) * 50}px`
                  : "auto"
              }}
            >
              {tab === "details" && (
                <EmployeeFormDetails
                  {...{
                    errors,
                    setValue,
                    isActive,
                    register,
                    touchedFields,
                    editedUserId,
                    phone,
                    setPhone,
                    isPaychex,
                    primary_company,
                    primary_station,
                    company_position_id
                  }}
                />
              )}
              {tab === "pay-rates" && (
                <div className="popup-content-sec-main new-employees-pay-adjustment">
                  <EmployeeFormPayRates
                    is_overtime_enabled={is_overtime_enabled}
                    add_stop_bonus={add_stop_bonus}
                    add_package_bonus={add_package_bonus}
                    add_per_package={add_per_package}
                    add_ils_addition_bonus={add_ils_addition_bonus}
                    add_ils_deduction_bonus={add_ils_deduction_bonus}
                    add_all_status_code_package_deduction_bonus={
                      add_all_status_code_package_deduction_bonus
                    }
                    add_dna_deduction_bonus={add_dna_deduction_bonus}
                    add_per_stop={add_per_stop}
                    add_miss_pickup_window_deduction_bonus={add_miss_pickup_window_deduction_bonus}
                    add_early_late_pickup_deduction_bonus={add_early_late_pickup_deduction_bonus}
                    add_code_85_deduction_bonus={add_code_85_deduction_bonus}
                    all_status_code_package_deduction_bonus={
                      all_status_code_package_deduction_bonus
                    }
                    dna_deduction_bonus={dna_deduction_bonus}
                    early_late_pickup_deduction_bonus={early_late_pickup_deduction_bonus}
                    miss_pickup_window_deduction_bonus={miss_pickup_window_deduction_bonus}
                    ils_addition_bonus={ils_addition_bonus}
                    ils_deduction_bonus={ils_deduction_bonus}
                    register={register}
                    touchedFields={touchedFields}
                    errors={errors}
                    isActive={isActive}
                    adding_daily_pay={adding_daily_pay}
                    setValue={setValue}
                    code_85_deduction_bonus={code_85_deduction_bonus || []}
                    stop_bonuses={stop_bonuses || []}
                    package_bonuses={package_bonuses || []}
                    deleted_stop_bonuses={deleted_stop_bonuses || []}
                    deleted_package_bonuses={deleted_package_bonuses || []}
                    deleted_code_85_deduction_bonus={deleted_code_85_deduction_bonus || []}
                    weekly_rate_w2={weekly_rate_w2}
                    is_work_area_enabled={is_work_area_enabled}
                    is_cap_reduction_bonus_enabled={is_cap_reduction_bonus_enabled}
                    customErrors={customErrors}
                    setCustomErrors={setCustomErrors}
                  />
                </div>
              )}
              {tab === "notes" && (
                <div className="notes-into">
                  <label>Notes</label>
                  <TextField
                    sx={errors.notes?.message ? styles.error : {}}
                    fullWidth
                    multiline
                    rows={4}
                    type="text"
                    placeholder="Enter notes..."
                    name="notes"
                    required
                    disabled={!isActive}
                    {...register("notes")}
                    error={touchedFields.notes && !!errors.notes?.message}
                    helperText={errors.notes?.message}
                  />
                </div>
              )}
            </form>
          </div>

          <EmployeeFormFooter
            {...{
              handleSaveMatchEmployeeId,
              handleCurrentClose,
              setSaveLoading,
              setIsEditComplete,
              setIsAddComplete,
              setTab,
              setAvatar,
              avatar,
              isLastAddMissingEmployee,
              isAddMissingEmployeeModal,
              handleSubmit,
              saveLoading,
              setValue,
              reset,
              customErrors,
              isActive,
              editedUserId,
              editedUser,
              phone,
              deleted_code_85_deduction_bonus,
              deleted_stop_bonuses,
              deleted_package_bonuses,
              code_85_deduction_bonus,
              ils_addition_bonus,
              add_ils_addition_bonus,
              ils_deduction_bonus,
              add_ils_deduction_bonus,
              all_status_code_package_deduction_bonus,
              add_all_status_code_package_deduction_bonus,
              dna_deduction_bonus,
              add_dna_deduction_bonus,
              miss_pickup_window_deduction_bonus,
              add_miss_pickup_window_deduction_bonus,
              early_late_pickup_deduction_bonus,
              add_early_late_pickup_deduction_bonus
            }}
          />
        </>
      )}
    </div>
  )
}

export default EmployeeForm

import { useEffect, useState } from "react"
import { batch, useDispatch, useSelector } from "react-redux"
import SearchBig from "../../assets/images/search-big.png"
import axios from "../../axios"
import Loading from "../common/Loading"
import Pagination from "../common/Pagination"
import EmptyState from "../common/EmptyState"
import { actions } from "../../store/pending-requests/pending-requests.reducers"
import PendingRequestsTable from "./PendingRequestsTable"
import ConfirmDeleteModal from "./../modals/ConfirmDeleteModal"
import {
  getPendingRequests,
  getPendingRequestsData
} from "./../../store/pending-requests/pending-requests.selectors"
import { ThumbDownAltOutlined, VerifiedOutlined } from "@mui/icons-material"
import ConfirmRecalculateModal from "./../modals/ConfirmRecalculateModal"
import toastService from "../../services/toastService"

const PendingRequestsContainer = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [isNeedUpdate, setIsNeedUpdate] = useState(false)
  const [isOpenDeclineModal, setIsOpenDeclineModal] = useState(false)
  const [declineRequest, setDeclineRequest] = useState()
  const [isOpenApproveModal, setIsOpenApproveModal] = useState(false)
  const [approveRequest, setApproveRequest] = useState()
  const [isApproveLoading, setIsApproveLoading] = useState(false)
  const [isDeclineLoading, setIsDeclineLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")

  const requests = useSelector(getPendingRequests)
  const requestsData = useSelector(getPendingRequestsData)

  useEffect(() => {
    if (isNeedUpdate) {
      setIsNeedUpdate(false)
      return
    }

    const handler = setTimeout(() => !isNeedUpdate && fetchOrganizationsList(page), 500)

    return () => {
      clearTimeout(handler)
    }
  }, [searchQuery, isNeedUpdate])

  const fetchOrganizationsList = (pageNumber) => {
    setLoading(true)

    axios
      .get(`/admin/organizations?page=${pageNumber}&search=${searchQuery}&status=Pending`)
      .then((response) => {
        batch(() => {
          dispatch(actions.setPendingRequests(response.data.data))
          dispatch(actions.setPendingRequestsData(response.data))
        })
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setLoading(false))
  }

  const handleChange = (event, value) => {
    setPage(value)
    fetchOrganizationsList(value)
  }

  const handleApprove = () => {
    setIsApproveLoading(true)

    axios
      .post(`/admin/organizations/${approveRequest.id}/approve`)
      .then((response) => {
        if (response.success) {
          toastService.success("Organization approved")
          setPage(1)
          fetchOrganizationsList(1)
          setIsOpenApproveModal(false)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setIsApproveLoading(false))
  }

  const handleDecline = () => {
    setIsDeclineLoading(true)

    axios
      .post(`/admin/organizations/${declineRequest.id}/reject`)
      .then((response) => {
        if (response.success) {
          toastService.success("Organization rejected")
          setPage(1)
          fetchOrganizationsList(1)
          setIsOpenDeclineModal(false)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setIsDeclineLoading(false))
  }

  const handleQueryChange = (e) => {
    setSearchQuery(e.target.value || "")
    setPage(1)
  }

  const handleOpenDeclineModal = (item) => {
    setDeclineRequest(item)
    setIsOpenDeclineModal(true)
  }

  const handleCloseDeclineModal = () => setIsOpenDeclineModal(false)

  const handleOpenApproveModal = (item) => {
    setApproveRequest(item)
    setIsOpenApproveModal(true)
  }

  const handleCloseApproveModal = () => setIsOpenApproveModal(false)

  return (
    <div
      className="employees-data-sec organizations"
      style={{ height: loading ? "400px" : "auto" }}
    >
      {(requests.length > 0 || searchQuery.length > 1) && (
        <div className="payroll-top">
          <div className="payroll-result-count">{requestsData.total} requests</div>
          <div className="search-box">
            <img src={SearchBig} alt="" />
            <input
              type="text"
              placeholder="Search item"
              value={searchQuery}
              onChange={handleQueryChange}
            />
          </div>
        </div>
      )}
      {!requests.length && !loading ? (
        <EmptyState title="No Pending Requests" />
      ) : loading ? (
        <div className="loader-wrapper">
          <Loading />
        </div>
      ) : (
        <>
          <div className="table-scroll-mobile payroll-table employees" style={{ height: "auto" }}>
            <PendingRequestsTable
              requestsList={requests}
              handleApprove={handleOpenApproveModal}
              handleDecline={handleOpenDeclineModal}
            />
          </div>
          {requestsData.last_page > 1 && (
            <Pagination page={page} total_pages={requestsData.last_page} onChange={handleChange} />
          )}
        </>
      )}

      <ConfirmDeleteModal
        open={isOpenDeclineModal}
        handleClose={handleCloseDeclineModal}
        handleConfirm={handleDecline}
        text="Are you sure decline this Organization?"
        isLoading={isDeclineLoading}
        confirmButtonText="Decline"
        icon={<ThumbDownAltOutlined className="confirm-modal-icon" />}
      />

      <ConfirmRecalculateModal
        open={isOpenApproveModal}
        handleClose={handleCloseApproveModal}
        handleConfirm={handleApprove}
        text="Are you sure approve this Organization?"
        isLoading={isApproveLoading}
        confirmButtonText="Approve"
        icon={<VerifiedOutlined className="confirm-modal-icon" />}
      />
    </div>
  )
}

export default PendingRequestsContainer

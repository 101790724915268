import "./button.scss"

const Button = ({ onClick, icon, text, type, bottomType, className, disabled }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type={bottomType}
      className={`button-style ${type ? type : ""} ${className ? className : ""}`}
    >
      {icon}
      {text}
    </button>
  )
}

export default Button

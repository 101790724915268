import { companyIcons } from "../../constants"

const supportedApps = ["gusto", "paychex"]
const testModeApps = ["gusto"]

const CompanyCard = ({
  cardData,
  handleConnect,
  isTestMode,
  isConnected,
  directIntegrations,
  templateIntegrations
}) => {
  const { name, description, key } = cardData

  const isSupported = supportedApps.includes(key)
  const inTestMode = testModeApps.includes(key)

  const getButton = () => {
    let disabled = true
    let text = "Coming soon"

    const showForEverybody = isSupported && !inTestMode
    const showForTestMode = inTestMode && isTestMode

    if (showForEverybody || showForTestMode) {
      disabled = false
      text = "Connect now"

      if (templateIntegrations) {
        disabled = true
      }
    }

    if (isConnected && !templateIntegrations) {
      disabled = false
      text = "Connected"
    }

    return (
      <button
        onClick={handleConnect.bind(null, cardData.key)}
        className={`btn-style btn-outline small-btn ${
          text === "Connect now" && !templateIntegrations ? "companies-btn connect-now" : ""
        }`}
        disabled={disabled}
      >
        {text}
      </button>
    )
  }
  return (
    <li>
      <div className="logo-btn-sec">
        <div className="app-logo">
          <img src={companyIcons[key]} alt="" />
          <div className="app-text">
            <h5>{name}</h5>
            <p>{description}</p>
          </div>
        </div>

        {getButton()}
      </div>
    </li>
  )
}

export default CompanyCard

import { useState } from "react"
import { MoreVert } from "@mui/icons-material"
import { Menu, MenuItem } from "@mui/material"
import { useSelector } from "react-redux"
import ConfirmDeleteModal from "../../modals/ConfirmDeleteModal"
import axios from "../../../axios"
import { ReactComponent as MasterCard } from "../../../assets/images/settings/master-card.svg"
import { ReactComponent as Visa } from "../../../assets/images/settings/visa.svg"
import { getUser } from "../../../store/auth/auth.selectors"
import toastService from "../../../services/toastService"

const SubscriptionPaymentMethodItem = ({ card, setIsNeedRefresh }) => {
  const userData = useSelector(getUser)

  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [isDefaultLoading, setIsDefaultLoading] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleClose = () => setAnchorEl(null)

  const handleOpenDeleteModal = () => {
    setIsOpenDeleteModal(true)
    setAnchorEl(null)
  }
  const handleCloseDeleteModal = () => setIsOpenDeleteModal(!isOpenDeleteModal)
  const handleOpen = (event) => setAnchorEl(event.currentTarget)

  const handleSetDefault = () => {
    setIsDefaultLoading(true)

    axios
      .post("subscription/cards/set-default", { payment_method_id: card.id })
      .then((res) => {
        if (res.success) {
          toastService.success(res.message)
          setIsNeedRefresh(true)
          setAnchorEl(null)
        } else {
          toastService.error(res.data.message)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setIsDefaultLoading(false))
  }

  const handleConfirmDelete = () => {
    setIsDeleteLoading(true)

    axios
      .delete(`/subscription/cards/${card.id}`)
      .then((res) => {
        if (res.success) {
          toastService.success(res.message)
          setIsNeedRefresh(true)
          handleCloseDeleteModal()
        } else {
          toastService.error(res.data.message)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setIsDeleteLoading(false))
  }

  const isDeleteDisabled = card.active && card.length < 2 && userData.is_subscribed

  return (
    <div className={`subscription-payment-method-item ${card.active && "active"}`}>
      <div className="subscription-payment-method-item-info">
        {card.card.brand === "visa" ? (
          <Visa className="subscription-payment-method-item-icon" />
        ) : (
          <MasterCard className="subscription-payment-method-item-icon" />
        )}
        <div className="subscription-payment-method-item-info-text">
          <div className="subscription-payment-method-item-info-ending">
            Card ending in {card.card.last4}
          </div>
          <div>
            Expired {card.card.exp_month}/{card.card.exp_year}
          </div>
          <div>Card holder name: {card.billing_details.name}</div>
        </div>
      </div>

      <div>
        <button className="subscription-payment-more-btn document-more-btn" onClick={handleOpen}>
          <MoreVert className="document-more-btn-icon" />
        </button>

        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} className="more-menu">
          <MenuItem onClick={handleSetDefault} disabled={card.active || isDefaultLoading}>
            Set default
          </MenuItem>
          <MenuItem onClick={handleOpenDeleteModal} disabled={isDeleteDisabled}>
            Delete
          </MenuItem>
        </Menu>
      </div>

      <ConfirmDeleteModal
        open={isOpenDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleConfirm={handleConfirmDelete}
        text="Are you sure delete this payment method?"
        isLoading={isDeleteLoading}
      />
    </div>
  )
}

export default SubscriptionPaymentMethodItem

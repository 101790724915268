import React from "react"
import "./styles.scss"

const CircularProgressBar = ({ total, uploaded }) => {
  const radius = 12 // Radius of the circle
  const stroke = 4 // Width of the stroke
  const normalizedRadius = radius - stroke / 2 // Adjusted for stroke width
  const circumference = 2 * Math.PI * normalizedRadius // Circumference of the circle
  const progress = (uploaded / total) * 100 // Calculate progress as a percentage
  const strokeDashoffset = circumference - (progress / 100) * circumference // Progress offset

  return (
    <svg height={radius * 2} width={radius * 2} className="circular-progress">
      {/* Background Circle */}
      <circle
        stroke="#EBF0FE"
        fill="transparent"
        strokeWidth={stroke}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      {/* Progress Circle */}
      <circle
        className="circle-filled"
        stroke={total === uploaded ? "#16AB6D" : "#4253F0"}
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        transform={`rotate(-90 ${radius} ${radius})`}
      />
    </svg>
  )
}

export default CircularProgressBar

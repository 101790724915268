import "../../assets/css/modal.css"
import { Modal } from "@mui/material"
import { InfoOutlined } from "@mui/icons-material"
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg"
import { useProductPrice } from "../../hooks/useProductPrice"
import { formatMoney } from "../../utils"

const ChangeCSANumberModal = ({ open, handleClose, handleConfirm }) => {
  const { productPrice } = useProductPrice()

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="confirm-modal csa">
        <div className="confirm-modal-wrapper">
          <div className="confirm-modal-close-wrapper" onClick={handleClose}>
            <CloseIcon />
          </div>

          <div className="confirm-modal-icon-wrapper csa">
            <InfoOutlined className="confirm-modal-icon" />
          </div>

          <div className="csa-modal-title">Upgrade your plan</div>
          <div className="csa-modal-text">
            To add more stations, please upgrade your plan. After you agree to upgrade your plan and
            add new station, the plan will increase by <b>{formatMoney(productPrice, true)}</b>
          </div>

          <div className="confirm-modal-btn-wrapper">
            <button className="confirm-modal-btn cancel" onClick={handleClose}>
              Cancel
            </button>
            <button className="confirm-modal-btn company" onClick={handleConfirm}>
              Upgrade
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ChangeCSANumberModal

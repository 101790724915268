import PendingRequestsContainer from "../../components/pending-requests/PendingRequestsContainer"
import TitleInfo from "../../components/common/TitleInfo"

const PendingRequests = () => {
  return (
    <>
      <TitleInfo title="Pending Requests" subTitle="All the Pending Requests" />

      <PendingRequestsContainer />
    </>
  )
}

export default PendingRequests

import { useState } from "react"
import axios from "../../../axios"
import Loading from "../../../components/common/Loading"
import EmptyState from "../../../components/common/EmptyState"
import ConfirmDeleteModal from "../../../components/modals/ConfirmDeleteModal"
import CouponsTableRaw from "./CouponsTableRaw"
import toastService from "../../../services/toastService"

const CouponsContainer = ({ isLoading, coupons, setIsUpdateNeed }) => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [deleteItem, setDeleteItem] = useState(false)

  const handleDelete = () => {
    setIsDeleteLoading(true)

    axios
      .delete(`/admin/coupons/${deleteItem.id}`)
      .then((response) => {
        if (response.success) {
          toastService.success("Coupon deleted successfully")
          setIsOpenDeleteModal(false)
          setIsUpdateNeed(true)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setIsDeleteLoading(false))
  }

  const handleOpenDeleteModal = (item) => {
    setDeleteItem(item)
    setIsOpenDeleteModal(true)
  }

  const handleCloseDeleteModal = () => setIsOpenDeleteModal(false)

  return (
    <div
      className="employees-data-sec organizations"
      style={{ height: isLoading ? "400px" : "auto" }}
    >
      {(coupons.length > 0 || coupons.length > 1) && !isLoading && (
        <div className="payroll-top">
          <div className="payroll-result-count">{coupons.length} requests</div>
        </div>
      )}
      {!coupons.length && !isLoading ? (
        <EmptyState title="No Coupons yet" />
      ) : isLoading ? (
        <div className="loader-wrapper">
          <Loading />
        </div>
      ) : (
        <div
          className="table-scroll-mobile payroll-table employees"
          style={{ maxHeight: "70vh", overflow: "auto" }}
        >
          <table>
            <thead>
              <tr>
                <th>Coupon name</th>
                <th>Coupon code</th>
                <th>Percent off</th>
                <th>Amount off</th>
                <th>Duration</th>
                <th>Number of months</th>
                <th>Options</th>
              </tr>
            </thead>

            <tbody>
              {coupons.map((item) => (
                <CouponsTableRaw
                  key={item.id}
                  couponData={item}
                  handleDelete={handleOpenDeleteModal}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}

      <ConfirmDeleteModal
        open={isOpenDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleConfirm={handleDelete}
        text="Are you sure delete this Coupon?"
        isLoading={isDeleteLoading}
      />
    </div>
  )
}

export default CouponsContainer

import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { batch, useDispatch } from "react-redux"
import DashboardImg from "../assets/images/dashboard-img.png"
import GoogleWhiteBig from "../assets/images/login/google-white-big.png"
import PaychexBig from "../assets/images/login/paychex-big.png"
import FedExLogoBig from "../assets/images/login/FedEx-Logo-big.png"
import StripeBig from "../assets/images/login/Stripe-big.png"
import LoginLogo from "../components/common/LoginLogo"
import Loading from "../components/common/Loading"
import axios from "../axios"
import { actions } from "../store/auth/auth.reducers"
import toastService from "../services/toastService"
import dayjs from "dayjs"

const SocialiteGoogle = () => {
  const { search } = useLocation()
  const dispatch = useDispatch()
  const nav = useNavigate()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    axios
      .get(`/auth/social/google/callback${search}`)
      .then((res) => {
        if (res && !res.success) {
          toastService.error(res.message)
          return
        }

        const data = res?.data

        batch(() => {
          dispatch(actions.setToken(data.token))
          dispatch(actions.loginUser(data.user))
          localStorage.setItem("user", JSON.stringify(data.user || {}))
        })

        localStorage.setItem("token", data.token)
        return
      })
      .catch((err) => {
        nav("/login")
        toastService.error(err.response?.data?.message || err.message)
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <>
      <LoginLogo />

      <div className="login-main-sec">
        <div className="login-left-sec">
          <div className="inner-sec">
            <h1>Login page</h1>
            <br></br>
            {loading ? (
              <>
                <p>Login in progress</p>
                <br></br>
                <Loading />
              </>
            ) : (
              <p>Login success</p>
            )}
          </div>
        </div>

        <div className="login-right-sec">
          <div className="inner-sec">
            <h2>The simplest way to do payroll for FedEx contractor</h2>
            <p>Enter your credentials to access your account</p>
            <img src={DashboardImg} alt="" />
            <div className="login-marquee">
              <div className="track">
                <img src={GoogleWhiteBig} alt="" />
                <img src={PaychexBig} alt="" />
                <img src={FedExLogoBig} alt="" />
                <img src={StripeBig} alt="" />
                <img src={GoogleWhiteBig} alt="" />
                <img src={PaychexBig} alt="" />
                <img src={FedExLogoBig} alt="" />
                <img src={StripeBig} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="copyright-sec">
          <p>{dayjs().year()} Fleet Wage Inc, All right Reserved</p>
        </div>
      </div>
    </>
  )
}

export default SocialiteGoogle

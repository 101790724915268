import { KeyboardArrowDown } from "@mui/icons-material"
import { FormHelperText, MenuItem, Select } from "@mui/material"

const styles = {
  errorSelect: {
    borderColor: "red"
  },
  selectErrorMessage: {
    color: "red !important",
    margin: "-8px 14px 0"
  }
}

const SelectField = ({
  title,
  name,
  value,
  isError,
  otherProps,
  required,
  disabled,
  options,
  onChange,
  optionValueName,
  isOnlySelectError,
  fullWidth,
  placeholder,
  doNotHideIfNoOptions
}) => {
  if (!options.length && !doNotHideIfNoOptions) return null
  return (
    <div className="form-item">
      <label className={isError && "error"}>{title}</label>
      <div className="input-box">
        <Select
          fullWidth={fullWidth}
          sx={isError ? styles.errorSelect : {}}
          required={required}
          className="employees-select"
          name={name}
          disabled={disabled}
          value={value || ""}
          renderValue={(value) => {
            if (!value) {
              return placeholder
            }
            const curValue = options.find((el) => el[optionValueName || "id"] === value)
            return curValue?.name || ""
          }}
          displayEmpty
          {...otherProps}
          {...(onChange ? { onChange } : {})}
          IconComponent={KeyboardArrowDown}
          error={isOnlySelectError}
        >
          {options?.map((el) => (
            <MenuItem key={el.id} value={el[optionValueName || "id"]}>
              {el.name}
            </MenuItem>
          ))}
        </Select>

        {!!isError && (
          <FormHelperText
            className="select-helper-text"
            component="div"
            error={!!isError}
            sx={styles.selectErrorMessage}
          >
            {isError}
          </FormHelperText>
        )}
      </div>
    </div>
  )
}

export default SelectField

import { DragHandle } from "./DragHandle"
import { formatMoney, formatNumber } from "../../utils"

export const RunPayrollPreviewStaticRow = ({ row }) => {
  const {
    total_w2,
    total_charges,
    total_fuel,
    total_stops,
    station_id,
    station_name,
    company_name,
    total_expenses,
    profit,
    exp_stop
  } = row

  return (
    <tr
      style={{
        boxShadow: "rgb(0 0 0 / 10%) 0px 20px 25px -5px, rgb(0 0 0 / 30%) 0px 10px 10px -5px",
        outline: "#3e1eb3 solid 1px",
        height: "44px"
      }}
    >
      <td style={{ maxWidth: 200 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <DragHandle isDragging />
          {company_name}
        </div>
      </td>
      <td>{station_name}</td>
      <td>{station_id}</td>
      <td>{formatNumber(total_stops)}</td>
      <td>{formatMoney(total_fuel, true)}</td>
      <td>{formatMoney(total_expenses, true)}</td>
      <td>{formatMoney(total_w2, true)}</td>
      <td>{formatMoney(total_charges, true)}</td>
      <td>{formatMoney(exp_stop || 0, true)}</td>
      <td>{formatMoney(profit, true)}</td>
    </tr>
  )
}

import { useEffect, useState } from "react"
import AddMemberModal from "../modals/AddMemberModal"
import axios from "../../axios"
import Loading from "./../common/Loading"
import SettingsMembersTable from "./members/SettingsMembersTable"
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal"
import toastService from "../../services/toastService"

const SettingsMembers = () => {
  const [open, setOpen] = useState(false)
  const [isAddComplete, setIsAddComplete] = useState(false)
  const [editedMember, setEditedMember] = useState()
  const [deletedMember, setDeletedMember] = useState()
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [membersList, setMembersList] = useState([])

  useEffect(() => {
    if (isAddComplete) {
      setIsAddComplete(false)
    }

    setLoading(true)

    axios
      .get("/settings/members")
      .then((response) => {
        if (response.success) {
          setMembersList(response.data.members)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setLoading(false))
  }, [isAddComplete])

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    editedMember && setEditedMember()
    setOpen(false)
  }

  const handleEdit = (item) => setEditedMember(item)

  const handleDeleteMember = (item) => setDeletedMember(item)
  const handleCloseDeleteModal = () => setDeletedMember()

  const handleDeleteConfirm = () => {
    setIsDeleteLoading(true)

    axios
      .delete(`/users/${deletedMember.id}`)
      .then((response) => {
        if (response.success) {
          setIsAddComplete(true)
          handleCloseDeleteModal()
          toastService.success(response.message)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setIsDeleteLoading(false))
  }

  return (
    <div className="my-profile-dis settings-tabs">
      <div className="top-title-bar">
        <div className="title-sec">
          <h2>Users</h2>
        </div>
        <div className="btn-sec">
          <button className="btn-style" type="submit" onClick={handleOpen}>
            + Add user
          </button>
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <div className="table-scroll-mobile payroll-table add-member-table">
          <SettingsMembersTable
            memberList={membersList}
            handleEdit={handleEdit}
            handleDeleteMember={handleDeleteMember}
          />
        </div>
      )}

      {(open || !!editedMember) && (
        <AddMemberModal
          open={open || !!editedMember}
          handleClose={handleClose}
          setIsAddComplete={setIsAddComplete}
          editedMember={editedMember}
        />
      )}

      <ConfirmDeleteModal
        open={!!deletedMember}
        handleClose={handleCloseDeleteModal}
        handleConfirm={handleDeleteConfirm}
        isLoading={isDeleteLoading}
        text="Are you sure delete this User?"
      />
    </div>
  )
}

export default SettingsMembers

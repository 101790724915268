import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@mui/material"
import { useEffect, useState } from "react"
import { ExpandMore } from "@mui/icons-material"

import DeleteIcon from "../../assets/images/run-payroll/delete-filled.svg"
import InputField from "../common/InputField"
import CustomCheckbox from "../common/CustomCheckbox"
import DarkTooltip from "./../common/DarkToolTip"

const EmployeeFormPayRates = ({
  is_overtime_enabled,
  add_stop_bonus,
  add_package_bonus,
  add_per_package,
  add_per_stop,
  add_ils_addition_bonus,
  add_ils_deduction_bonus,
  add_all_status_code_package_deduction_bonus,
  add_dna_deduction_bonus,
  add_miss_pickup_window_deduction_bonus,
  add_early_late_pickup_deduction_bonus,
  add_code_85_deduction_bonus,
  all_status_code_package_deduction_bonus,
  dna_deduction_bonus,
  miss_pickup_window_deduction_bonus,
  early_late_pickup_deduction_bonus,
  ils_addition_bonus,
  ils_deduction_bonus,
  register,
  touchedFields,
  errors,
  adding_daily_pay,
  setValue,
  code_85_deduction_bonus,
  stop_bonuses,
  package_bonuses,
  deleted_stop_bonuses,
  deleted_package_bonuses,
  deleted_code_85_deduction_bonus,
  is_work_area_enabled,
  is_cap_reduction_bonus_enabled,
  isActive,
  weekly_rate_w2,
  customErrors,
  setCustomErrors
}) => {
  const [stopBonusesArray, setStopBonusesArray] = useState(
    stop_bonuses?.length > 0 ? stop_bonuses : [{ id: 0, per_item: "stop" }]
  )
  const [packageBonusesArray, setPackageBonusesArray] = useState(
    package_bonuses?.length > 0 ? package_bonuses : [{ id: 0, per_item: "package" }]
  )
  const [expandedSecondPanel, setExpandedSecondPanel] = useState(false)
  const [expandedThirdPanel, setExpandedThirdPanel] = useState(false)

  const handleExpandedSecondPanelChange = () => {
    localStorage.setItem("employeeModalSecondAccordionState", !expandedSecondPanel ? 1 : 0)
    setExpandedSecondPanel(!expandedSecondPanel)
  }
  const handleExpandedThirdPanelChange = () => {
    localStorage.setItem("employeeModalThirdAccordionState", !expandedThirdPanel ? 1 : 0)
    setExpandedThirdPanel(!expandedThirdPanel)
  }

  const handleDailyRateChange = () => {
    if (!isActive || !weekly_rate_w2) {
      return
    }

    setValue("subtracting_daily_pay", adding_daily_pay === 1 ? 1 : 0, {
      shouldValidate: true,
      shouldDirty: true
    })

    setValue("adding_daily_pay", adding_daily_pay === 1 ? 0 : 1, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const handleWorkAreaChange = () => {
    if (!isActive) {
      return
    }

    setValue("is_work_area_enabled", is_work_area_enabled === 1 ? 0 : 1, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const handleCapReductionBonusChange = () => {
    if (!isActive) {
      return
    }

    setValue("is_cap_reduction_bonus_enabled", is_cap_reduction_bonus_enabled === 1 ? 0 : 1, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const handleIsOvertimeChange = () => {
    if (!isActive) {
      return
    }

    setValue("is_overtime_enabled", is_overtime_enabled === 0 ? 1 : 0, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const handlePerStopChange = () => {
    if (!isActive) {
      return
    }

    setValue("add_per_stop", add_per_stop === 0 ? 1 : 0, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const handleIlsAdditionBonusChange = () => {
    if (!isActive) {
      return
    }

    setValue("add_ils_addition_bonus", add_ils_addition_bonus === 0 ? 1 : 0, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const handleIlsDeductionBonusChange = () => {
    if (!isActive) {
      return
    }

    setValue("add_ils_deduction_bonus", add_ils_deduction_bonus === 0 ? 1 : 0, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const handleAllStatusCodeChange = () => {
    if (!isActive) {
      return
    }

    setValue(
      "add_all_status_code_package_deduction_bonus",
      add_all_status_code_package_deduction_bonus === 0 ? 1 : 0,
      {
        shouldValidate: true,
        shouldDirty: true
      }
    )
  }

  const handleDnaDeductionBonusChange = () => {
    if (!isActive) {
      return
    }

    setValue("add_dna_deduction_bonus", add_dna_deduction_bonus === 0 ? 1 : 0, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const handleMissPickupWindowDeductionBonusChange = () => {
    if (!isActive) {
      return
    }

    setValue(
      "add_miss_pickup_window_deduction_bonus",
      add_miss_pickup_window_deduction_bonus === 0 ? 1 : 0,
      {
        shouldValidate: true,
        shouldDirty: true
      }
    )
  }

  const handleEarlyLatePickupWindowDeductionBonusChange = () => {
    if (!isActive) {
      return
    }

    setValue(
      "add_early_late_pickup_deduction_bonus",
      add_early_late_pickup_deduction_bonus === 0 ? 1 : 0,
      {
        shouldValidate: true,
        shouldDirty: true
      }
    )
  }

  const handlePerPackageChange = () => {
    if (!isActive) {
      return
    }

    setValue("add_per_package", add_per_package === 0 ? 1 : 0, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const handlePackageBonusChange = () => {
    if (!isActive) {
      return
    }

    setPackageBonusesArray(
      package_bonuses?.length > 1 ? package_bonuses : [{ id: 0, per_item: "package" }]
    )
    setValue("package_bonuses", package_bonuses, {
      shouldValidate: true,
      shouldDirty: true
    })
    setValue("add_package_bonus", add_package_bonus === 0 ? 1 : 0, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const handleStopBonusChange = () => {
    if (!isActive) {
      return
    }

    setStopBonusesArray(stop_bonuses?.length > 1 ? stop_bonuses : [{ id: 0, per_item: "stop" }])
    setValue("stop_bonuses", stop_bonuses, {
      shouldValidate: true,
      shouldDirty: true
    })
    setValue("add_stop_bonus", add_stop_bonus === 0 ? 1 : 0, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const handleCode85BonusChange = () => {
    if (!isActive) {
      return
    }

    setValue(
      "code_85_deduction_bonus",
      code_85_deduction_bonus?.length > 1
        ? code_85_deduction_bonus
        : [
            {
              id: 0,
              action_type: "deduction",
              bonus_type: "threshold",
              field: "code_85",
              price_type: "amount"
            }
          ],
      {
        shouldValidate: true,
        shouldDirty: true
      }
    )
    setValue("add_code_85_deduction_bonus", add_code_85_deduction_bonus === 0 ? 1 : 0, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const handlePackageAddMore = () => {
    if (!isActive) {
      return
    }

    setPackageBonusesArray([
      ...packageBonusesArray,
      {
        price: null,
        threshold: null,
        id: packageBonusesArray.length + 1,
        per_item: "package"
      }
    ])
  }

  const handleCode85AddMore = () => {
    if (!isActive) {
      return
    }

    setValue(
      "code_85_deduction_bonus",
      [
        ...code_85_deduction_bonus,
        {
          id: 0,
          action_type: "deduction",
          bonus_type: "threshold",
          field: "code_85",
          price_type: "amount"
        }
      ],
      {
        shouldValidate: true,
        shouldDirty: true
      }
    )
  }

  const handleStopAddMore = () => {
    if (!isActive) {
      return
    }

    setStopBonusesArray([
      ...stopBonusesArray,
      {
        price: null,
        threshold: null,
        id: stopBonusesArray.length + 1,
        per_item: "stop"
      }
    ])
  }

  const handleNumberChange = (inputName, e) => {
    const value = e.target?.value
      .replace(/[^\d.]/g, "")
      .replace(/\.([.\d]+)$/, function (m, m1) {
        return "." + m1.replace(/\./g, "")
      })
      .replace(/^0*([^0]\d*\.\d{1,2}).*/g, "$1")
    setValue(inputName, value, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const handleStopDeleteBonus = (index, e) => {
    e.preventDefault()
    const item = stop_bonuses.find((el, indexN) => indexN === index)
    const newBonusesArr = [...stopBonusesArray]
    newBonusesArr.splice(index, 1)
    setStopBonusesArray(newBonusesArr)
    setValue(
      "stop_bonuses",
      stop_bonuses.filter((el, indexN) => indexN !== index),
      {
        shouldValidate: true,
        shouldDirty: true
      }
    )

    if (item.id > 0) {
      setValue("deleted_stop_bonuses", [...deleted_stop_bonuses, item.id])
    }
  }

  const handleCode85DeleteBonus = (index, e) => {
    e.preventDefault()
    const item = code_85_deduction_bonus.find((el, indexN) => indexN === index)

    setValue(
      "code_85_deduction_bonus",
      code_85_deduction_bonus.filter((el, indexN) => indexN !== index),
      {
        shouldValidate: true,
        shouldDirty: true
      }
    )

    if (item?.id > 0) {
      setValue("deleted_code_85_deduction_bonus", [...deleted_code_85_deduction_bonus, item.id])
    }
  }

  const handlePackageDeleteBonus = (index, e) => {
    e.preventDefault()
    const item = package_bonuses.find((el, indexN) => indexN === index)
    const newBonusesArr = [...packageBonusesArray]
    newBonusesArr.splice(index, 1)
    setPackageBonusesArray(newBonusesArr)
    setValue(
      "package_bonuses",
      package_bonuses.filter((el, indexN) => indexN !== index),
      {
        shouldValidate: true,
        shouldDirty: true
      }
    )

    if (item.id > 0) {
      setValue("deleted_package_bonuses", [...deleted_package_bonuses, item.id])
    }
  }

  const handlePackageThresholdChange = (index, e) => {
    const value = e.target?.value.replace(/[^\d.]/g, "").replace(/\.([.\d]+)$/, function (m, m1) {
      return "." + m1.replace(/\./g, "")
    })

    if (package_bonuses?.length > 1) {
      const arrayOfMatches = package_bonuses
        .map((el) => {
          if (
            el.threshold &&
            +el.threshold > 0 &&
            +e.target?.value > 0 &&
            +el.threshold === +e.target?.value
          ) {
            return true
          }
          return false
        })
        .filter((el) => el === true)

      if (arrayOfMatches.length > 0) {
        const errorsData = {
          ...errors,
          [`package_bonuses_${index}_threshold`]: "Daily threshold must be unique"
        }
        setCustomErrors(errorsData)
      } else {
        const newErrors = { ...errors }
        delete newErrors[`package_bonuses_${index}_threshold`]
        setCustomErrors(newErrors)
      }
    }

    setValue(`package_bonuses[${index}][threshold]`, value, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const handleCode85ThresholdChange = (index, e) => {
    const value = e.target?.value.replace(/[^\d.]/g, "").replace(/\.([.\d]+)$/, function (m, m1) {
      return "." + m1.replace(/\./g, "")
    })

    if (code_85_deduction_bonus?.length > 1) {
      const arrayOfMatches = code_85_deduction_bonus
        .map((el) => {
          if (
            el.field_value &&
            (+el.field_value > 0 || +el.field_value === 0) &&
            (+e.target?.value > 0 || +e.target?.value === 0) &&
            +el.field_value === +e.target?.value
          ) {
            return true
          }
          return false
        })
        .filter((el) => el === true)

      if (arrayOfMatches.length > 0) {
        const errorsData = {
          ...errors,
          [`code_85_deduction_bonus_${index}_field_value`]: "Code 85 threshold must be unique"
        }
        setCustomErrors(errorsData)
      } else {
        const newErrors = { ...errors }
        delete newErrors[`code_85_deduction_bonus_${index}_field_value`]
        setCustomErrors(newErrors)
      }
    }

    setValue(`code_85_deduction_bonus[${index}][field_value]`, value, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const handleStopThresholdChange = (index, e) => {
    const value = e.target?.value.replace(/[^\d.]/g, "").replace(/\.([.\d]+)$/, function (m, m1) {
      return "." + m1.replace(/\./g, "")
    })

    if (stop_bonuses?.length > 1) {
      const arrayOfMatches = stop_bonuses
        .map((el) => {
          if (
            el.threshold &&
            +el.threshold > 0 &&
            +e.target?.value > 0 &&
            +el.threshold === +e.target?.value
          ) {
            return true
          }
          return false
        })
        .filter((el) => el === true)

      if (arrayOfMatches.length > 0) {
        const errorsData = {
          ...errors,
          [`stop_bonuses_${index}_threshold`]: "Daily threshold must be unique"
        }
        setCustomErrors(errorsData)
      } else {
        const newErrors = { ...errors }
        delete newErrors[`stop_bonuses_${index}_threshold`]
        setCustomErrors(newErrors)
      }
    }

    setValue(`stop_bonuses[${index}][threshold]`, value, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  useEffect(() => {
    if (!code_85_deduction_bonus.length) {
      setValue(
        "code_85_deduction_bonus",
        [
          {
            id: 0,
            action_type: "deduction",
            bonus_type: "threshold",
            field: "code_85",
            price_type: "amount"
          }
        ],
        {
          shouldValidate: true,
          shouldDirty: true
        }
      )
    }

    const employeeModalSecondAccordionState = localStorage.getItem(
      "employeeModalSecondAccordionState"
    )
    const employeeModalThirdAccordionState = localStorage.getItem(
      "employeeModalThirdAccordionState"
    )

    employeeModalSecondAccordionState &&
      setExpandedSecondPanel(Boolean(+employeeModalSecondAccordionState))
    employeeModalThirdAccordionState &&
      setExpandedThirdPanel(Boolean(+employeeModalThirdAccordionState))
  }, [])

  useEffect(() => {
    if (!weekly_rate_w2 && !adding_daily_pay) {
      setValue("subtracting_daily_pay", 0, {
        shouldValidate: true,
        shouldDirty: true
      })

      setValue("adding_daily_pay", 1, {
        shouldValidate: true,
        shouldDirty: true
      })
    }
  }, [weekly_rate_w2])

  return (
    <div className="popup-dis-sec">
      <InputField
        title={
          <DarkTooltip
            title="Set a minimum weekly pay rate, regardless of days worked"
            enterNextDelay={500}
            enterDelay={500}
            placement="top"
            arrow
          >
            Weekly Rate
          </DarkTooltip>
        }
        placeholder=" "
        name="weekly_rate_w2"
        errorMessage={errors.weekly_rate_w2?.message}
        isError={errors.weekly_rate_w2?.message}
        isTouched={touchedFields.weekly_rate_w2}
        otherProps={register("weekly_rate_w2")}
        onChange={handleNumberChange.bind(null, "weekly_rate_w2")}
        onFocus={(event) => event.target.select()}
        fullWidth
        spanText="$"
      />

      <CustomCheckbox
        checked={!adding_daily_pay}
        disabled={!weekly_rate_w2}
        onChange={handleDailyRateChange}
        label={
          <DarkTooltip
            title="Set a maximum limit on the employee’s total earnings per week"
            enterNextDelay={500}
            enterDelay={500}
            placement="top"
            arrow
          >
            <div className="custom-checkbox-with-info">Weekly Rate Cap</div>
          </DarkTooltip>
        }
        small
      />

      <div className="form-items-container">
        <InputField
          title="Paid Time Off (PTO)"
          placeholder=" "
          name="paid_time_off"
          errorMessage={errors.paid_time_off?.message}
          isError={errors.paid_time_off?.message}
          isTouched={touchedFields.paid_time_off}
          otherProps={register("paid_time_off")}
          onChange={handleNumberChange.bind(null, "paid_time_off")}
          onFocus={(event) => event.target.select()}
          disabled={!isActive}
          fullWidth
          spanText="$"
          tooltipText="Specify the pay rate for hours or days taken as PTO"
        />
        <InputField
          title="Training Rate"
          placeholder=" "
          name="training_rate"
          errorMessage={errors.training_rate?.message}
          isError={errors.training_rate?.message}
          isTouched={touchedFields.training_rate}
          otherProps={register("training_rate")}
          onChange={handleNumberChange.bind(null, "training_rate")}
          onFocus={(event) => event.target.select()}
          disabled={!isActive}
          fullWidth
          spanText="$"
          tooltipText="Set the rate paid to the employee during training sessions"
        />
      </div>

      <CustomCheckbox
        checked={Boolean(is_work_area_enabled)}
        onChange={handleWorkAreaChange}
        label="Add Work Area Rate (Advanced User)"
        className="mt-4"
      />
      <span className="employee-more-info">
        Enable different pay rates based on specific work areas assigned to the employee
      </span>

      {is_work_area_enabled === 0 && (
        <>
          <Accordion className="employee-modal-accordion" defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>Additional Pay Options</AccordionSummary>
            <AccordionDetails>
              <div className="form-items-container mb-3">
                <InputField
                  title="Daily Rate"
                  placeholder=" "
                  name="daily_pay"
                  errorMessage={errors.daily_pay?.message}
                  isError={errors.daily_pay?.message}
                  isTouched={touchedFields.daily_pay}
                  otherProps={register("daily_pay")}
                  onChange={handleNumberChange.bind(null, "daily_pay")}
                  onFocus={(event) => event.target.select()}
                  disabled={!isActive}
                  fullWidth
                  spanText="$"
                  tooltipText="Set a flat daily pay amount, regardless of hours or stops"
                />
                <div className="employee-form-half-item-container">
                  <InputField
                    title="Hourly Rate"
                    placeholder=" "
                    name="hourly_rate"
                    errorMessage={errors.hourly_rate?.message}
                    isError={errors.hourly_rate?.message}
                    isTouched={touchedFields.hourly_rate}
                    otherProps={register("hourly_rate")}
                    onChange={handleNumberChange.bind(null, "hourly_rate")}
                    onFocus={(event) => event.target.select()}
                    disabled={!isActive}
                    fullWidth
                    spanText="$"
                    tooltipText="Enter the standard hourly pay rate"
                  />
                  <CustomCheckbox
                    checked={is_overtime_enabled}
                    onChange={handleIsOvertimeChange}
                    label={
                      <DarkTooltip
                        title="Enable this to pay overtime 1.5x after 40 weekly hour limit is reached"
                        enterNextDelay={500}
                        enterDelay={500}
                        placement="top"
                        arrow
                      >
                        Include Weekly Overtime
                      </DarkTooltip>
                    }
                    small
                  />
                </div>
              </div>

              <div className="add-bonus-sec">
                <div className="option-toggle add-bonus-toggle" onClick={handlePerStopChange}>
                  <span className={`toggle-btn ${add_per_stop ? "active" : ""}`}></span>
                  <span>Add Per Stop </span>
                </div>
                <span className="employee-more-info mt-0">
                  Pay an additional rate for each completed stop
                </span>

                {Boolean(add_per_stop) && (
                  <div className="add-bonus-field">
                    <div className="three-column">
                      <InputField
                        title="Pickup"
                        placeholder="0.00"
                        name="stop_pickup_rate"
                        errorMessage={errors.stop_pickup_rate?.message}
                        isError={errors.stop_pickup_rate?.message}
                        isTouched={touchedFields.stop_pickup_rate}
                        otherProps={register("stop_pickup_rate")}
                        onChange={handleNumberChange.bind(null, "stop_pickup_rate")}
                        onFocus={(event) => event.target.select()}
                        disabled={!isActive}
                        fullWidth
                        spanText="$"
                      />
                      <InputField
                        title="Delivery"
                        placeholder="0.00"
                        name="stop_delivery_rate"
                        errorMessage={errors.stop_delivery_rate?.message}
                        isError={errors.stop_delivery_rate?.message}
                        isTouched={touchedFields.stop_delivery_rate}
                        otherProps={register("stop_delivery_rate")}
                        onChange={handleNumberChange.bind(null, "stop_delivery_rate")}
                        onFocus={(event) => event.target.select()}
                        disabled={!isActive}
                        fullWidth
                        spanText="$"
                      />
                      <InputField
                        title="E-commerce"
                        placeholder="0.00"
                        name="stop_ecommerce_rate"
                        errorMessage={errors.stop_ecommerce_rate?.message}
                        isError={errors.stop_ecommerce_rate?.message}
                        isTouched={touchedFields.stop_ecommerce_rate}
                        otherProps={register("stop_ecommerce_rate")}
                        onChange={handleNumberChange.bind(null, "stop_ecommerce_rate")}
                        onFocus={(event) => event.target.select()}
                        disabled={!isActive}
                        fullWidth
                        spanText="$"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="add-bonus-sec mb-0">
                <div className="option-toggle add-bonus-toggle" onClick={handlePerPackageChange}>
                  <span className={`toggle-btn ${add_per_package ? "active" : ""}`}></span>
                  <span>Add Per Package </span>
                </div>
                <span className="employee-more-info mt-0">
                  Pay an additional rate for each delivered package
                </span>

                {Boolean(add_per_package) && (
                  <div className="add-bonus-field">
                    <div className="three-column">
                      <InputField
                        title="Pickup"
                        placeholder="0.00"
                        name="package_pickup_rate"
                        errorMessage={errors.package_pickup_rate?.message}
                        isError={errors.package_pickup_rate?.message}
                        isTouched={touchedFields.package_pickup_rate}
                        otherProps={register("package_pickup_rate")}
                        onChange={handleNumberChange.bind(null, "package_pickup_rate")}
                        onFocus={(event) => event.target.select()}
                        disabled={!isActive}
                        fullWidth
                        spanText="$"
                      />
                      <InputField
                        title="Delivery"
                        placeholder="0.00"
                        name="package_delivery_rate"
                        errorMessage={errors.package_delivery_rate?.message}
                        isError={errors.package_delivery_rate?.message}
                        isTouched={touchedFields.package_delivery_rate}
                        otherProps={register("package_delivery_rate")}
                        onChange={handleNumberChange.bind(null, "package_delivery_rate")}
                        onFocus={(event) => event.target.select()}
                        disabled={!isActive}
                        fullWidth
                        spanText="$"
                      />
                      <InputField
                        title="E-commerce"
                        placeholder="0.00"
                        name="package_ecommerce_rate"
                        errorMessage={errors.package_ecommerce_rate?.message}
                        isError={errors.package_ecommerce_rate?.message}
                        isTouched={touchedFields.package_ecommerce_rate}
                        otherProps={register("package_ecommerce_rate")}
                        onChange={handleNumberChange.bind(null, "package_ecommerce_rate")}
                        onFocus={(event) => event.target.select()}
                        disabled={!isActive}
                        fullWidth
                        spanText="$"
                      />
                    </div>
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="employee-modal-accordion"
            onChange={handleExpandedSecondPanelChange}
            expanded={expandedSecondPanel}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>Incentives</AccordionSummary>
            <AccordionDetails>
              <div className="form-items-container mb-3">
                <InputField
                  title="6th Day Bonus"
                  placeholder=" "
                  name="six_day_bonus"
                  errorMessage={errors.six_day_bonus?.message}
                  isError={errors.six_day_bonus?.message}
                  isTouched={touchedFields.six_day_bonus}
                  otherProps={register("six_day_bonus")}
                  onChange={handleNumberChange.bind(null, "six_day_bonus")}
                  onFocus={(event) => event.target.select()}
                  disabled={!isActive}
                  fullWidth
                  spanText="$"
                  tooltipText="Add a bonus amount for employees working a sixth day in a given week"
                />
                <InputField
                  title="Sunday Bonus"
                  placeholder=" "
                  name="sunday_bonus"
                  errorMessage={errors.sunday_bonus?.message}
                  isError={errors.sunday_bonus?.message}
                  isTouched={touchedFields.sunday_bonus}
                  otherProps={register("sunday_bonus")}
                  onChange={handleNumberChange.bind(null, "sunday_bonus")}
                  onFocus={(event) => event.target.select()}
                  disabled={!isActive}
                  fullWidth
                  spanText="$"
                  tooltipText="Add a bonus amount for work performed on Sundays"
                />
              </div>

              <div className="add-bonus-sec">
                <div className="option-toggle add-bonus-toggle" onClick={handleStopBonusChange}>
                  <span className={`toggle-btn ${add_stop_bonus ? "active" : ""}`}></span>
                  <span>Add Stop Threshold</span>
                </div>
                <span className="employee-more-info mt-0">
                  Enable a bonus once a certain number of stops have been completed
                </span>

                {Boolean(add_stop_bonus) && (
                  <div className="add-bonus-field bonus">
                    {stopBonusesArray.map((el, index) => (
                      <div className="form-item-container mb-2" key={index}>
                        <InputField
                          title="Stop Threshold"
                          placeholder="100"
                          name={`stop_bonuses[${index}][threshold]`}
                          errorMessage={customErrors[`stop_bonuses_${index}_threshold`]}
                          isError={!!customErrors[`stop_bonuses_${index}_threshold`]}
                          isTouched={touchedFields.stop_bonuses?.[index]?.threshold}
                          otherProps={register(`stop_bonuses[${index}][threshold]`)}
                          onChange={handleStopThresholdChange.bind(null, index)}
                          disabled={!isActive}
                          fullWidth
                        />

                        <InputField
                          title="Per Stop"
                          placeholder="1.00"
                          name={`stop_bonuses[${index}][price]`}
                          errorMessage={errors.price?.message}
                          isError={errors.price?.message}
                          isTouched={touchedFields.price}
                          otherProps={register(`stop_bonuses[${index}][price]`)}
                          onChange={handleNumberChange.bind(null, `stop_bonuses[${index}][price]`)}
                          onFocus={(event) => event.target.select()}
                          disabled={!isActive}
                          fullWidth
                          spanText="$"
                        />

                        <button
                          disabled={!isActive}
                          className="document-more-btn menu-item mb-1"
                          onClick={handleStopDeleteBonus.bind(null, index)}
                        >
                          <img src={DeleteIcon} alt="" />
                        </button>
                      </div>
                    ))}

                    {stopBonusesArray.length < 3 && (
                      <div className="bonus-add-more" onClick={handleStopAddMore}>
                        <span>+</span> Add more
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="add-bonus-sec">
                <div className="option-toggle add-bonus-toggle" onClick={handlePackageBonusChange}>
                  <span className={`toggle-btn ${add_package_bonus ? "active" : ""}`}></span>
                  <span>Add Package Threshold</span>
                </div>
                <span className="employee-more-info mt-0">
                  Enable a bonus once a certain number of packages have been delivered
                </span>

                {Boolean(add_package_bonus) && (
                  <div className="add-bonus-field bonus">
                    {packageBonusesArray.map((el, index) => (
                      <div className="form-item-container mb-2" key={index}>
                        <InputField
                          title="Package Threshold"
                          placeholder="100"
                          name={`package_bonuses[${index}][threshold]`}
                          errorMessage={customErrors[`package_bonuses_${index}_threshold`]}
                          isError={!!customErrors[`package_bonuses_${index}_threshold`]}
                          isTouched={touchedFields.package_bonuses?.[index]?.threshold}
                          otherProps={register(`package_bonuses[${index}][threshold]`)}
                          onChange={handlePackageThresholdChange.bind(null, index)}
                          disabled={!isActive}
                          fullWidth
                        />
                        <InputField
                          title="Per Package"
                          placeholder="1.00"
                          name={`package_bonuses[${index}][price]`}
                          errorMessage={errors.price?.message}
                          isError={errors.price?.message}
                          isTouched={touchedFields.price}
                          otherProps={register(`package_bonuses[${index}][price]`)}
                          onChange={handleNumberChange.bind(
                            null,
                            `package_bonuses[${index}][price]`
                          )}
                          onFocus={(event) => event.target.select()}
                          disabled={!isActive}
                          fullWidth
                          spanText="$"
                        />
                        <button
                          disabled={!isActive}
                          className="document-more-btn menu-item mb-1"
                          onClick={handlePackageDeleteBonus.bind(null, index)}
                        >
                          <img src={DeleteIcon} alt="" />
                        </button>
                      </div>
                    ))}

                    {packageBonusesArray.length < 3 && (
                      <div className="bonus-add-more" onClick={handlePackageAddMore}>
                        <span>+</span> Add more
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="add-bonus-sec mb-0">
                <div
                  className="option-toggle add-bonus-toggle"
                  onClick={handleIlsAdditionBonusChange}
                >
                  <span className={`toggle-btn ${add_ils_addition_bonus ? "active" : ""}`}></span>
                  <span>Add Inbound Local Scan</span>
                </div>
                <span className="employee-more-info mt-0">
                  Increase pay for successfully completed inbound scans
                </span>

                {Boolean(add_ils_addition_bonus) && (
                  <div className="add-bonus-field">
                    <div className="three-column" style={{ alignItems: "end" }}>
                      <InputField
                        title="ILS%"
                        placeholder="100"
                        name="ils_addition_bonus[field_value]"
                        errorMessage={errors.ils_addition_bonus?.field_value?.message}
                        isError={!!errors.ils_addition_bonus?.field_value?.message}
                        isTouched={touchedFields.ils_addition_bonus?.field_value}
                        otherProps={register("ils_addition_bonus[field_value]")}
                        onChange={handleNumberChange.bind(null, "ils_addition_bonus[field_value]")}
                        onFocus={(event) => event.target.select()}
                        disabled={!isActive}
                        fullWidth
                        percentage
                        spanText="%"
                      />

                      <div className="form-item radio">
                        <FormControl
                          sx={{ width: "100%" }}
                          name="ils_addition_bonus[price_type]"
                          {...register("ils_addition_bonus[price_type]")}
                          onChange={(e) => {
                            setValue("ils_addition_bonus[price_value]", undefined)
                            setValue("ils_addition_bonus[price_type]", e.target.value)
                          }}
                        >
                          <RadioGroup
                            disabled={!isActive}
                            value={ils_addition_bonus?.price_type}
                            defaultValue="amount"
                            row
                          >
                            <FormControlLabel
                              value="amount"
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    color: "#EEF0F2",
                                    "&.Mui-checked": {
                                      color: "#4253F0"
                                    }
                                  }}
                                />
                              }
                              label="Amount"
                              sx={{
                                width: "50%",
                                margin: "0 !important",
                                "& span:first-child": { padding: 0, marginBottom: "5px" },
                                "& span:last-child": { marginLeft: "4px" }
                              }}
                            />

                            <FormControlLabel
                              value="percentage"
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    color: "#EEF0F2",
                                    "&.Mui-checked": {
                                      color: "#4253F0"
                                    }
                                  }}
                                />
                              }
                              label="Percentage"
                              sx={{
                                width: "50%",
                                margin: "0 !important",
                                "& span:first-child": { padding: 0, marginBottom: "5px" },
                                "& span:last-child": { marginLeft: "4px" }
                              }}
                            />
                          </RadioGroup>
                        </FormControl>
                        <div style={{ width: "100%", display: "flex", gap: "16px" }}>
                          <InputField
                            title="Amount Increase"
                            placeholder="100"
                            name="ils_addition_bonus[price_value]"
                            errorMessage={errors.ils_addition_bonus?.price_value?.message}
                            isError={!!errors.ils_addition_bonus?.price_value?.message}
                            isTouched={touchedFields.ils_addition_bonus?.price_value}
                            onChange={handleNumberChange.bind(
                              null,
                              "ils_addition_bonus[price_value]"
                            )}
                            otherProps={
                              ils_addition_bonus?.price_type !== "percentage"
                                ? register("ils_addition_bonus[price_value]")
                                : {}
                            }
                            onFocus={(event) => event.target.select()}
                            disabled={!isActive || ils_addition_bonus?.price_type === "percentage"}
                            fullWidth
                            spanText="$"
                            sx={{ "& input:disabled": { background: "#EEF0F2" } }}
                            autoWidth
                          />

                          <InputField
                            title="Percentage Increase"
                            placeholder="0.00"
                            name="ils_addition_bonus[price_value]"
                            errorMessage={errors.ils_addition_bonus?.price_value?.message}
                            isError={!!errors.ils_addition_bonus?.price_value?.message}
                            isTouched={touchedFields.ils_addition_bonus?.price_value}
                            otherProps={
                              ils_addition_bonus?.price_type !== "amount"
                                ? register("ils_addition_bonus[price_value]")
                                : {}
                            }
                            onChange={handleNumberChange.bind(
                              null,
                              "ils_addition_bonus[price_value]"
                            )}
                            onFocus={(event) => event.target.select()}
                            disabled={!isActive || ils_addition_bonus?.price_type === "amount"}
                            fullWidth
                            percentage
                            spanText="%"
                            sx={{
                              "& input": {
                                paddingLeft: "18px !important",
                                "&:disabled": { background: "#EEF0F2" }
                              }
                            }}
                            autoWidth
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="employee-modal-accordion"
            onChange={handleExpandedThirdPanelChange}
            expanded={expandedThirdPanel}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>Reduction</AccordionSummary>
            <AccordionDetails>
              <CustomCheckbox
                checked={Boolean(is_cap_reduction_bonus_enabled)}
                onChange={handleCapReductionBonusChange}
                label="Cap Reduction to Incentives"
                className="mt-3"
              />
              <span className="employee-more-info">Ensure reductions do not exceed incentives</span>

              <div className="add-bonus-sec mt-3">
                <div
                  className="option-toggle add-bonus-toggle"
                  onClick={handleIlsDeductionBonusChange}
                >
                  <span className={`toggle-btn ${add_ils_deduction_bonus ? "active" : ""}`}></span>
                  <span>Add Inbound Local Scan</span>
                </div>
                <span className="employee-more-info mt-0">
                  Reduce pay for any missed inbound scans
                </span>

                {Boolean(add_ils_deduction_bonus) && (
                  <div className="add-bonus-field">
                    <div className="three-column" style={{ alignItems: "end" }}>
                      <InputField
                        title="ILS%"
                        placeholder="96"
                        name="ils_deduction_bonus[field_value]"
                        errorMessage={errors.ils_deduction_bonus?.field_value?.message}
                        isError={!!errors.ils_deduction_bonus?.field_value?.message}
                        isTouched={touchedFields.ils_deduction_bonus?.field_value}
                        otherProps={register("ils_deduction_bonus[field_value]")}
                        onChange={handleNumberChange.bind(null, "ils_deduction_bonus[field_value]")}
                        onFocus={(event) => event.target.select()}
                        disabled={!isActive}
                        fullWidth
                        percentage
                        spanText="%"
                      />
                      <div className="form-item radio">
                        <FormControl
                          sx={{ width: "100%" }}
                          name="ils_deduction_bonus[price_type]"
                          {...register("ils_deduction_bonus[price_type]")}
                          onChange={(e) => {
                            setValue("ils_deduction_bonus[price_value]", undefined)
                            setValue("ils_deduction_bonus[price_type]", e.target.value)
                          }}
                        >
                          <RadioGroup
                            disabled={!isActive}
                            value={ils_deduction_bonus?.price_type}
                            defaultValue="amount"
                            row
                          >
                            <FormControlLabel
                              value="amount"
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    color: "#EEF0F2",
                                    "&.Mui-checked": {
                                      color: "#4253F0"
                                    }
                                  }}
                                />
                              }
                              label="Amount"
                              sx={{
                                width: "50%",
                                margin: "0 !important",
                                "& span:first-child": { padding: 0, marginBottom: "5px" },
                                "& span:last-child": { marginLeft: "4px" }
                              }}
                            />

                            <FormControlLabel
                              value="percentage"
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    color: "#EEF0F2",
                                    "&.Mui-checked": {
                                      color: "#4253F0"
                                    }
                                  }}
                                />
                              }
                              label="Percentage"
                              sx={{
                                width: "50%",
                                margin: "0 !important",
                                "& span:first-child": { padding: 0, marginBottom: "5px" },
                                "& span:last-child": { marginLeft: "4px" }
                              }}
                            />
                          </RadioGroup>
                        </FormControl>
                        <div style={{ width: "100%", display: "flex", gap: "16px" }}>
                          <InputField
                            title="Amount Reduction"
                            placeholder="0.00"
                            name="ils_deduction_bonus[price_value]"
                            errorMessage={errors.ils_deduction_bonus?.price_value?.message}
                            isError={!!errors.ils_deduction_bonus?.price_value?.message}
                            isTouched={touchedFields.ils_deduction_bonus?.price_value}
                            onChange={handleNumberChange.bind(
                              null,
                              "ils_deduction_bonus[price_value]"
                            )}
                            otherProps={
                              ils_deduction_bonus?.price_type !== "percentage"
                                ? register("ils_deduction_bonus[price_value]")
                                : {}
                            }
                            onFocus={(event) => event.target.select()}
                            disabled={!isActive || ils_deduction_bonus?.price_type === "percentage"}
                            fullWidth
                            spanText="$"
                            sx={{ "& input:disabled": { background: "#EEF0F2" } }}
                            autoWidth
                          />

                          <InputField
                            title="Percentage Reduction"
                            placeholder="0.00"
                            name="ils_deduction_bonus[price_value]"
                            errorMessage={errors.ils_deduction_bonus?.price_value?.message}
                            isError={!!errors.ils_deduction_bonus?.price_value?.message}
                            isTouched={touchedFields.ils_deduction_bonus?.price_value}
                            otherProps={
                              ils_deduction_bonus?.price_type !== "amount"
                                ? register("ils_deduction_bonus[price_value]")
                                : {}
                            }
                            onChange={handleNumberChange.bind(
                              null,
                              "ils_deduction_bonus[price_value]"
                            )}
                            onFocus={(event) => event.target.select()}
                            disabled={!isActive || ils_deduction_bonus?.price_type === "amount"}
                            fullWidth
                            percentage
                            spanText="%"
                            sx={{
                              "& input": {
                                paddingLeft: "18px !important",
                                "&:disabled": { background: "#EEF0F2" }
                              }
                            }}
                            autoWidth
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="add-bonus-sec">
                <div className="option-toggle add-bonus-toggle" onClick={handleCode85BonusChange}>
                  <span
                    className={`toggle-btn ${add_code_85_deduction_bonus ? "active" : ""}`}
                  ></span>
                  <span>Add Code 85</span>
                </div>
                <span className="employee-more-info mt-0">
                  Apply a penalty for each recorded delivery issue (Code 85)
                </span>

                {Boolean(add_code_85_deduction_bonus) && (
                  <>
                    {code_85_deduction_bonus.map((el, index) => (
                      <div className="add-bonus-field" key={index}>
                        <div className="three-column" style={{ alignItems: "end" }}>
                          <InputField
                            title="Code 85 Threshold"
                            placeholder="1"
                            name={`code_85_deduction_bonus[${index}][field_value]`}
                            errorMessage={
                              customErrors[`code_85_deduction_bonus_${index}_field_value`]
                            }
                            isError={!!customErrors[`code_85_deduction_bonus_${index}_field_value`]}
                            isTouched={touchedFields.code_85_deduction_bonus?.[index]?.field_value}
                            value={
                              code_85_deduction_bonus?.[index]?.field_value === "0.00"
                                ? 0
                                : +code_85_deduction_bonus?.[index]?.field_value || undefined
                            }
                            onChange={handleCode85ThresholdChange.bind(null, index)}
                            disabled={!isActive}
                            fullWidth
                          />
                          <div className="form-item radio with-delete">
                            <FormControl
                              sx={{ width: "100%" }}
                              name={`code_85_deduction_bonus[${index}][price_type]`}
                              {...register(`code_85_deduction_bonus[${index}][price_type]`)}
                              onChange={(e) => {
                                setValue(
                                  `code_85_deduction_bonus[${index}][price_value]`,
                                  undefined
                                )
                                setValue(
                                  `code_85_deduction_bonus[${index}][price_type]`,
                                  e.target.value
                                )
                              }}
                            >
                              <RadioGroup
                                disabled={!isActive}
                                value={code_85_deduction_bonus[index]?.price_type}
                                defaultValue="amount"
                                row
                              >
                                <FormControlLabel
                                  value="amount"
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        color: "#EEF0F2",
                                        "&.Mui-checked": {
                                          color: "#4253F0"
                                        }
                                      }}
                                    />
                                  }
                                  label="Amount"
                                  sx={{
                                    width: "50%",
                                    margin: "0 !important",
                                    "& span:first-child": { padding: 0, marginBottom: "5px" },
                                    "& span:last-child": { marginLeft: "4px" }
                                  }}
                                />

                                <FormControlLabel
                                  value="percentage"
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        color: "#EEF0F2",
                                        "&.Mui-checked": {
                                          color: "#4253F0"
                                        }
                                      }}
                                    />
                                  }
                                  label="Percentage"
                                  sx={{
                                    width: "50%",
                                    margin: "0 !important",
                                    "& span:first-child": { padding: 0, marginBottom: "5px" },
                                    "& span:last-child": { marginLeft: "4px" }
                                  }}
                                />
                              </RadioGroup>
                            </FormControl>
                            <div style={{ width: "100%", display: "flex", gap: "16px" }}>
                              <InputField
                                title="Amount Reduction"
                                placeholder="0.00"
                                name={`code_85_deduction_bonus[${index}][price_value]`}
                                errorMessage={
                                  customErrors[`code_85_deduction_bonus_${index}_price_value`]
                                }
                                isError={
                                  !!customErrors[`code_85_deduction_bonus_${index}_price_value`]
                                }
                                isTouched={
                                  touchedFields.code_85_deduction_bonus?.[index]?.price_value
                                }
                                onChange={handleNumberChange.bind(
                                  null,
                                  `code_85_deduction_bonus[${index}][price_value]`
                                )}
                                otherProps={
                                  code_85_deduction_bonus?.[index]?.price_type !== "percentage"
                                    ? register(`code_85_deduction_bonus[${index}][price_value]`)
                                    : {}
                                }
                                onFocus={(event) => event.target.select()}
                                disabled={
                                  !isActive ||
                                  code_85_deduction_bonus?.[index]?.price_type === "percentage"
                                }
                                fullWidth
                                spanText="$"
                                sx={{ "& input:disabled": { background: "#EEF0F2" } }}
                                autoWidth
                              />

                              <InputField
                                title="Percentage Reduction"
                                placeholder="0.00"
                                name={`code_85_deduction_bonus[${index}][price_value]`}
                                errorMessage={
                                  customErrors[`code_85_deduction_bonus_${index}_price_value`]
                                }
                                isError={
                                  !!customErrors[`code_85_deduction_bonus_${index}_price_value`]
                                }
                                isTouched={
                                  touchedFields.code_85_deduction_bonus?.[index]?.price_value
                                }
                                otherProps={
                                  code_85_deduction_bonus?.[index]?.price_type !== "amount"
                                    ? register(`code_85_deduction_bonus[${index}][price_value]`)
                                    : {}
                                }
                                onChange={handleNumberChange.bind(
                                  null,
                                  `code_85_deduction_bonus[${index}][price_value]`
                                )}
                                onFocus={(event) => event.target.select()}
                                disabled={
                                  !isActive ||
                                  code_85_deduction_bonus?.[index]?.price_type === "amount"
                                }
                                fullWidth
                                percentage
                                spanText="%"
                                sx={{
                                  "& input": {
                                    paddingLeft: "18px !important",
                                    "&:disabled": { background: "#EEF0F2" }
                                  }
                                }}
                                autoWidth
                              />
                            </div>
                          </div>
                          <button
                            disabled={!isActive}
                            className="document-more-btn menu-item mb-1"
                            onClick={handleCode85DeleteBonus.bind(null, index)}
                          >
                            <img src={DeleteIcon} alt="" />
                          </button>
                        </div>
                      </div>
                    ))}

                    {code_85_deduction_bonus.length < 3 && (
                      <div
                        className="bonus-add-more"
                        style={{ marginTop: "16px" }}
                        onClick={handleCode85AddMore}
                      >
                        <span>+</span> Add more
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className="add-bonus-sec">
                <div className="option-toggle add-bonus-toggle" onClick={handleAllStatusCodeChange}>
                  <span
                    className={`toggle-btn ${
                      add_all_status_code_package_deduction_bonus ? "active" : ""
                    }`}
                  ></span>
                  <span>Add All Status Code Package</span>
                </div>
                <span className="employee-more-info mt-0">
                  Apply a pay reduction for each package with a status code error
                </span>

                {Boolean(add_all_status_code_package_deduction_bonus) && (
                  <div className="form-items-container">
                    <InputField
                      title="Status Code Threshold"
                      placeholder="1"
                      name="all_status_code_package_deduction_bonus[field_value]"
                      value={
                        all_status_code_package_deduction_bonus?.field_value === "0.00"
                          ? 0
                          : +all_status_code_package_deduction_bonus?.field_value || undefined
                      }
                      errorMessage={
                        errors.all_status_code_package_deduction_bonus?.field_value?.message
                      }
                      isError={
                        !!errors.all_status_code_package_deduction_bonus?.field_value?.message
                      }
                      isTouched={touchedFields.all_status_code_package_deduction_bonus?.field_value}
                      onChange={handleNumberChange.bind(
                        null,
                        "all_status_code_package_deduction_bonus[field_value]"
                      )}
                      onFocus={(event) => event.target.select()}
                      disabled={!isActive}
                      fullWidth
                    />

                    <InputField
                      title="Amount Reduction"
                      placeholder="0.00"
                      name="all_status_code_package_deduction_bonus[price_value]"
                      errorMessage={
                        errors.all_status_code_package_deduction_bonus?.price_value?.message
                      }
                      isError={
                        !!errors.all_status_code_package_deduction_bonus?.price_value?.message
                      }
                      isTouched={touchedFields.all_status_code_package_deduction_bonus?.price_value}
                      otherProps={register("all_status_code_package_deduction_bonus[price_value]")}
                      onChange={handleNumberChange.bind(
                        null,
                        "all_status_code_package_deduction_bonus[price_value]"
                      )}
                      onFocus={(event) => event.target.select()}
                      disabled={!isActive}
                      fullWidth
                      spanText="$"
                    />
                  </div>
                )}
              </div>

              <div className="add-bonus-sec">
                <div
                  className="option-toggle add-bonus-toggle"
                  onClick={handleDnaDeductionBonusChange}
                >
                  <span className={`toggle-btn ${add_dna_deduction_bonus ? "active" : ""}`}></span>
                  <span>Add Did Not Attempt</span>
                </div>
                <span className="employee-more-info mt-0">
                  Penalty for each missed delivery attempt
                </span>

                {Boolean(add_dna_deduction_bonus) && (
                  <div className="form-items-container">
                    <InputField
                      title="DNA Threshold"
                      placeholder="1"
                      name="dna_deduction_bonus[field_value]"
                      value={
                        dna_deduction_bonus?.field_value === "0.00"
                          ? 0
                          : +dna_deduction_bonus?.field_value || undefined
                      }
                      errorMessage={errors.dna_deduction_bonus?.field_value?.message}
                      isError={!!errors.dna_deduction_bonus?.field_value?.message}
                      isTouched={touchedFields.dna_deduction_bonus?.field_value}
                      onChange={handleNumberChange.bind(null, "dna_deduction_bonus[field_value]")}
                      disabled={!isActive}
                      fullWidth
                    />

                    <InputField
                      title="Amount Reduction"
                      placeholder="0.00"
                      name="dna_deduction_bonus[price_value]"
                      errorMessage={errors.dna_deduction_bonus?.price_value?.message}
                      isError={!!errors.dna_deduction_bonus?.price_value?.message}
                      isTouched={touchedFields.dna_deduction_bonus?.price_value}
                      otherProps={register("dna_deduction_bonus[price_value]")}
                      onChange={handleNumberChange.bind(null, "dna_deduction_bonus[price_value]")}
                      onFocus={(event) => event.target.select()}
                      disabled={!isActive}
                      fullWidth
                      spanText="$"
                    />
                  </div>
                )}
              </div>

              <div className="add-bonus-sec">
                <div
                  className="option-toggle add-bonus-toggle"
                  onClick={handleMissPickupWindowDeductionBonusChange}
                >
                  <span
                    className={`toggle-btn ${
                      add_miss_pickup_window_deduction_bonus ? "active" : ""
                    }`}
                  ></span>
                  <span>Add Missed Pickup</span>
                </div>
                <span className="employee-more-info mt-0">
                  Reduce pay for each scheduled pickup the employee fails to complete
                </span>

                {Boolean(add_miss_pickup_window_deduction_bonus) && (
                  <div className="form-items-container">
                    <InputField
                      title="Missed Pickup Threshold"
                      placeholder="1"
                      name="miss_pickup_window_deduction_bonus[field_value]"
                      value={
                        miss_pickup_window_deduction_bonus?.field_value === "0.00"
                          ? 0
                          : +miss_pickup_window_deduction_bonus?.field_value || undefined
                      }
                      errorMessage={errors.miss_pickup_window_deduction_bonus?.field_value?.message}
                      isError={!!errors.miss_pickup_window_deduction_bonus?.field_value?.message}
                      isTouched={touchedFields.miss_pickup_window_deduction_bonus?.field_value}
                      onChange={handleNumberChange.bind(
                        null,
                        "miss_pickup_window_deduction_bonus[field_value]"
                      )}
                      disabled={!isActive}
                      fullWidth
                    />

                    <InputField
                      title="Amount Reduction"
                      placeholder="0.00"
                      name="miss_pickup_window_deduction_bonus[price_value]"
                      errorMessage={errors.miss_pickup_window_deduction_bonus?.price_value?.message}
                      isError={!!errors.miss_pickup_window_deduction_bonus?.price_value?.message}
                      isTouched={touchedFields.miss_pickup_window_deduction_bonus?.price_value}
                      otherProps={register("miss_pickup_window_deduction_bonus[price_value]")}
                      onChange={handleNumberChange.bind(
                        null,
                        "miss_pickup_window_deduction_bonus[price_value]"
                      )}
                      onFocus={(event) => event.target.select()}
                      disabled={!isActive}
                      fullWidth
                      spanText="$"
                    />
                  </div>
                )}
              </div>

              <div className="add-bonus-sec mb-0">
                <div
                  className="option-toggle add-bonus-toggle"
                  onClick={handleEarlyLatePickupWindowDeductionBonusChange}
                >
                  <span
                    className={`toggle-btn ${
                      add_early_late_pickup_deduction_bonus ? "active" : ""
                    }`}
                  ></span>
                  <span>Add Early/Late Pickup</span>
                </div>
                <span className="employee-more-info mt-0">
                  Apply a penalty for pickups performed outside the scheduled time window
                </span>

                {Boolean(add_early_late_pickup_deduction_bonus) && (
                  <div className="form-items-container">
                    <InputField
                      title="Early/Late Pickup Threshold"
                      placeholder="1"
                      name="early_late_pickup_deduction_bonus[field_value]"
                      value={
                        early_late_pickup_deduction_bonus?.field_value === "0.00"
                          ? 0
                          : +early_late_pickup_deduction_bonus?.field_value || undefined
                      }
                      errorMessage={errors.early_late_pickup_deduction_bonus?.field_value?.message}
                      isError={!!errors.early_late_pickup_deduction_bonus?.field_value?.message}
                      isTouched={touchedFields.early_late_pickup_deduction_bonus?.field_value}
                      onChange={handleNumberChange.bind(
                        null,
                        "early_late_pickup_deduction_bonus[field_value]"
                      )}
                      disabled={!isActive}
                      fullWidth
                    />

                    <InputField
                      title="Amount Reduction"
                      placeholder="0.00"
                      name="early_late_pickup_deduction_bonus[price_value]"
                      errorMessage={errors.early_late_pickup_deduction_bonus?.price_value?.message}
                      isError={!!errors.early_late_pickup_deduction_bonus?.price_value?.message}
                      isTouched={touchedFields.early_late_pickup_deduction_bonus?.price_value}
                      otherProps={register("early_late_pickup_deduction_bonus[price_value]")}
                      onChange={handleNumberChange.bind(
                        null,
                        "early_late_pickup_deduction_bonus[price_value]"
                      )}
                      onFocus={(event) => event.target.select()}
                      disabled={!isActive}
                      fullWidth
                      spanText="$"
                    />
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </div>
  )
}

export default EmployeeFormPayRates

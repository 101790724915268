import { useMemo, useState } from "react"
import { batch, useDispatch, useSelector } from "react-redux"
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core"
import { restrictToVerticalAxis } from "@dnd-kit/modifiers"
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { useParams } from "react-router-dom"
import Loading from "./../common/Loading"
import {
  getPayrollTotalStats,
  getPayrollStatements
} from "../../store/payroll-preview/payroll-preview.selectors"
import { actions } from "../../store/payroll-preview/payroll-preview.reducers"
import PayrollTopTotal from "../common/PayrollTopTotal"
import { RunPayrollPreviewStaticRow } from "./RunPayrollPreviewStaticRow"
import { RunPayrollPreviewDraggableRow } from "./RunPayrollPreviewDraggableRow"
import axios from "../../axios"
import toastService from "../../services/toastService"

const RunPayrollPreviewTable = ({ loading, isFuelUploadDisabled }) => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const payrollTotalStats = useSelector(getPayrollTotalStats)
  const statements = useSelector(getPayrollStatements)

  const [activeOrder, setActiveOrder] = useState()

  const items = useMemo(() => statements?.map(({ order }) => `${order}`), [statements])

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  )

  const handleDragStart = (event) => setActiveOrder(event.active.id)
  const statementsCount = statements.length
  const handleDragEnd = (event) => {
    if (statementsCount <= 1) return // Skip logic if only one item
    const { active, over } = event

    if (active.id !== over.id) {
      const itemArr = statements
        .map((el) => {
          if (el.order === active.id) {
            return { ...el, order: over.id }
          }

          if (
            +el.order !== +active.id &&
            +over.id > +active.id &&
            +el.order <= +over.id &&
            +el.order > +active.id
          ) {
            return { ...el, order: `${+el.order - 1}` }
          }

          if (
            +el.order !== +active.id &&
            +over.id < +active.id &&
            +el.order >= +over.id &&
            +el.order < +active.id
          ) {
            return { ...el, order: `${+el.order + 1}` }
          }

          return el
        })
        .sort((a, b) => (+a.order < +b.order ? -1 : 1))

      const formData = new FormData()

      itemArr.forEach((el, index) => {
        formData.append(`statements[${index}][station_id]`, el.station_id)
        formData.append(`statements[${index}][order]`, +el.order)
      })

      dispatch(actions.setPayrollStatements(itemArr))
      setActiveOrder(null)

      axios
        .post(`/payroll/${id}/reorder-statements`, formData)
        .then((response) => {
          if (response.success) {
            batch(() => {
              toastService.success(response.message)
            })
          }
        })
        .catch((err) => toastService.error(err.response?.data?.message || err.message))
    } else {
      setActiveOrder(null)
    }
  }

  const handleDragCancel = () => {
    setActiveOrder(null)
  }

  const selectedRow = useMemo(() => {
    if (!activeOrder) {
      return null
    }

    const row = statements.find(({ order }) => order === activeOrder)
    return row
  }, [activeOrder])

  return (
    <div className="run-payroll-data">
      {loading ? (
        <div style={{ height: "400px" }}>
          <Loading />
        </div>
      ) : (
        <>
          <PayrollTopTotal totalStats={payrollTotalStats} isShowFuelIcon={!isFuelUploadDisabled} />
          <div className="payroll-table preview payroll-data-table">
            <DndContext
              sensors={sensors}
              onDragEnd={handleDragEnd}
              onDragStart={handleDragStart}
              onDragCancel={handleDragCancel}
              collisionDetection={closestCenter}
              modifiers={[restrictToVerticalAxis]}
            >
              <table>
                <thead>
                  <tr>
                    <th style={{ width: "30px" }}></th>
                    <th>
                      <div className="leaderboard-top-table-header-item">Company</div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">Station</div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">Exp Stop</div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">Stop</div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">Fuel</div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">Expense</div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">W-2</div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">Revenue</div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">Per Stop</div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">Gross Profit</div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <SortableContext items={items} strategy={verticalListSortingStrategy}>
                    {statements.map((row) => (
                      <RunPayrollPreviewDraggableRow
                        key={`${row.order}`}
                        row={row}
                        statementsCount={statementsCount}
                      />
                    ))}
                  </SortableContext>
                </tbody>
              </table>
              <DragOverlay dropAnimation={null}>
                {activeOrder && (
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <RunPayrollPreviewStaticRow row={selectedRow} />
                    </tbody>
                  </table>
                )}
              </DragOverlay>
            </DndContext>
          </div>
        </>
      )}
    </div>
  )
}

export default RunPayrollPreviewTable

import React from "react"
import "./assets/css/style.css"
import { BrowserRouter } from "react-router-dom"
import { Provider, useSelector } from "react-redux"
import Routing from "./Routing"
import store from "./store/store"
import toast, { Toaster, useToasterStore } from "react-hot-toast"
import "./App.scss"
import { useEffect } from "react"

const ToasterOptions = {
  style: {
    background: "rgba(45, 45, 47, 0.95)",
    color: "white",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "20px",
    borderRadius: "20px",
    padding: "14px 30px 14px 16px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
    minWidth: 300,
    maxWidth: 400
  },
  success: {
    iconTheme: {
      primary: "#4caf50",
      secondary: "rgba(45, 45, 47, 0.95)"
    }
  },
  error: {
    iconTheme: {
      primary: "#A4000F",
      secondary: "rgba(45, 45, 47, 0.95)"
    }
  }
}

const App = () => {
  const { toasts } = useToasterStore()

  const TOAST_LIMIT = 5

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)) // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts])

  return (
    <Provider store={store}>
      <BrowserRouter>
        {/* <PageAuthDefender> */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Routing />
          <Toaster position="top-center" reverseOrder={false} toastOptions={ToasterOptions} />
        </div>
        {/* </PageAuthDefender> */}
      </BrowserRouter>
    </Provider>
  )
}

export default App

import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useEffect, useState } from "react"
import { FormHelperText, MenuItem, Select, TextField } from "@mui/material"
import CloseCircle from "../../assets/images/close-circle.png"
import axios from "../../axios"
import Loading from "../common/Loading"
import { KeyboardArrowDown } from "@mui/icons-material"
import { ReactComponent as InfoCircle } from "../../assets/images/run-payroll-preview/info-circle.svg"
import PaychexShowCompanyID from "../../assets/images/paychex-show-company-id.png"
import { styled } from "@mui/material/styles"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import toastService from "../../services/toastService"

const styles = {
  error: {
    "& div input": {
      borderColor: "red !important"
    }
  },
  errorSelect: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red !important"
    }
  },
  selectErrorMessage: {
    marginLeft: 0,
    marginTop: "4px"
  },
  labelContainer: {
    display: "flex",
    alignItems: "center"
  },
  infoIcon: {
    marginLeft: "11px",
    marginTop: "-9px"
  }
}

const schema = yup.object().shape({
  display_id: yup.string().required("Paychex Client ID is required!"),
  primary_company_id: yup.string().required("Company Name is required!")
})

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#1D1858",
    boxShadow: "0px 0px 24px 0px #0000001A",
    fontSize: 12,
    borderRadius: "3px",
    fontWeight: 400,
    padding: 0,
    maxWidth: "400px",
    [`& .MuiTooltip-arrow`]: {
      color: theme.palette.common.white
    }
  }
}))

const PaychexIntegrationModal = ({ open, handleClose }) => {
  const [loadingRequest, setLoadingRequest] = useState(false)
  const [loading, setLoading] = useState(true)
  const [companies, setCompanies] = useState([])
  const [selectPrimaryCompany, setSelectPrimaryCompany] = useState("")

  const [tooltipOpen, setTooltipOpen] = useState(false)

  const handleTooltipToggle = () => {
    setTooltipOpen(!tooltipOpen)
  }

  const handleTooltipClose = () => {
    if (!("ontouchstart" in window)) {
      // Close tooltip on mouse out only for non-touch devices (desktop)
      setTooltipOpen(false)
    }
  }

  const handleTooltipOpen = () => {
    setTooltipOpen(true)
  }

  useEffect(() => {
    axios
      .get("/integrations/paychex/get-companies")
      .then((response) => {
        const companiesArray = Object.values(response.data)

        if (companiesArray.length === 0) {
          toastService.error("Please create a Company with a station!")

          reset()
          setSelectPrimaryCompany("")
        } else {
          setCompanies(companiesArray)
        }
      })
      .catch((err) => {
        toastService.error(err.response?.data?.message || err.message)

        reset()
        setSelectPrimaryCompany("")
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields }
  } = useForm({
    mode: "all",
    defaultValues: {
      display_id: "",
      primary_company_id: ""
    },
    resolver: yupResolver(schema)
  })

  const onRequestSubmit = async (values) => {
    setLoadingRequest(true)

    try {
      const response = await axios.post("/integrations/paychex/requestaccess", values)

      if (response.data.errors) {
        toastService.error(response.data.errors[0].description)
        reset()
        setSelectPrimaryCompany("")

        return
      }

      window.open(response.data.approvalLink, "_blank")
      window.location.reload()
    } catch (err) {
      toastService.error(err.response?.data?.message || err.message)
      reset()
      setSelectPrimaryCompany("")
    } finally {
      setLoadingRequest(false)
    }
  }

  const handleCompanyChange = (e) => {
    const targetValue = e.target?.value

    setSelectPrimaryCompany(targetValue)
  }

  return (
    <div
      className="employees-popup integration-app-popup"
      style={{ display: open ? "flex" : "none" }}
    >
      <div className="employees-inner">
        <div className="popup-top-bar">
          <h2>Connect to Paychex</h2>

          <button onClick={handleClose} className="popup-close">
            <img src={CloseCircle} alt="" />
          </button>
        </div>

        <div>
          <div className="popup-dis-sec integration-app-form">
            <form className="login-form">
              <div className="form-item">
                <label className={errors.primary_company_id?.message && "error"}>
                  Company Name *
                </label>
                {loading ? (
                  <Loading />
                ) : (
                  <div className="input-box">
                    <Select
                      style={{ width: "100%" }}
                      sx={errors.primary_company_id?.message ? styles.errorSelect : {}}
                      required
                      className="employees-select"
                      name="primary_company_id"
                      value={selectPrimaryCompany || ""}
                      {...register("primary_company_id")}
                      onChange={handleCompanyChange}
                      IconComponent={KeyboardArrowDown}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select Company Name
                      </MenuItem>
                      {companies?.map((el) => (
                        <MenuItem key={el.id} value={el.id}>
                          {el.name}
                        </MenuItem>
                      ))}
                    </Select>

                    {!!errors.primary_company_id?.message && (
                      <FormHelperText
                        component="div"
                        error={!!errors.primary_company_id?.message}
                        sx={styles.selectErrorMessage}
                      >
                        {errors.primary_company_id?.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              </div>
              <div className="input-items">
                <div style={styles.labelContainer}>
                  <label className={errors.display_id?.message && "error"}>
                    Paychex Client ID *
                  </label>
                  <LightTooltip
                    title={
                      <div className="statement-name-block-tooltip">
                        <div className="statement-name-block-tooltip-item">
                          8 Digit Number (Paychex Client ID)
                        </div>

                        <img
                          src={PaychexShowCompanyID}
                          style={{
                            maxWidth: "300px",
                            display: "block",
                            margin: "0 auto"
                          }}
                        />
                      </div>
                    }
                    arrow
                    open={tooltipOpen}
                    disableFocusListener
                    disableHoverListener={false}
                    disableTouchListener
                    onMouseEnter={handleTooltipOpen}
                    onMouseLeave={handleTooltipClose}
                    onClick={handleTooltipToggle} // Toggle tooltip on click for mobile devices
                  >
                    <InfoCircle
                      className="statement-name-block-tooltip-icon"
                      style={styles.infoIcon}
                      onClick={handleTooltipToggle} // Toggle tooltip on click for mobile devices
                    />
                  </LightTooltip>
                </div>
                <TextField
                  sx={errors.display_id?.message ? styles.error : {}}
                  type="text"
                  name="display_id"
                  required
                  placeholder="Enter Paychex Client ID"
                  {...register("display_id")}
                  error={touchedFields.display_id && !!errors.display_id?.message}
                  helperText={errors.display_id?.message}
                />
              </div>
            </form>
          </div>
          <div className="popup-btns-sec">
            <button onClick={handleClose} className="btn-style blank-btn add-popup-cancel">
              Cancel
            </button>
            {loadingRequest ? (
              <Loading />
            ) : (
              <input
                type="submit"
                onClick={handleSubmit(onRequestSubmit)}
                className="btn-style add-popup-save"
                disabled={loadingRequest || loading}
                value="Request"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaychexIntegrationModal

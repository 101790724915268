import { memo } from "react"
import { ReactComponent as FleetWageLogoEev } from "../../assets/images/fleet-wage-logo-rev.svg"
import { ReactComponent as FleetWageLogo } from "../../assets/images/fleet-wage-logo.svg"
import { ReactComponent as PackageRouteWageLogo } from "../../assets/images/package-route-logo.svg"
import { ReactComponent as PackageRouteWageLogoRev } from "../../assets/images/package-route-logo-rev.svg"
import { useSelector } from "react-redux"
import { getUser } from "../../store/auth/auth.selectors"

const LeftMenuHeader = ({ isCollapsed }) => {
  const userData = useSelector(getUser)
  const isUserRestricted = ["package_route"].includes(userData?.organization?.source)

  return (
    <div className="main-logo">
      {!!Object.keys(userData).length && (
        <a href="/" className="logo-item">
          {!isUserRestricted ? (
            <>{isCollapsed ? <FleetWageLogo /> : <FleetWageLogoEev />}</>
          ) : (
            <>
              {isCollapsed ? (
                <></>
              ) : (
                <div style={{ marginLeft: "-1.1rem" }}>
                  <PackageRouteWageLogoRev />
                </div>
              )}
            </>
          )}
        </a>
      )}
    </div>
  )
}

export default memo(LeftMenuHeader)

import "../../assets/css/modal.css"
import { Modal, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg"
import axios from "../../axios"
import toastService from "../../services/toastService"

const styles = {
  error: {
    "& div input": {
      borderColor: "red"
    }
  }
}

const schema = yup.object().shape({
  name: yup.string().required("Name is required!")
})

const AddOrEditPositionModal = ({ open, handleClose, setIsAddComplete, editPosition }) => {
  const [saveLoading, setSaveLoading] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, touchedFields }
  } = useForm({
    mode: "all",
    defaultValues: {
      name: ""
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (editPosition) {
      setValue("name", editPosition.name, {
        shouldValidate: true,
        shouldDirty: true
      })
    }
  }, [open])

  const handleMainClose = () => {
    handleClose()
    reset()
  }

  const onSubmit = (values) => {
    setSaveLoading(true)

    if (editPosition) {
      axios
        .post(`/settings/positions/${editPosition.id}`, { ...values, _method: "PATCH" })
        .then((response) => {
          if (response.success) {
            toastService.success("Position successfully edited")
            handleMainClose()
            setIsAddComplete(true)
          }
        })
        .catch((err) => toastService.error(err.response?.data?.message || err.message))
        .finally(() => setSaveLoading(false))
    } else {
      axios
        .post("/settings/positions", values)
        .then((response) => {
          if (response.success) {
            toastService.success("Position successfully added")
            handleMainClose()
            setIsAddComplete(true)
          }
        })
        .catch((err) => toastService.error(err.response?.data?.message || err.message))
        .finally(() => setSaveLoading(false))
    }
  }

  return (
    <Modal open={open} onClose={handleMainClose}>
      <div className="confirm-modal">
        <div className="confirm-modal-wrapper add-member-modal">
          <div className="add-member-modal-header">
            <div className="title-sec">
              <h2>{editPosition ? "Edit" : "Add"} Position</h2>
            </div>

            <div className="confirm-modal-close-wrapper" onClick={handleMainClose}>
              <CloseIcon />
            </div>
          </div>

          <form className="profile-form-sec add-member-modal-form">
            <div className="form-item">
              <label className={errors.name?.message && "error"} style={{ textAlign: "left" }}>
                {" "}
                Name *
              </label>
              <div className="input-box">
                <TextField
                  sx={errors.name?.message ? styles.error : {}}
                  fullWidth
                  type="text"
                  placeholder="Name"
                  name="name"
                  required
                  {...register("name")}
                  error={touchedFields.name && !!errors.name?.message}
                  helperText={errors.name?.message}
                />
              </div>
            </div>
          </form>

          <div className="add-member-form-btn-wrapper">
            <button className="btn-style blank-btn add-popup-cancel" onClick={handleMainClose}>
              Cancel
            </button>
            <button
              className="btn-style confirm-modal-btn company company-btn"
              onClick={handleSubmit(onSubmit)}
              disabled={saveLoading}
            >
              {editPosition ? "Save changes" : "Add"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AddOrEditPositionModal

import { ReactComponent as Delete } from "../../../assets/images/run-payroll/delete-filled.svg"
import { ReactComponent as Edit } from "../../../assets/images/settings/edit.svg"

const SettingsStationWorkAreaRow = ({ itemData, handleDelete, handleEdit }) => (
  <>
    <tr>
      <td>{itemData.work_area_number}</td>

      {/*<td>*/}
      {/*  {itemData.rates.auto_clock_in ? (*/}
      {/*    <div className="auto-clock-in-enabled">Enabled</div>*/}
      {/*  ) : (*/}
      {/*    <div className="auto-clock-in-disabled">Turned off</div>*/}
      {/*  )}*/}
      {/*</td>*/}
      <td>
        <div className="work-area-pay-rate-container">
          {itemData.rates_data?.map((el) => (
            <div key={el.name} className="statement-name-block-tooltip-item work-area-row">
              {el.name}: <b>{el.amount};</b>
            </div>
          ))}
        </div>
      </td>

      <td>
        <div className="station-btns-wrapper">
          <button className="companies-btn edit" onClick={handleEdit.bind(null, itemData)}>
            <Edit />
          </button>
          <button className="companies-btn delete" onClick={handleDelete.bind(null, itemData)}>
            <Delete className="companies-delete" />
          </button>
        </div>
      </td>
    </tr>
  </>
)

export default SettingsStationWorkAreaRow

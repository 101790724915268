import { ReactComponent as CheckedIcon } from "../../assets/images/run-payroll/checked.svg"

const RunPayrollProcessStep = ({
  handleStepChange,
  step,
  completedSteps,
  visitedSteps,
  name,
  number,
  isLastStep,
  countItem
}) => {
  const isCompleted = completedSteps.includes(number)
  const isActive = step === number
  const isFilled = visitedSteps.includes(number)

  return (
    <div
      className={`stepper-step ${isActive && !isCompleted ? "active" : ""} ${
        isCompleted ? "completed" : ""
      } ${isFilled && !isCompleted ? "filled" : ""}`}
      onClick={isFilled ? handleStepChange.bind(null, number) : null}
    >
      <div className="stepper-step-number-wrapper">
        <div className="stepper-step-number-container">
          <div className="stepper-step-number-container-number">
            <div className="stepper-step-number">{isCompleted ? <CheckedIcon /> : number}</div>
          </div>
        </div>
        <div className={`stepper-step-number-line  ${isLastStep ? "last-step" : ""}`} />
      </div>
      <div className="stepper-step-description">
        <div className={`stepper-step-name ${isActive ? "active" : ""}`}>{name} </div>
        <div className="stepper-step-count">
          {countItem?.total ? (
            <>
              Uploaded Files ({countItem?.uploaded}/{countItem?.total})
            </>
          ) : (
            <>Uploaded Files (0/1)</>
          )}
        </div>
      </div>
    </div>
  )
}

export default RunPayrollProcessStep

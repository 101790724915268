import { createSelector } from "@reduxjs/toolkit"

export const getData = (state) => state.leaderboard || {}

export const getLeaderboardList = createSelector(
  getData,
  ({ leaderboardList }) => leaderboardList || []
)
export const getLeaderboardData = createSelector(
  getData,
  ({ leaderboardData }) => leaderboardData || {}
)

import DocumentUploadBig from "../../assets/images/run-payroll/document-upload.svg"
import "./payroll-process.scss"

const RunPayrollDropzone = ({
  handleUploadFile,
  uploadText = "Click or drag a CSV or XSL file to this area to upload",
  fileFormats
}) => {
  return (
    <div className="dropdown-zone">
      <input
        type="file"
        id="file-uploader"
        accept={fileFormats || ".csv"}
        onChange={handleUploadFile}
        multiple
      />
      <img src={DocumentUploadBig} alt="" />
      <h6>{uploadText}</h6>
      <h6>Select file</h6>
    </div>
  )
}

export default RunPayrollDropzone

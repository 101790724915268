import { useEffect, useState } from "react"
import { batch, useDispatch, useSelector } from "react-redux"
import { Tab, Tabs } from "@mui/material"
import SearchBig from "../../assets/images/search-big.png"
import EmployeesTable from "./EmployeesTable"
import axios from "../../axios"
import { actions as employeeActions } from "../../store/employee/employee.reducers"
import { actions as authActions } from "../../store/auth/auth.reducers"
import { actions } from "../../store/data/data.reducers"
import { getEmployees, getEmployeesData } from "../../store/employee/employee.selectors"
import Loading from "../common/Loading"
import Pagination from "../common/Pagination"
import EmployeesModal from "../modals/EmployeesModal"
import ConfirmDeleteModal from "./../modals/ConfirmDeleteModal"
import EmptyState from "../common/EmptyState"
import { useTableSortItem } from "../../hooks/useTableSortItem"
import toastService from "../../services/toastService"
import { ReactComponent as Danger } from "../../assets/images/run-payroll-preview/danger.svg"
import { getUser } from "../../store/auth/auth.selectors"

const EmployeesContainer = ({ isAddComplete, setIsAddComplete }) => {
  const dispatch = useDispatch()
  const [editedUser, setEditedUser] = useState()
  const [deleteUser, setDeleteUser] = useState()
  const [isEditComplete, setIsEditComplete] = useState(false)
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")
  const [filterBy, setFilterBy] = useState(0)

  const { sortItem, setSortItem } = useTableSortItem("employees")

  const employees = useSelector(getEmployees)
  const employeeData = useSelector(getEmployeesData)
  const userData = useSelector(getUser)

  useEffect(() => {
    fetchCompanies()
    fetchStations()
    fetchPositions()

    return () => {
      dispatch(employeeActions.resetEmployeeData())
    }
  }, [])

  useEffect(() => {
    if (page > 1 && employees.length === 0) {
      // deleting last employee on the page
      setPage(page - 1)
      fetchEmployeeList(page - 1)
    }
  }, [employees])

  useEffect(() => {
    const handler = setTimeout(() => fetchEmployeeList(page), 500)

    return () => {
      clearTimeout(handler)
    }
  }, [searchQuery, sortItem, filterBy])

  useEffect(() => {
    if (isEditComplete || isAddComplete) {
      fetchEmployeeList(page)
      isEditComplete && setIsEditComplete(false)
      isAddComplete && setIsAddComplete(false)
    }
  }, [isEditComplete, isAddComplete])

  const fetchEmployeeList = (pageNumber) => {
    setLoading(true)

    axios
      .get(
        `/employee?page=${pageNumber}&search=${searchQuery}&field=${
          sortItem?.name || ""
        }&direction=${sortItem?.direction || ""}&arrowDirection=${sortItem?.arrowDirection || ""}${
          filterBy === 1 ? "&active=1" : filterBy === 2 ? "&active=0" : ""
        }${filterBy === 3 ? "&missed_info=1" : ""}`
      )
      .then((response) => {
        if (
          userData.has_missing_employees &&
          filterBy === 3 &&
          !response?.data?.data?.length &&
          !searchQuery.length
        ) {
          dispatch(authActions.updateUserData({ ...userData, has_missing_employees: false }))
        }
        batch(() => {
          dispatch(employeeActions.setEmployee(response.data.data))
          dispatch(employeeActions.setEmployeeData(response.data))
        })
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setLoading(false))
  }

  const fetchCompanies = () => {
    axios
      .get("/company-list")
      .then((response) => {
        dispatch(actions.setCompanies(response.data.companies))
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
  }

  const fetchStations = () => {
    axios
      .get("/station-list")
      .then((response) => {
        dispatch(actions.setStations(response.data.stations))
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
  }

  const fetchPositions = () => {
    axios
      .get("/settings/positions")
      .then((response) => {
        dispatch(actions.setPositions(response.data.positions))
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
  }

  const handleEdit = (user) => setEditedUser(user)
  const handleClose = () => setEditedUser()

  const handleChange = (event, value) => {
    setPage(value)
    fetchEmployeeList(value)
  }

  const handleDeleteEmployee = () => {
    axios
      .delete(`/employee/${deleteUser?.id}`)
      .then(() => {
        toastService.success("Employee deleted successfully")
        fetchEmployeeList(page)
        handleCloseDeleteModal()
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
  }

  const handleOpenDeleteModal = (user) => setDeleteUser(user)
  const handleCloseDeleteModal = () => setDeleteUser()

  const handleQueryChange = (e) => {
    setSearchQuery(e.target.value || "")
    setPage(1)
  }

  const handleSortChange = (sortItemName, type, arrowDirection) =>
    setSortItem({
      name: sortItemName,
      direction: type,
      arrowDirection: arrowDirection
    })

  const handleFilterByChange = (_, newValue) => {
    localStorage.setItem("employeesFilterBy", newValue)
    setFilterBy(newValue)
    setPage(1)
  }

  useEffect(() => {
    const employeesFilterBy = localStorage.getItem("employeesFilterBy")

    if (employeesFilterBy) {
      setFilterBy(+employeesFilterBy)
    }
  }, [])

  return (
    <>
      {
        <>
          <Tabs value={filterBy} onChange={handleFilterByChange} className="employees-page-tab">
            <Tab value={0} label="All" />
            <Tab value={1} label="Active" />
            <Tab value={2} label="Inactive" />
            <Tab
              value={3}
              label={
                <div className="employee-tab-danger">
                  {userData.has_missing_employees && <Danger />} Missed Info
                </div>
              }
            />
          </Tabs>
          <div className="employees-data-sec" style={{ height: loading ? "400px" : "auto" }}>
            {(employees.length > 0 ||
              searchQuery.length > 1 ||
              (!employees.length && searchQuery.length > 1)) && (
              <div className="payroll-top">
                <div className="payroll-result-count">{employeeData.total} employees</div>
                <div className="payroll-header-container">
                  <div className="search-box">
                    <img src={SearchBig} alt="" />
                    <input
                      type="text"
                      placeholder="Search item"
                      value={searchQuery}
                      onChange={handleQueryChange}
                    />
                  </div>
                </div>
              </div>
            )}
            {!employees.length && !loading ? (
              <EmptyState title="No Employees" />
            ) : loading ? (
              <div className="loader-wrapper">
                <Loading />
              </div>
            ) : (
              <>
                <div
                  className="table-scroll-mobile payroll-table employees"
                  style={{ height: "auto" }}
                >
                  <EmployeesTable
                    userList={employees}
                    handleEdit={handleEdit}
                    handleDeleteEmployee={handleOpenDeleteModal}
                    handleSortChange={handleSortChange}
                    activeSortedItem={sortItem}
                  />
                </div>
                {employeeData.last_page > 1 && (
                  <Pagination
                    page={page}
                    total_pages={employeeData.last_page}
                    onChange={handleChange}
                  />
                )}
              </>
            )}
          </div>

          <EmployeesModal
            open={!!editedUser}
            handleClose={handleClose}
            editedUserId={editedUser?.id}
            setIsEditComplete={setIsEditComplete}
          />

          <ConfirmDeleteModal
            open={!!deleteUser}
            handleClose={handleCloseDeleteModal}
            handleConfirm={handleDeleteEmployee}
            text="Are you sure delete this Employee?"
          />
        </>
      }
    </>
  )
}

export default EmployeesContainer

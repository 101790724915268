import "../assets/css/run-payroll.css"

import { useEffect, useState } from "react"
import { batch, useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { Badge, Menu, MenuItem, useMediaQuery } from "@mui/material"
import { CalculateOutlined, Downloading } from "@mui/icons-material"
import { ReactComponent as CalculatorIcon } from "../assets/images/run-payroll-preview/calculator.svg"

import { styled } from "@mui/material/styles"
import RunPayrollPreviewTable from "../components/run-payroll-preview/RunPayrollPreviewTable"
import axios from "../axios"
import { actions as dataActions } from "../store/data/data.reducers"
import { actions } from "../store/payroll-preview/payroll-preview.reducers"
import { actions as authActions } from "../store/auth/auth.reducers"
import { calculateProfit, formatMoney, formatNumber } from "../utils"
import { getBreadCrumbs } from "../store/bread-crumbs/bread-crumbs.selectors"
import { actions as breadCrumbsActions } from "../store/bread-crumbs/bread-crumbs.reducers"
import { getPayrollData } from "../store/payroll-preview/payroll-preview.selectors"
import CSV from "../assets/images/run-payroll-preview/csv.svg"
import PDF from "../assets/images/run-payroll-preview/pdf.svg"
import Wex from "../assets/images/run-payroll-preview/wex.svg"
import Rcpa from "../assets/images/run-payroll-preview/rcpa.svg"
import Sunoco from "../assets/images/run-payroll-preview/sunoco.png"
import Coast from "../assets/images/run-payroll-preview/coast-logo.svg"
import PackageRoute from "../assets/images/run-payroll-preview/package-route.png"
import GroundCloud from "../assets/images/run-payroll-preview/GC.svg"
import BeansRoute from "../assets/images/run-payroll-preview/beans-route.png"
import { ReactComponent as TimeCardUpload } from "../assets/images/run-payroll-preview/time-card.svg"
import { ReactComponent as FuelUpload } from "../assets/images/run-payroll-preview/fuel.svg"
import { ReactComponent as ExportIcon } from "../assets/images/run-payroll-preview/export.svg"
import { ReactComponent as ReceiveSquare } from "../assets/images/run-payroll-preview/receive-square.svg"
import { ReactComponent as DocumentUpload } from "../assets/images/run-payroll-preview/document-upload.svg"
import { ReactComponent as UploadIco } from "../assets/images/run-payroll-preview/document-text.svg"
import AppBig from "../assets/images/settings/app-big.png"
import PayrollTimeUpload from "../components/modals/PayrollTimeUpload"
import { getUser } from "../store/auth/auth.selectors"
import NotSelectedCompanyModal from "../components/modals/NotSelectedCompanyModal"
import Loading from "../components/common/Loading"
import {
  calculatePercentageOfTotal,
  formatDate,
  getPayrollPeriodString,
  initiateFileDownloadFromBlob,
  isUserSourceRestricted
} from "../helpers/helpers"
import { getPayrollList } from "../store/payroll/payroll.selectors"
import { actions as payrollReducers } from "../store/payroll/payroll.reducers"
import ConfirmRecalculateModal from "./../components/modals/ConfirmRecalculateModal"
import { getIsProcessPayrollStarted } from "../store/data/data.selectors"
import DefaultPageHeader from "../components/shared/DefaultPageHeader"
import { companyIcons } from "../constants"
import Gusto from "../assets/images/settings/Gusto1.svg"
import Paychex from "../assets/images/settings/paychex.svg"
import Adp from "../assets/images/settings/ADP-logo.png"
import CorporatePayrollServices from "../assets/images/settings/corporate_payroll_services.png"
import Netchex from "../assets/images/settings/netchex.png"
import DarkTooltip from "../components/common/DarkToolTip"
import toastService from "../services/toastService"

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    marginRight: 30,
    backgroundColor: "#F1AE15"
  }
}))

const findTrueKey = (obj) => {
  for (let key in obj) {
    if (obj[key] === true) {
      return key
    }
  }
  return undefined
}

const images = {
  adp: Adp,
  adp_v2: Adp,
  corporate_payroll_services: CorporatePayrollServices,
  netchex: Netchex,
  paychex: Paychex,
  gusto: Gusto
}

const RunPayrollPreview = () => {
  const dispatch = useDispatch()
  const nav = useNavigate()
  const { id } = useParams()
  const userData = useSelector(getUser)
  const isUserRestricted = isUserSourceRestricted()
  let navigate = useNavigate()

  const breadCrumbs = useSelector(getBreadCrumbs)
  const payrollData = useSelector(getPayrollData)
  const isProcessPayrollStarted = useSelector(getIsProcessPayrollStarted)

  const [isLoadingProcessPayroll, setIsLoadingProcessPayroll] = useState(false)
  const [isProcessPayrollModalOpen, setIsProcessPayrollModalOpen] = useState(false)
  const [isUploadingTimeOpen, setIsUploadingTimeOpen] = useState(false)
  const [uploadingTimeOpenType, setUploadingTimeOpenType] = useState()
  const [isPrintPDFInProgress, setIsPrintPDFInProgress] = useState(false)
  const [isPrintCSVInProgress, setIsPrintCSVInProgress] = useState(false)
  const [isPrintCSVFullInProgress, setIsPrintCSVFullInProgress] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElFuel, setAnchorElFuel] = useState(null)
  const [anchorElTimeCard, setAnchorElTimeCard] = useState(null)
  const [isNotSelectedCompanyOpen, setIsNotSelectedCompanyOpen] = useState(false)
  const [isShowProcessPayroll, setIsShowProcessPayroll] = useState(false)
  const [isPaychexIntegrations, setIsPaychexIntegrations] = useState(false)
  const [isOpenRecalculateModal, setIsOpenRecalculateModal] = useState(false)
  const [connectedApp, setConnectedApp] = useState()
  const [changedEmployeeRatesCount, setChangedEmployeeRatesCount] = useState(0)

  const payrolls = useSelector(getPayrollList)

  const isMobile = useMediaQuery("(max-width:990px)")

  const handleOpen = (event) => setAnchorEl(event.currentTarget)
  // const handleFuelOpen = (event) => setAnchorElFuel(event.currentTarget);
  // const handleTimeCardOpen = (event) => {
  //   if (isUserRestricted) {
  //     // in case we don't need to show all time cards
  //     // like for Package Route Integration
  //     if (filteredStatementList.length > 1) {
  //       handleUploadTimeModal("package-route");
  //     }
  //
  //     return;
  //   }
  //
  //   setAnchorElTimeCard(event.currentTarget);
  // };

  const handleClose = () => setAnchorEl(null)

  const open = Boolean(anchorEl)

  const handleDownloadClick = (e) => {
    e.preventDefault()
    handleClose()

    if (userData.is_payroll_company_connected) {
      axios({
        method: "get",
        url: `/payroll/${id}/download-wage-calculator`,
        responseType: "blob"
      })
        .then((response) => {
          const currentDate = new Date()

          const formattedDate =
            `${currentDate.getMonth() + 1}`.padStart(2, "0") +
            `${currentDate.getDate()}`.padStart(2, "0") +
            currentDate.getFullYear()

          initiateFileDownloadFromBlob(response, `EmployeePreview-${formattedDate}`)
        })
        .catch((err) => toastService.error(err.response?.data?.message || "Something went wrong"))
    } else {
      setIsNotSelectedCompanyOpen(true)
    }
  }

  const getChangedEmployeeRatesCount = () => {
    axios(`/payroll/${id}/changed-employee-rates-count`).then((response) => {
      if (response.success === true) {
        setChangedEmployeeRatesCount(response.data)
      }
    })
  }

  const handleProcessPayrollCloseClick = () => setIsProcessPayrollModalOpen(false)
  const handleProcessPayrollOpenClick = () => {
    handleClose()

    setIsProcessPayrollModalOpen(true)
  }

  const checkProcessPayrollStatus = () =>
    axios.get(`/integrations/paychex/check-process-payroll/${id}`).then((res) => {
      dispatch(dataActions.setIsProcessPayrollStarted(res.data.progress || false))
    })

  const handleProcessPayrollClick = (e) => {
    e.preventDefault()

    setIsLoadingProcessPayroll(true)

    axios
      .post(`/integrations/paychex/process-payroll/${id}`)
      .then(() => {
        handleProcessPayrollCloseClick()
        dispatch(dataActions.setIsProcessPayrollStarted(true))
      })
      .catch((err) => toastService.error(err.response?.data?.message || "Something went wrong"))
      .finally(() => setIsLoadingProcessPayroll(false))
  }

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (payrollData.date && (breadCrumbs.length === 0 || breadCrumbs.length > 1)) {
      dispatch(
        breadCrumbsActions.setBreadCrumbs([
          {
            name: "Payroll",
            url: `/run-payroll`
          },
          {
            name: getPayrollPeriodString(payrollData.date),
            url: `/run-payroll-preview/${id}`
          }
        ])
      )
    } else if (payrollData.date && breadCrumbs.length === 1) {
      dispatch(
        breadCrumbsActions.setBreadCrumbs([
          ...breadCrumbs,
          {
            name: getPayrollPeriodString(payrollData.date),
            url: `/run-payroll-preview/${id}`
          }
        ])
      )
    }

    if (payrollData.is_last_payroll) {
      getChangedEmployeeRatesCount()
    }
  }, [payrollData])

  useEffect(() => {
    fetchPayrollData()

    return () => {
      dispatch(actions.resetPayroll())
    }
  }, [])

  const fetchPayrollData = () => {
    setLoading(true)

    axios
      .get(`/payroll/${id}`)
      .then((response) => {
        const payrollData = response.data

        const profit = calculateProfit(+payrollData.total_w2, payrollData)

        const payrollTotalStats = [
          {
            name: "Employees",
            count: payrollData.total_employees
          },
          {
            name: "Stops",
            count: formatNumber(payrollData.total_stops)
          },
          {
            name: "Fuel",
            count: formatMoney(+payrollData.total_fuel === 0 ? 0 : payrollData.total_fuel),
            percentage: calculatePercentageOfTotal(
              payrollData.total_fuel,
              payrollData.total_charges
            )
          },
          {
            name: "Expenses",
            count: formatMoney(+payrollData.total_expenses === 0 ? 0 : payrollData.total_expenses),
            percentage: calculatePercentageOfTotal(
              payrollData.total_expenses,
              payrollData.total_charges
            )
          },
          {
            name: "W-2",
            count: formatMoney(payrollData.total_w2),
            percentage: calculatePercentageOfTotal(payrollData.total_w2, payrollData.total_charges)
          },
          // TODO total_charges - revenue need to check
          {
            name: "Revenue",
            count: formatMoney(payrollData.total_charges)
          },
          // TODO total_per_stop need to check
          {
            name: "Per Stop",
            count: formatMoney(payrollData.total_charges / payrollData.total_stops, true)
          },
          {
            name: "Profit",
            count: formatMoney(profit),
            percentage: calculatePercentageOfTotal(profit, payrollData.total_charges)
          }
        ]

        // payrollData.total_fuel > 0 && setIsFuelUploadDisabled(true);
        batch(() => {
          dispatch(actions.setPayrollTotalStats(payrollTotalStats))
          dispatch(
            actions.setPayrollStatements(
              payrollData.statements.map((el, index) => ({ ...el, order: `${index + 1}` }))
            )
          )
          dispatch(actions.setPayrollData(payrollData))
        })
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => {
        setLoading(false)

        axios
          .get("/integrations/paychex/is-show-process-payroll")
          .then((response) => {
            if (response.data) {
              axios
                .get("/payroll")
                .then((response) =>
                  dispatch(payrollReducers.setPayrollList(response.data.payrolls))
                )
                .catch((err) => toastService.error(err.response?.data?.message || err.message))
                .finally(() => {
                  setIsShowProcessPayroll(true)
                })
            }
          })
          .catch((err) => toastService.error(err.response?.data?.message || err.message))
      })
  }

  useEffect(() => {
    if (payrolls[0]?.id == id && isShowProcessPayroll) {
      checkProcessPayrollStatus()
    }
  }, [isShowProcessPayroll])

  const fetchUserData = () =>
    axios
      .get("/auth/user")
      .then((response) => {
        dispatch(authActions.loginUser(response))
        localStorage.setItem("user", JSON.stringify(response || {}))
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))

  const handlePrintPDFReport = (e) => {
    setIsPrintPDFInProgress(true)

    e.preventDefault()
    axios({
      method: "get",
      url: `/payroll/${id}/download-pdf-report`,
      responseType: "blob"
    })
      .then((response) => {
        initiateFileDownloadFromBlob(response, "report")
      })
      .catch(() => toastService.error("Something went wrong"))
      .finally(() => {
        setIsPrintPDFInProgress(false)
        handleClose()
      })
  }

  const handlePrintCSVReport = (e) => {
    setIsPrintCSVInProgress(true)
    handleClose()

    e.preventDefault()
    axios({
      method: "get",
      url: `/payroll/${id}/download-csv-report`,
      responseType: "blob"
    })
      .then((response) => {
        initiateFileDownloadFromBlob(response, "report")
      })
      .catch(() => toastService.error("Something went wrong"))
      .finally(() => {
        setIsPrintCSVInProgress(false)
        handleClose()
      })
  }

  const handlePrintCSVFullReport = (e) => {
    setIsPrintCSVFullInProgress(true)
    handleClose()

    e.preventDefault()
    axios({
      method: "get",
      url: `/payroll/${id}/download-csv-report?detailed=true`,
      responseType: "blob"
    })
      .then((response) => {
        initiateFileDownloadFromBlob(response, "report-full")
      })
      .catch(() => toastService.error("Something went wrong"))
      .finally(() => {
        setIsPrintCSVFullInProgress(false)
        handleClose()
      })
  }

  const handleConfirmRecalculate = () => {
    handleCloseRecalculateModal()

    toastService.promise(
      axios.post(`/payroll/${id}/recalculate`),
      {
        loading: "Recalculating...",
        success: (data) => data.message,
        error: (error) => error.response?.data?.message || error.message // Handle error messages
      },
      () => {
        fetchPayrollData()
      }
    )
  }

  const handleOpenRecalculateModal = () => setIsOpenRecalculateModal(!isOpenRecalculateModal)
  const handleCloseRecalculateModal = () => setIsOpenRecalculateModal(!isOpenRecalculateModal)

  useEffect(() => {
    axios
      .get("/integrations/paychex")
      .then((response) => {
        setIsPaychexIntegrations(
          response?.data?.some((integration) => integration.data.approved) || false
        )
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))

    axios
      .get("/integrated-applications")
      .then((response) => setConnectedApp(findTrueKey(response.data)))
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
  }, [])

  const handleGoToProcessPayroll = () => {
    nav(`/run-payroll/${id}/process`, {
      state: {
        fromPayrollPreview: true
      }
    })
  }

  const actionButtons = (
    <>
      {payrollData.is_last_payroll && (
        <>
          <button
            onClick={handleOpenRecalculateModal}
            className="btn-style upload-fuel-btn employees-profile-img-upload dsw"
          >
            <>
              <CalculatorIcon />
              {!isMobile && "Recalculate"}
            </>
          </button>
          <DarkTooltip
            placement="top"
            title="Update payroll with the latest rates. This will overwrite current calculations"
            styles={{ right: 22 }}
            arrow
          >
            <StyledBadge color="warning" badgeContent={changedEmployeeRatesCount} />
          </DarkTooltip>
        </>
      )}
      <button
        className="btn-style upload-fuel-btn employees-profile-img-upload dsw"
        onClick={handleGoToProcessPayroll}
      >
        <>
          <UploadIco />
          {!isMobile && "Upload Data"}
        </>
      </button>
      <button
        onClick={handleOpen}
        disabled={isProcessPayrollStarted}
        className="btn-style export-btn dsw"
      >
        {isProcessPayrollStarted ? (
          <Loading />
        ) : (
          <>
            <ExportIcon />
            {!isMobile && "Export"}
          </>
        )}
      </button>
    </>
  )

  return (
    <>
      {!loading && (
        <DefaultPageHeader
          title={`Pay Period ${
            payrollData
              ? formatDate(payrollData.date, false) + " - " + formatDate(payrollData.date, true, 6)
              : null
          }`}
          actionButtons={actionButtons}
          page={`/run-payroll`}
        />
      )}
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} className="more-menu report">
        <MenuItem onClick={handlePrintPDFReport}>
          <button className="document-more-btn menu-item" disabled={isPrintPDFInProgress}>
            <img src={PDF} alt="" />
          </button>
          Payroll (pdf)
        </MenuItem>
        <MenuItem onClick={handlePrintCSVReport}>
          <button className="document-more-btn menu-item" disabled={isPrintCSVInProgress}>
            <img src={CSV} alt="" />
          </button>
          Payroll (csv)
        </MenuItem>
        <MenuItem onClick={handlePrintCSVFullReport}>
          <button className="document-more-btn menu-item" disabled={isPrintCSVFullInProgress}>
            <img src={CSV} alt="" />
          </button>
          Payroll (csv-full)
        </MenuItem>
        {(!isShowProcessPayroll ||
          (userData.directIntegrations && userData.templateIntegrations)) && (
          <MenuItem onClick={handleDownloadClick}>
            <button
              onClick={handleDownloadClick}
              className="document-more-btn icon-black menu-item"
            >
              {connectedApp && images[connectedApp] ? (
                <img src={images[connectedApp]} alt="" />
              ) : (
                <ReceiveSquare />
              )}
            </button>
            Export Payroll (csv)
          </MenuItem>
        )}
        {isPaychexIntegrations && (
          <MenuItem
            onClick={handleProcessPayrollOpenClick}
            disabled={!(isShowProcessPayroll && payrolls[0]?.id == id) || isProcessPayrollStarted}
          >
            {/*<Tooltip*/}
            {/*  title={*/}
            {/*    !(isShowProcessPayroll && payrolls[0]?.id == id) &&*/}
            {/*    !isProcessPayrollStarted*/}
            {/*      ? 'You need connected direct integration to process payroll automatically'*/}
            {/*      : ''*/}
            {/*  }>*/}
            <button
              onClick={handleProcessPayrollOpenClick}
              disabled={!(isShowProcessPayroll && payrolls[0]?.id == id) || isProcessPayrollStarted}
              className="document-more-btn menu-item"
            >
              {isProcessPayrollStarted ? (
                <Loading />
              ) : isMobile ? (
                <Downloading />
              ) : (
                <img src={companyIcons["paychex"]} width={20} height={20} alt="" />
              )}
            </button>
            Process Payroll
          </MenuItem>
        )}

        <MenuItem onClick={() => navigate("/app")} className="border-last-item">
          <button className="document-more-btn menu-item">
            <img src={AppBig} alt="" className="integrations-icon" />
          </button>
          Integrations
        </MenuItem>
      </Menu>

      <RunPayrollPreviewTable loading={loading} />

      <NotSelectedCompanyModal
        open={isNotSelectedCompanyOpen}
        handleClose={() => setIsNotSelectedCompanyOpen(false)}
      />
      <ConfirmRecalculateModal
        open={isProcessPayrollModalOpen}
        handleClose={handleProcessPayrollCloseClick}
        handleConfirm={handleProcessPayrollClick}
        subText="This process may take some time."
        text="Are you sure you want to Process Payroll?"
        isLoading={isLoadingProcessPayroll}
        confirmButtonText="Confirm"
      />
      <ConfirmRecalculateModal
        open={isOpenRecalculateModal}
        handleClose={handleCloseRecalculateModal}
        handleConfirm={handleConfirmRecalculate}
        text="Are you sure recalculate this Payroll?"
        isLoading={false}
      />
    </>
  )
}

export default RunPayrollPreview

import "../assets/css/run-payroll.css"
import { useEffect, useState } from "react"
import axios from "../axios"
import Loading from "../components/common/Loading"
import { formatMoney, formatNumber } from "../utils"
import toastService from "../services/toastService"
import Avatar from "../components/common/Avatar"
import BlurText from "../components/common/BlurText"
import { NumericFormat } from "react-number-format"
import { useParams } from "react-router-dom"
import { styled } from "@mui/material/styles"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import { ReactComponent as InfoCircle } from "../assets/images/run-payroll-preview/info-circle.svg"
import RunPayrollStatementMoreTableRow from "../components/run-payroll-preview/RunPayrollStatementMoreTableRow"
import dayjs from "dayjs"
import DarkTooltip from "../components/common/DarkToolTip"
import EmptyState from "../components/common/EmptyState"
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import DefaultPageHeader from "../components/shared/DefaultPageHeader"
import customParseFormat from "dayjs/plugin/customParseFormat"
import { useMediaQuery } from "@mui/material"

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#1D1858",
    boxShadow: "0px 0px 24px 0px #0000001A",
    fontSize: 12,
    borderRadius: "3px",
    fontWeight: 400,
    padding: 0,
    maxWidth: "400px",
    [`& .MuiTooltip-arrow`]: {
      color: theme.palette.common.white
    }
  }
}))

const EmployeeReport = () => {
  const { payrollId, employeeId } = useParams()
  const [loading, setLoading] = useState(false)
  const [employeeReport, setEmployeeReport] = useState([])

  const isMobile = useMediaQuery("(max-width:990px)")

  useEffect(() => {
    fetchEmployeeReport()
  }, [])

  const fetchEmployeeReport = () => {
    setLoading(true)

    axios
      .get(`/employee-report/payroll/${payrollId}/${employeeId}`)
      .then((response) => {
        setEmployeeReport(response?.data || {})
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setLoading(false))
  }

  const rates = employeeReport?.employee?.rates || {}

  const isWeekly = rates?.weekly_rate_w2

  const isOvertime = (weekly) => {
    return rates?.is_overtime_enabled && +weekly?.total_hours > 40
  }

  const calculatedHour = (weekly) => {
    return rates?.subtracting_daily_pay
      ? 0
      : rates?.is_work_area_enabled === 1
        ? weekly?.total_wa_hourly
        : isOvertime(weekly)
          ? 40 * rates?.hourly_rate + (+weekly?.total_hours - 40) * (1.5 * rates?.hourly_rate)
          : weekly?.total_hours * +rates?.hourly_rate
  }

  const getBonusAmount = (name, fallbackAmount) => {
    const bonus = Array.isArray(employeeReport?.report_rates)
      ? employeeReport?.report_rates?.find((b) => b.name === name)
      : null
    return bonus ? parseFloat(fallbackAmount || 0) : 0
  }

  const incentivesBonus = (weekly) => {
    const sixthDayBonus = getBonusAmount("6th Day Bonus", weekly?.six_day_bonus)
    const sundayBonus = getBonusAmount("Sunday Bonus", weekly?.sunday_bonus)
    return (sixthDayBonus + sundayBonus + parseFloat(weekly?.added_bonus)).toFixed(2)
  }

  const paddingStyles = {
    paddingTop: "24px",
    paddingBottom: "24px",
    paddingLeft: isMobile ? "10px" : "48px",
    paddingRight: isMobile ? "10px" : "48px"
  }

  return (
    <div
      className={`run-payroll-data statement employee-report-profile ${isMobile ? "mobile" : ""}`}
      style={paddingStyles}
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <DefaultPageHeader
            title={`${employeeReport?.employee?.first_name} ${
              employeeReport?.employee?.last_name
            } Report for Pay Period ${dayjs(employeeReport?.payroll?.date).format(
              "MMM DD"
            )} - ${dayjs(employeeReport?.payroll?.date).add(6, "d").format("MMM DD, YYYY")}`}
            isBackButtonVisible={false}
          />
          <div className="run-payroll-data-statement-content">
            <div className="payroll-table payroll-data-table statement statement-height">
              <table>
                <thead>
                  <tr>
                    <th>
                      <div className="leaderboard-top-table-header-item">Name </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">Day </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">Exp Stop </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">Fuel </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">Stop </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">Pkg </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">Pay </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">Addition </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">Deduction </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">Training </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">PTO </div>
                    </th>
                    <th>
                      <div className="leaderboard-top-table-header-item">Hour </div>
                    </th>
                    <th>
                      <div
                        className="leaderboard-top-table-header-item"
                        style={{ fontWeight: 700 }}
                      >
                        Total{" "}
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    style={{
                      background: "#FFFFFF",
                      borderLeft: "none",
                      borderRight: "none"
                    }}
                  >
                    <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                      <div className="statement-first-block">
                        <Avatar
                          variant="circular"
                          className="statement-avatar"
                          alt={employeeReport.employee?.first_name}
                          src={employeeReport.employee?.profile_image}
                          withBadge
                          badgeType={isWeekly ? "Weekly" : "Daily"}
                          isMultipleIcon={employeeReport.worked_in_multiple_stations}
                          warnings={employeeReport.warnings}
                        />
                        <div className="statement-name-block">
                          <div className="statement-name-block-content">
                            <div>
                              {employeeReport.employee?.first_name}{" "}
                              <BlurText>{employeeReport.employee?.last_name}</BlurText>{" "}
                            </div>
                            <LightTooltip
                              title={
                                <div className="statement-name-block-tooltip">
                                  <div className="title">
                                    <p>
                                      {employeeReport.report_rates?.length > 0
                                        ? "Pay Structure"
                                        : "No Pay Structure"}
                                    </p>
                                  </div>
                                  {employeeReport.report_rates?.length > 0
                                    ? employeeReport.report_rates.map((el) => (
                                        <div
                                          key={el.name}
                                          className={`statement-name-block-tooltip-item ${
                                            el.name === "Threshold" ? "threshold" : ""
                                          }`}
                                        >
                                          {el.name}:{" "}
                                          <b>
                                            {el.amount}{" "}
                                            {el.name === "Weekly W-2" &&
                                              employeeReport.employee.rates.adding_daily_pay ===
                                                0 &&
                                              "(CAP)"}
                                          </b>
                                        </div>
                                      ))
                                    : null}
                                </div>
                              }
                              arrow
                            >
                              <InfoCircle className="statement-name-block-tooltip-icon" />
                            </LightTooltip>
                          </div>

                          <div className="statement-company">
                            {employeeReport.employee?.company_position_name}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                      {employeeReport.working_days +
                        employeeReport.training_days +
                        employeeReport.pto}
                    </td>
                    <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                      {formatMoney(employeeReport.expense_stop, true)}
                    </td>
                    <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                      <div>
                        <div className="input-box">
                          {formatMoney(employeeReport.total_fuel, true)}
                        </div>
                      </div>
                    </td>
                    <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                      {formatNumber(employeeReport.total_stops)}
                    </td>
                    <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                      {formatNumber(employeeReport.total_packages)}
                    </td>
                    <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                      {formatMoney(employeeReport.pay, true)}
                    </td>
                    <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                      <div>{formatMoney(employeeReport.addition, true)}</div>
                    </td>
                    <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                      <div>{formatMoney(employeeReport.deduction, true)}</div>
                    </td>
                    <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                      <div>
                        <div className="input-box">{employeeReport.training_days}</div>
                      </div>
                    </td>
                    <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                      <div className="form-item statement-input">{employeeReport.pto}</div>
                    </td>
                    <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                      <div>{employeeReport?.total_hours?.toFixed(2)}</div>
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #EEF0F2",
                        fontWeight: 600,
                        color: "#1D1858"
                      }}
                    >
                      {formatMoney(employeeReport.total_w2, true)}
                    </td>
                  </tr>
                  {employeeReport?.weekly_summaries?.length > 0 && (
                    <>
                      {employeeReport?.weekly_summaries
                        .sort((a, b) => {
                          if (a.daily_summaries.length === 0 && b.daily_summaries.length > 0) {
                            return -1
                          }
                          if (a.daily_summaries.length > 0 && b.daily_summaries.length === 0) {
                            return 1
                          }
                          return 0
                        })
                        .map((el) => (
                          <>
                            <tr
                              style={{
                                background: "rgb(245, 245, 246)",
                                borderLeft: "1px solid #EEF0F2",
                                borderRight: "1px solid #EEF0F2"
                              }}
                            >
                              <td className="no-hover-effect" style={{ borderBottom: "none" }}>
                                <div className="statement-first-block">
                                  <div
                                    className="statement-name-block"
                                    style={{ marginLeft: "54px" }}
                                  >
                                    <div className="statement-name-block-content">
                                      <div>{el.company.name}</div>
                                    </div>

                                    <div className="statement-company">{el.station.name}</div>
                                  </div>
                                </div>
                              </td>
                              <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                                {el.weekly?.working_days +
                                  el.weekly?.training_days +
                                  el.weekly?.pto}
                              </td>
                              <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                                {formatMoney(el.weekly?.expense_stop, true)}
                              </td>
                              <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                                <div>{formatMoney(el.weekly?.total_fuel, true)}</div>
                              </td>
                              <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                                {formatNumber(el.weekly?.total_stops)}
                              </td>
                              <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                                {formatNumber(el.weekly?.total_packages)}
                              </td>
                              <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                                {formatMoney(el.weekly?.pay, true)}
                              </td>
                              <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                                <div>{formatMoney(el.weekly?.addition, true)}</div>
                              </td>
                              <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                                <div>{formatMoney(el.weekly?.deduction, true)}</div>
                              </td>
                              <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                                <div>{el.weekly?.training_days}</div>
                              </td>
                              <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                                <div className="form-item statement-input">{el.weekly?.pto}</div>
                              </td>
                              <td style={{ borderBottom: "1px solid #EEF0F2" }}>
                                <div>{el.weekly?.total_hours?.toFixed(2)}</div>
                              </td>
                              <td
                                style={{
                                  borderBottom: "1px solid #EEF0F2",
                                  fontWeight: 600,
                                  color: "#1D1858"
                                }}
                              >
                                {formatMoney(el.weekly?.total_w2, true)}
                              </td>
                            </tr>
                            {el.daily_summaries?.length > 0 && (
                              <tr
                                className="statement-more-data-container"
                                style={{
                                  borderLeft: "1px solid #EEF0F2",
                                  borderRight: "1px solid #EEF0F2"
                                }}
                              >
                                <td colSpan="16">
                                  <div className="statement-more-data-table">
                                    <table>
                                      <thead className="statement-more-data-table-header">
                                        <tr>
                                          <th>Date</th>
                                          <th colSpan="2" style={{ fontWeight: 700 }}>
                                            Total
                                          </th>
                                          <th colSpan="2" style={{ fontWeight: 700 }}>
                                            Clock In/Out
                                          </th>
                                          <th colSpan="1" style={{ fontWeight: 700 }}>
                                            Salary
                                          </th>
                                          <th colSpan="1" style={{ fontWeight: 700 }}>
                                            Incentive
                                          </th>
                                          <th colSpan="1" style={{ fontWeight: 700 }}>
                                            Reduction
                                          </th>
                                          <th colSpan="8" style={{ fontWeight: 700 }}>
                                            DSW
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>&nbsp;</td>
                                          <td>Stop</td>
                                          <td>Pkg</td>
                                          <td>Hour</td>
                                          <td>O/T</td>
                                          <td>&nbsp;</td>
                                          <td>&nbsp;</td>
                                          <td>&nbsp;</td>
                                          <td>WA</td>
                                          <td>ILS%</td>
                                          <td>Code 85</td>
                                          <td>Status Code</td>
                                          <td>DNA</td>
                                          <td>Miss PUs</td>
                                          <td>E/L PUs</td>
                                        </tr>
                                        {el.daily_summaries.map((el) => (
                                          <tr key={el.id}>
                                            <td>{dayjs(el.date).format("MM/DD/YYYY - ddd")}</td>
                                            <td>{el.total_stops}</td>
                                            <td>{el.total_packages}</td>
                                            <td>
                                              <DarkTooltip
                                                title={
                                                  el.clock_in_time !== null ||
                                                  el.clock_out_time !== null ? (
                                                    <>
                                                      {el.clock_in_time !== null && (
                                                        <div style={{ fontSize: "13px" }}>
                                                          Start:{" "}
                                                          {dayjs(el.clock_in_time).format("HH:mm")}
                                                        </div>
                                                      )}

                                                      {el.clock_out_time !== null && (
                                                        <div style={{ fontSize: "13px" }}>
                                                          End:{" "}
                                                          {dayjs(el.clock_out_time).format("HH:mm")}
                                                        </div>
                                                      )}
                                                    </>
                                                  ) : null
                                                }
                                                arrow
                                                placement="left"
                                              >
                                                <span style={{ color: "#344054" }}>
                                                  {(el?.hours ?? 0).toFixed(2)}
                                                </span>
                                              </DarkTooltip>
                                            </td>
                                            <td>-</td>
                                            {/* TODO: When the branch `779-review-total-earning-part-2` is pushed to production,*/}
                                            {/* TODO: uncomment the code below and delete the specific code block directly above it*/}
                                            {/*<td>${el.salary}</td>*/}
                                            {/*<td>${el.added_bonus}</td>*/}
                                            {/*<td>${el.added_deduction_bonus}</td>*/}

                                            <td>{el.salary ? `$${el.salary}` : "-"}</td>
                                            <td>{el.added_bonus ? `$${el.added_bonus}` : "-"}</td>
                                            <td>
                                              {el.added_deduction_bonus
                                                ? `$${el.added_deduction_bonus}`
                                                : "-"}
                                            </td>
                                            <td>
                                              {el.work_areas
                                                ?.map((elem) => elem?.work_area_number)
                                                .join(", ") || "-"}
                                            </td>
                                            <td>
                                              {el.work_areas
                                                ?.map((elem) => `${elem?.pivot?.ils_percentage}%`)
                                                .join(", ") || "0%"}
                                            </td>
                                            <td>
                                              {el.work_areas
                                                ?.map((elem) => `${elem?.pivot?.code_85}`)
                                                .join(", ") || "0"}
                                            </td>
                                            <td>
                                              {el.work_areas
                                                ?.map(
                                                  (elem) =>
                                                    `${elem?.pivot?.all_status_code_package}`
                                                )
                                                .join(", ") || "0"}
                                            </td>
                                            <td>
                                              {el.work_areas
                                                ?.map((elem) => `${elem?.pivot?.dna}`)
                                                .join(", ") || "0"}
                                            </td>
                                            <td>
                                              {el.work_areas
                                                ?.map(
                                                  (elem) => `${elem?.pivot?.miss_pickup_window}`
                                                )
                                                .join(", ") || "0"}
                                            </td>
                                            <td>
                                              {el.work_areas
                                                ?.map((elem) => `${elem?.pivot?.early_late_pickup}`)
                                                .join(", ") || "0"}
                                            </td>
                                          </tr>
                                        ))}
                                        <tr>
                                          <td style={{ fontWeight: 700 }}>TOTAL</td>
                                          <td style={{ fontWeight: 700 }}>
                                            {el.weekly?.total_stops}
                                          </td>
                                          <td style={{ fontWeight: 700 }}>
                                            {el.weekly?.total_packages}
                                          </td>
                                          <td style={{ fontWeight: 700 }}>
                                            {rates?.is_overtime_enabled
                                              ? el.weekly?.total_hours > 40
                                                ? 40
                                                : el.weekly?.total_hours.toFixed(2)
                                              : el.weekly?.total_hours.toFixed(2)}
                                          </td>
                                          <td style={{ fontWeight: 700 }}>
                                            {rates?.is_overtime_enabled &&
                                              (el.weekly?.total_hours > 40
                                                ? (el.weekly?.total_hours - 40).toFixed(2)
                                                : 0)}
                                          </td>
                                          <td style={{ fontWeight: 700 }}>
                                            $
                                            {(
                                              el.weekly?.total_w2_before_changes +
                                              calculatedHour(el.weekly)
                                            ).toFixed(2)}
                                          </td>
                                          <td style={{ fontWeight: 700 }}>
                                            ${incentivesBonus(el?.weekly)}
                                          </td>
                                          <td style={{ fontWeight: 700 }}>
                                            ${el.weekly?.added_deduction_bonus}
                                          </td>
                                          <td style={{ fontWeight: 700 }}>-</td>
                                          <td style={{ fontWeight: 700 }}>
                                            {el.weekly?.total_ils}%
                                          </td>
                                          <td style={{ fontWeight: 700 }}>
                                            {el.weekly?.total_code_85}
                                          </td>
                                          <td style={{ fontWeight: 700 }}>
                                            {el.weekly?.total_all_status_code_package}
                                          </td>
                                          <td style={{ fontWeight: 700 }}>
                                            {el.weekly?.total_dna}
                                          </td>
                                          <td style={{ fontWeight: 700 }}>
                                            {el.weekly?.total_miss_pickup_window}
                                          </td>
                                          <td style={{ fontWeight: 700 }}>
                                            {el.weekly?.total_early_late_pickup}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default EmployeeReport

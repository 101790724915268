import { useState } from "react"
import CloseCircle from "../../assets/images/close-circle.png"
import { useDisableBodyScroll } from "../../hooks/useDisableBodyScroll"
import EmployeeForm from "../add-employee/EmployeeForm"

const EmployeesModal = ({ open, handleClose, editedUserId, setIsEditComplete }) => {
  const [isPayHistory, setIsPayHistory] = useState(false)
  const [isCloseRun, setIsCloseRun] = useState(false)
  const [editedUser, setEditedUser] = useState()

  const handleCurClose = () => {
    handleClose()
    setIsCloseRun(true)
  }

  useDisableBodyScroll(open)

  return (
    <div
      className="employees-popup add-employees-popup"
      style={{ display: open ? "flex" : "none" }}
    >
      <div className="popup-overlay-close" onClick={handleCurClose} />

      <div className="employees-inner">
        <div className="popup-top-bar add-employees-popup-title">
          <h5>
            {editedUser && !isPayHistory
              ? "Edit Employee"
              : isPayHistory
                ? "Pay History"
                : "Add New Employee"}
          </h5>
          <button className="popup-close add-popup-cancel" onClick={handleCurClose}>
            <img src={CloseCircle} alt="" />
          </button>
        </div>

        <EmployeeForm
          handleClose={handleClose}
          editedUserId={editedUserId}
          setIsEditComplete={setIsEditComplete}
          setIsPayHistory={setIsPayHistory}
          isPayHistory={isPayHistory}
          setEditedUser={setEditedUser}
          editedUser={editedUser}
          isCloseRun={isCloseRun}
          setIsCloseRun={setIsCloseRun}
        />
      </div>
    </div>
  )
}

export default EmployeesModal

import { useLocation, useNavigate } from "react-router-dom"
import { useEffect, useMemo, useState } from "react"
import "../assets/css/app-style.css"
import TitleInfo from "../components/common/TitleInfo"
import CompanyCard from "../components/app/CompanyCard"
import AppModal from "../components/modals/AppModal"
import PaychexIntegrationModal from "../components/modals/PaychexIntegrationModal"
import axios from "../axios"
import Loading from "../components/common/Loading"
import PaychexCard from "../components/app/PaychexCard"
import ManualApplications from "../components/app/ManualApplications"
import toastService from "../services/toastService"

const companies = [
  {
    id: 1,
    name: "Paychex",
    description: "Connect Paychex to sync payroll and employees automatically",
    key: "paychex"
  }
  // {
  //   id: 2,
  //   name: "ADP Payroll",
  //   description: "Connect ADP to sync payroll and employees automatically",
  //   key: "adp"
  // },
  // {
  //   id: 3,
  //   name: "Gusto Payroll",
  //   description: "Connect Gusto to sync payroll and employees automatically",
  //   key: "gusto"
  // },
  // {
  //   id: 4,
  //   name: "FedEx MyGroundBiz.com",
  //   description: "Connect FedEx Ground Settlement/Charge statements automatically",
  //   key: "fedex"
  // }
  // {
  //   id: 5,
  //   name: "MailChimp",
  //   description: "Connect MailChimp to reach employees and grow faster",
  //   key: "mail_chimp"
  // },
  // {
  //   id: 6,
  //   name: "QuickBooks",
  //   description: "Connect QuickBooks to sync payroll and employees automatically",
  //   key: "quick_books"
  // }
]

const AppPage = () => {
  const [openKey, setOpenKey] = useState()
  const [loading, setLoading] = useState(false)
  const [connectedApps, setConnectedApps] = useState({})
  const [synchronizationProgress, setSynchronizationProgress] = useState(false)
  const [paychexIntegrations, setPaychexIntegrations] = useState([])

  const nav = useNavigate()

  const useQuery = () => {
    const { search } = useLocation()

    return useMemo(() => new URLSearchParams(search), [search])
  }

  const query = useQuery()

  const ifTestMode = query.get("test")
  const sectionQueryName = query.get("section")

  useEffect(() => {
    setLoading(true)

    axios
      .get("/integrated-applications")
      .then((response) => setConnectedApps(response.data))
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => {
        axios
          .get("/integrations/paychex")
          .then((response) => {
            setPaychexIntegrations(response?.data)
          })
          .catch((err) => toastService.error(err.response?.data?.message || err.message))
          .finally(() => {
            setLoading(false)
          })
      })
  }, [])

  useEffect(() => {
    const hasActiveSync = paychexIntegrations.some((integration) => integration.data.sync_progress)

    let intervalId
    if (hasActiveSync) {
      intervalId = setInterval(() => {
        console.log("Checking the synchronization status...")
        axios
          .get("/integrations/paychex?check_sync_progress=true")
          .then((response) => {
            setPaychexIntegrations(response.data)
          })
          .catch((err) => {
            toastService.error(err.response?.data?.message || err.message)
          })
      }, 10000)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [paychexIntegrations])

  useEffect(() => {
    const ifSuccess = query.get("success")

    if (["1"].includes(ifSuccess)) {
      toastService.success("Connected successfully")
      nav({ search: "", replace: true })
    }

    if (["0"].includes(ifSuccess)) {
      toastService.success("Something went wrong")
      nav({ search: "", replace: true })
    }
  }, [query])

  const handleOpen = (key) => setOpenKey(key)
  const handleClose = () => setOpenKey()

  useEffect(() => {
    const section = document.getElementById("manual")
    if (sectionQueryName === "manual" && section && !loading) {
      const sectionTop = section.offsetTop
      window.scrollTo({
        top: sectionTop,
        behavior: "smooth"
      })
    }
  }, [loading])

  const directIntegrations = paychexIntegrations?.length > 0
  const templateIntegrations = Object.values(connectedApps).some((value) => value === true)

  return (
    <>
      <TitleInfo
        title="Direct Integrations"
        subTitle="Streamline your workflow, effortlessly with one click"
      />

      <div
        className={`integration-app-sec ${
          !paychexIntegrations?.length ? "li-nth-child-3n-1-mr-0" : ""
        }`}
      >
        {loading ? (
          <Loading />
        ) : (
          <ul>
            {paychexIntegrations.length ? (
              <>
                {companies
                  .filter((item) => item.key === "paychex")
                  .map((item, index) => (
                    <PaychexCard
                      paychexIntegrations={paychexIntegrations}
                      synchronizationProgress={synchronizationProgress}
                      directIntegrations={directIntegrations}
                      templateIntegrations={templateIntegrations}
                    />
                  ))}
                {companies
                  .filter((item) => item.key !== "paychex")
                  .map((item) => (
                    <CompanyCard
                      key={item.id}
                      cardData={item}
                      handleConnect={handleOpen}
                      isTestMode={!!ifTestMode}
                      isConnected={connectedApps[item.key]}
                      directIntegrations={directIntegrations}
                      templateIntegrations={templateIntegrations}
                    />
                  ))}
              </>
            ) : (
              companies.map((item) => (
                <CompanyCard
                  key={item.id}
                  cardData={item}
                  handleConnect={handleOpen}
                  isTestMode={!!ifTestMode}
                  isConnected={connectedApps[item.key]}
                  directIntegrations={directIntegrations}
                  templateIntegrations={templateIntegrations}
                />
              ))
            )}
          </ul>
        )}
      </div>

      <TitleInfo
        title="Export Templates"
        subTitle="Export format to your payroll provider`s format"
        id="manual"
      />

      <ManualApplications
        directIntegrations={directIntegrations}
        templateIntegrations={templateIntegrations}
      />

      {openKey === "paychex" ? (
        <PaychexIntegrationModal open={!!openKey} handleClose={handleClose} />
      ) : (
        <AppModal open={!!openKey} handleClose={handleClose} openKey={openKey} />
      )}
    </>
  )
}

export default AppPage

import { useState } from "react"
import axios from "../../../axios"
import SettingsFixedExpensesContentItem from "./SettingsFixedExpensesContentItem"
import EditFixedExpensesItem from "../../modals/EditFixedExpensesItem"
import toastService from "../../../services/toastService"

const SettingsFixedExpensesContent = ({
  settingsData,
  handleChangeItem,
  handleDelete,
  handleEditCustomOpen
}) => {
  const [editedItem, setEditedItem] = useState()
  const [isEditLoading, setIsEditLoading] = useState(false)

  const handleOpenEditModal = (item) => setEditedItem(item)

  const handleEdit = (value, handleCurClose) => {
    setIsEditLoading(true)
    const formData = new FormData()
    formData.append(editedItem.key, value)

    axios
      .post("/fixed-expenses-settings", formData)
      .then((response) => {
        if (response.success) {
          toastService.success(response.message)
          handleChangeItem(editedItem.key, value)
          handleCurClose && handleCurClose()
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setIsEditLoading(false))
  }

  const handleEditClose = () => setEditedItem()

  return (
    <>
      <div className="settings-expenses-content">
        {settingsData.map((el) => (
          <SettingsFixedExpensesContentItem
            key={el.key}
            itemData={el}
            handleChangeItem={handleOpenEditModal}
            handleDelete={handleDelete}
            handleEditCustomOpen={handleEditCustomOpen}
          />
        ))}
      </div>

      <EditFixedExpensesItem
        open={!!editedItem}
        handleClose={handleEditClose}
        editedItem={editedItem}
        handleConfirm={handleEdit}
        isLoading={isEditLoading}
      />
    </>
  )
}

export default SettingsFixedExpensesContent

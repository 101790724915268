import { useEffect, useState } from "react"
import { batch, useDispatch, useSelector } from "react-redux"
import SearchBig from "../../assets/images/search-big.png"
import axios from "../../axios"
import Loading from "../common/Loading"
import Pagination from "../common/Pagination"
import EmptyState from "../common/EmptyState"
import OrganizationsTable from "./OrganizationsTable"
import {
  getOrganizations,
  getOrganizationsData
} from "../../store/organizations/organizations.selectors"
import { actions } from "../../store/organizations/organizations.reducers"
import { actions as authActions } from "../../store/auth/auth.reducers"
import { actions as dataActions } from "../../store/data/data.reducers"
import { useNavigate } from "react-router-dom"
import { getUser } from "../../store/auth/auth.selectors"
import { useSearchParams } from "react-router-dom"
import toastService from "../../services/toastService"

const OrganizationsContainer = () => {
  const nav = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const dispatch = useDispatch()
  const page = searchParams.get("page") || 1
  const type = searchParams.get("type") || ""
  const [loading, setLoading] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")

  const organizations = useSelector(getOrganizations)
  const organizationsData = useSelector(getOrganizationsData)
  const userData = useSelector(getUser)

  useEffect(() => {
    if (isDeleted) {
      setIsDeleted(false)
      return
    }

    const handler = setTimeout(() => !isDeleted && fetchOrganizationsList(parseInt(page, 10)), 500)

    return () => {
      clearTimeout(handler)
    }
  }, [searchQuery, isDeleted, searchParams])

  const fetchOrganizationsList = (pageNumber) => {
    setLoading(true)

    axios
      .get(`/admin/organizations?page=${pageNumber}&search=${searchQuery}&type=${type}`)
      .then((response) => {
        batch(() => {
          if (pageNumber > 1 && response.data.data.length === 0) {
            // deleting last organization on the page
            setSearchParams({ page: pageNumber - 1, type: type })
          } else {
            dispatch(actions.setOrganizations(response.data.data))
            dispatch(actions.setOrganizationsData(response.data))
          }
        })
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setLoading(false))
  }

  const handleChange = (event, value) => {
    setSearchParams({ page: value, type: type })
  }

  const handleImpersonateOrganizationsChange = (item) =>
    axios
      .post(`/admin/organizations/${item.id}/impersonate`)
      .then((response) => {
        if (response.success) {
          localStorage.setItem("token", response.data.impersonateUserToken)
          localStorage.setItem("impersonateUserToken", response.data.authenticatedUserToken)
          localStorage.setItem("impersonateUserData", JSON.stringify(userData || {}))
          localStorage.setItem(
            "impersonateBackLink",
            `/admin/organizations?page=${page}&type=${type}`
          )

          axios
            .get("/auth/user")
            .then((res) => {
              dispatch(authActions.loginUser(res))
              localStorage.setItem("user", JSON.stringify(res || {}))
            })
            .catch((err) => toastService.error(err.response?.data?.message || err.message))

          batch(() => {
            dispatch(authActions.setToken(response.data.impersonateUserToken))
            dispatch(dataActions.resetData())

            toastService.success(response.message)
          })

          nav("/")
          return
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))

  const handleImpersonateOrganizationMemberChange = (item) =>
    axios
      .post(`/admin/users/${item.id}/impersonate`)
      .then((response) => {
        if (response.success) {
          localStorage.setItem("token", response.data.impersonateUserToken)
          localStorage.setItem("impersonateUserToken", response.data.authenticatedUserToken)
          localStorage.setItem("impersonateUserData", JSON.stringify(userData || {}))

          axios
            .get("/auth/user")
            .then((res) => {
              dispatch(authActions.loginUser(res))
              localStorage.setItem("user", JSON.stringify(res || {}))
            })
            .catch((err) => toastService.error(err.response?.data?.message || err.message))

          batch(() => {
            dispatch(authActions.setToken(response.data.impersonateUserToken))
            dispatch(dataActions.resetData())
            toastService.success(response.message)
          })

          nav("/")
          return
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))

  const handleQueryChange = (e) => {
    setSearchQuery(e.target.value || "")
    setSearchParams({ page: 1 })
  }

  return (
    <div
      className="employees-data-sec organizations"
      style={{ height: loading ? "400px" : "auto" }}
    >
      {(organizations.length > 0 || searchQuery.length > 1) && (
        <div className="payroll-top">
          <div className="payroll-result-count">{organizationsData.total} organizations</div>
          <div className="search-box">
            <img src={SearchBig} alt="" />
            <input
              type="text"
              placeholder="Search item"
              value={searchQuery}
              onChange={handleQueryChange}
            />
          </div>
        </div>
      )}
      {!organizations.length && !loading ? (
        <EmptyState title="No Organizations" />
      ) : loading ? (
        <div className="loader-wrapper">
          <Loading />
        </div>
      ) : (
        <>
          <div
            className="table-scroll-mobile payroll-table employees organizations"
            style={{ height: "auto" }}
          >
            <OrganizationsTable
              organizationsList={organizations}
              handleImpersonateOrganizationsChange={handleImpersonateOrganizationsChange}
              handleImpersonateOrganizationMemberChange={handleImpersonateOrganizationMemberChange}
              setIsDeleted={setIsDeleted}
            />
          </div>
          {organizationsData.last_page > 1 && (
            <Pagination
              page={parseInt(page)}
              total_pages={organizationsData.last_page}
              onChange={handleChange}
            />
          )}
        </>
      )}
    </div>
  )
}

export default OrganizationsContainer

import { useEffect, useState } from "react"
import { ReactComponent as Delete } from "../../../assets/images/run-payroll/delete-filled.svg"
import { ReactComponent as Edit } from "../../../assets/images/settings/edit.svg"
import { ReactComponent as ArrowDown } from "../../../assets/images/settings/arrow-down.svg"
import SettingsStationWorkAreaList from "./SettingsStationWorkAreaList"
import axios from "../../../axios"
import WorkAreaModal from "../../modals/WorkAreaModal"
import ConfirmDeleteModal from "../../modals/ConfirmDeleteModal"
import BlurText from "./../../common/BlurText"
import toastService from "../../../services/toastService"

const SettingsStationRow = ({
  itemData,
  handleDelete,
  handleEdit,
  setCompanyWorkAreaCount,
  companyWorkAreaCount,
  isUserRestricted
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [workAreaList, setWorkAreaList] = useState([])
  const [isNeedUpdate, setIsNeedUpdate] = useState(false)

  const [addOpen, setAddOpen] = useState(false)
  const [editedWorkArea, setEditedWorkArea] = useState()
  const [isAddLoading, setIsAddLoading] = useState(false)

  const [deletedWorkArea, setDeletedWorkArea] = useState()
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  const [workAreaCount, setWorkAreaCount] = useState(itemData.work_areas_count)

  const handleOpenChange = (e) => {
    e.preventDefault()
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    if (!isNeedUpdate && isOpen) {
      fetchWorkAreas()
    }

    if (isNeedUpdate) {
      setIsNeedUpdate(false)
    }
  }, [isNeedUpdate, isOpen])

  const fetchWorkAreas = () => {
    setLoading(true)

    axios
      .get(`/stations/${itemData.id}/work-areas`)
      .then((response) => {
        if (response.success) {
          setWorkAreaList(response.data)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setLoading(false))
  }

  const handleAddClose = () => {
    setAddOpen(false)
    setEditedWorkArea()
  }

  const handleEditConfirm = (values, handleCurClose) => {
    setIsAddLoading(true)

    const curStopBonuses =
      values.stop_bonuses
        ?.filter((el) => el.per_stop !== "" || el.daily_threshold !== "")
        .map((el) => (el.id ? el : { ...el, id: 0, per_item: "stop" })) || []
    const curPackageBonuses =
      values.package_bonuses
        ?.filter((el) => el.per_stop !== "" || el.daily_threshold !== "")
        .map((el) => (el.id ? el : { ...el, id: 0, per_item: "package" })) || []

    const body = {
      ...values,
      ...((curStopBonuses || curPackageBonuses) && {
        bonuses: [...curStopBonuses, ...curPackageBonuses]
      }),
      deleted_bonuses: [
        ...(values.deleted_stop_bonuses || []),
        ...(values.deleted_package_bonuses || [])
      ]
    }

    if (editedWorkArea) {
      axios
        .post(`/stations/${itemData.id}/work-areas`, { ...body, id: editedWorkArea.id })
        .then((response) => {
          if (response.success) {
            handleAddClose()
            handleCurClose && handleCurClose()
            setIsNeedUpdate(true)
            toastService.success("Work Area Successfully Updated")
          }
        })
        .catch((err) => toastService.error(err.response?.data?.message || err.message))
        .finally(() => setIsAddLoading(false))
    } else {
      axios
        .post(`/stations/${itemData.id}/work-areas`, body)
        .then((response) => {
          if (response.success) {
            handleAddClose()
            handleCurClose && handleCurClose()
            setIsNeedUpdate(true)
            setWorkAreaCount(workAreaCount + 1)
            setCompanyWorkAreaCount(companyWorkAreaCount + 1)
            toastService.success("Work Area Successfully Created")
          }
        })
        .catch((err) => toastService.error(err.response?.data?.message || err.message))
        .finally(() => setIsAddLoading(false))
    }
  }

  const handleOpenEditModal = (item, e) => {
    e.preventDefault()

    setEditedWorkArea(item)
  }

  const handleAddNewOpen = () => setAddOpen(true)

  const handleCloseDeleteModal = () => setDeletedWorkArea()

  const handleDeleteConfirm = () => {
    setIsDeleteLoading(true)

    axios
      .delete(`/stations/${itemData.id}/work-areas/${deletedWorkArea.id}`)
      .then((response) => {
        if (response.success) {
          handleCloseDeleteModal()
          setIsNeedUpdate(true)
          toastService.success("Work Area Successfully Deleted")
          setWorkAreaCount(workAreaCount - 1)
          setCompanyWorkAreaCount(companyWorkAreaCount - 1)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => {
        setIsDeleteLoading(false)
      })
  }

  const handleOpenDeleteModal = (item, e) => {
    e.preventDefault()

    setDeletedWorkArea(item)
  }

  return (
    <>
      <tr className="settings-station-row">
        <td onClick={handleOpenChange}>{itemData.name}</td>

        <td onClick={handleOpenChange}>
          <BlurText>{itemData.csa}</BlurText>
        </td>
        <td onClick={handleOpenChange}>{workAreaCount}</td>

        <td>
          <div className="station-btns-wrapper">
            <button className="companies-btn edit" onClick={handleEdit.bind(null, itemData)}>
              <Edit />
            </button>
            {!isUserRestricted && (
              <>
                <button
                  className="companies-btn delete"
                  onClick={handleDelete.bind(null, itemData)}
                >
                  <Delete className="companies-delete" />
                </button>
              </>
            )}
            <button
              onClick={handleOpenChange}
              className={`companies-btn edit ${isOpen ? "up" : ""}`}
            >
              <ArrowDown />
            </button>
          </div>
        </td>
      </tr>
      {isOpen && (
        <tr className="statement-more-data-container work-area">
          <td colSpan="16">
            <div className="companies-informatio-sec">
              <div className="table-scroll-mobile payroll-table stations work-area">
                <SettingsStationWorkAreaList
                  loading={loading}
                  workAreaList={workAreaList}
                  handleEdit={handleOpenEditModal}
                  isAddDisabled={workAreaList.length >= 200}
                  handleAddNewOpen={handleAddNewOpen}
                  handleDelete={handleOpenDeleteModal}
                />
              </div>
            </div>
          </td>
        </tr>
      )}

      <WorkAreaModal
        open={!!editedWorkArea || addOpen}
        handleClose={handleAddClose}
        editedWorkArea={editedWorkArea}
        handleConfirm={handleEditConfirm}
        isLoading={isAddLoading}
      />

      <ConfirmDeleteModal
        open={!!deletedWorkArea}
        handleClose={handleCloseDeleteModal}
        handleConfirm={handleDeleteConfirm}
        text="Are you sure delete this Work Area?"
        isLoading={isDeleteLoading}
      />
    </>
  )
}

export default SettingsStationRow

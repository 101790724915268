import React from "react"
import { ReactComponent as ArrowUp } from "../../assets/images/overview/arrow-up.svg"
import { ReactComponent as ArrowDown } from "../../assets/images/overview/arrow-down.svg"

const improvementSincePreviousTimePeriod = ({ value, previousPeriodText = "last week" }) => {
  return (
    <div className="total-statistic-block-vs">
      {value > 0 ? (
        <div className="total-statistic-block-vs-item green">
          <ArrowUp />
          {value ? (Math.round(value * 10) / 10).toFixed(1) : 0}%
        </div>
      ) : (
        <div className="total-statistic-block-vs-item red">
          <ArrowDown />
          {value ? (Math.round(value * 10) / 10).toFixed(1) : 0}%
        </div>
      )}{" "}
      vs {previousPeriodText}
    </div>
  )
}

export default improvementSincePreviousTimePeriod

import React from "react"
import { useNavigate } from "react-router-dom"
import { ReactComponent as ArrowLeft } from "../../assets/images/home/arrow-left.svg"

const DefaultPageHeader = ({
  title,
  actionButtons,
  isBackButtonVisible = true,
  noBackLogic,
  page
}) => {
  let navigate = useNavigate()

  return (
    <div className="payroll-header">
      <div className="title">
        {!noBackLogic && (
          <>
            {isBackButtonVisible && (
              <a onClick={() => navigate(page ? page : -1)}>
                <div className="back-button">
                  <ArrowLeft />
                </div>
              </a>
            )}

            <h4>{title}</h4>
          </>
        )}
      </div>
      <div className="payroll-header-actions">{actionButtons}</div>
    </div>
  )
}

export default DefaultPageHeader

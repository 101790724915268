import { useEffect } from "react"
import DashboardImg from "../assets/images/dashboard-img.png"
import GoogleWhiteBig from "../assets/images/login/google-white-big.png"
import PaychexBig from "../assets/images/login/paychex-big.png"
import FedExLogoBig from "../assets/images/login/FedEx-Logo-big.png"
import StripeBig from "../assets/images/login/Stripe-big.png"
import LoginLogo from "../components/common/LoginLogo"
import ResetForm from "../components/login/ResetForm"
import dayjs from "dayjs"

const Reset = () => {
  useEffect(() => {
    window.Intercom("boot", {
      app_id: "dyklmyeq"
    })
  }, [])

  return (
    <>
      <LoginLogo />

      <div className="login-main-sec">
        <div className="login-left-sec">
          <ResetForm />;
        </div>

        <div className="login-right-sec">
          <div className="inner-sec">
            <h2>The simplest way to do payroll for FedEx contractor</h2>
            <p>Enter your credentials to access your account</p>
            <img src={DashboardImg} alt="" />
            <div className="login-marquee">
              <div className="track">
                <img src={GoogleWhiteBig} alt="" />
                <img src={PaychexBig} alt="" />
                <img src={FedExLogoBig} alt="" />
                <img src={StripeBig} alt="" />
                <img src={GoogleWhiteBig} alt="" />
                <img src={PaychexBig} alt="" />
                <img src={FedExLogoBig} alt="" />
                <img src={StripeBig} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="copyright-sec">
          <p>{dayjs().year()} Fleet Wage Inc, All right Reserved</p>
        </div>
      </div>
    </>
  )
}

export default Reset

import "../../assets/css/modal.css"
import { Modal } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import axios from "../../axios"
import toastService from "../../services/toastService"
import Loading from "../common/Loading"
import DarkTooltip from "../common/DarkToolTip"
import CustomCheckbox from "../common/CustomCheckbox"
import "./scss/employee-add-date-modal.scss"

const schema = yup.object().shape({
  value: yup.string().required("Date is required!")
})

const EmployeeAddDateModal = ({
  open,
  handleClose,
  employee,
  statementId,
  free_days,
  fetchStatementList
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [dates, setDates] = useState([])
  const [allSelected, setAllSelected] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, touchedFields }
  } = useForm({
    mode: "all",
    defaultValues: {
      value: ""
    },
    resolver: undefined
  })

  useEffect(() => {
    if (open && free_days && Object.entries(free_days).length === 1) {
      const singleDateKey = Object.keys(free_days)[0]

      setDates([singleDateKey])
    }
  }, [free_days, open])

  useEffect(() => {
    setValue("value", employee?.email, {
      shouldValidate: true,
      shouldDirty: true
    })
  }, [open])

  useEffect(() => {
    if (allSelected) {
      setDates(Object.keys(free_days))
    }
  }, [allSelected])

  const handleMainClose = () => {
    handleClose()
    reset()
  }

  const handleAddDate = (value) => {
    if (dates.includes(value)) {
      setDates(dates.filter((v) => v !== value))
      if (allSelected) {
        setAllSelected(false)
      }
    } else {
      setDates([...dates, value])
    }
  }

  const handleDeselectAll = () => {
    setAllSelected(false)
    setDates([])
  }

  const onSubmit = () => {
    console.log(dates)

    if (dates.length < 1) {
      return toastService.error("Select at least 1 Day")
    }

    setIsLoading(true)

    axios
      .post(`/employee-daily-summary`, {
        statement_id: statementId,
        employee_id: employee?.id,
        dates: dates
      })
      .then((response) => {
        if (response.success) {
          handleMainClose()
          fetchStatementList(employee?.id)
          toastService.success("Day successfully added.")
        }
      })
      .catch((err) => {
        const message = err.response?.data?.errors
          ? Object.values(err.response?.data?.errors).join("\n")
          : err.response?.data?.message || err?.message
        toastService.error(message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Modal open={open} onClose={handleMainClose}>
      <div className="confirm-modal">
        <div className="confirm-modal-wrapper add-date-modal">
          <form className="profile-form-sec">
            <h3 style={{ fontSize: "18px", marginBottom: "5px", fontWeight: "800" }}>
              Add Date ({employee.first_name} {employee.last_name})
            </h3>
            <span style={{ fontSize: "12px", fontWeight: "400" }}>
              Choose day from days that are still not in the list
            </span>

            <div className="form-item" style={{ marginBottom: "0", marginTop: "20px" }}>
              {free_days && Object.entries(free_days).length > 1 ? (
                <CustomCheckbox
                  key={"all"}
                  checked={allSelected}
                  onChange={() => (!allSelected ? setAllSelected(true) : handleDeselectAll())}
                  className={"height-40"}
                  label={
                    <DarkTooltip enterNextDelay={500} enterDelay={500} placement="top" arrow>
                      <div className="custom-checkbox-with-info">Select All</div>
                    </DarkTooltip>
                  }
                />
              ) : null}

              {free_days &&
                Object.entries(free_days).map(([key, value]) => (
                  <CustomCheckbox
                    key={key}
                    checked={dates.includes(key)}
                    onChange={() => handleAddDate(key)}
                    className={"height-40"}
                    label={
                      <DarkTooltip enterNextDelay={500} enterDelay={500} placement="top" arrow>
                        <div className="custom-checkbox-with-info">{value}</div>
                      </DarkTooltip>
                    }
                  />
                ))}
            </div>
          </form>

          <div className="add-member-form-btn-wrapper">
            <button className="btn-style blank-btn add-popup-cancel" onClick={handleMainClose}>
              Cancel
            </button>
            <button
              className="btn-style add-employee-btn loading-style"
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              {isLoading ? <Loading /> : "Add"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default EmployeeAddDateModal
